import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { QueryListService } from "../../../services/query-list.service";

@Component({
  selector: 'app-checklists',
  templateUrl: './checklists.component.html'
})
export class ChecklistsComponent implements OnInit {
  public patientPathwayUid: string;
  public patientUid: string;
  public isLoading: false;
  public checklists: Array<any> =  [];


  constructor(
    public queryListService: QueryListService,
    private route: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.patientPathwayUid = params.patientPathwayUid;
      this.patientUid = params.patientUid;

      this.getChecklists();
    });
  }

  getChecklists() {
    this.queryListService.getQueryLists(this.patientUid, this.patientPathwayUid).subscribe(result => {
    });
  }
}
