import { Injectable, RendererFactory2, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService extends BsModalService {
  confirmPromise?: Promise<void>;

  constructor(
    public translateService: TranslateService,
    rendererFactory: RendererFactory2,
    componentLoaderFactory: ComponentLoaderFactory,
    modalOptions: ModalOptions,

  ) {
    super(rendererFactory, componentLoaderFactory, modalOptions)
  }

  //
  // Intercepts onHide and shows confirmationModal.
  // If confirmationModal should popup depending on modal/form state,
  // use 'showModalInterceptor' in given modal.
  //
  showWithInterceptor(
    modal: TemplateRef<any> | string | (new (...args: any[]) => any),
    modalOptions: ModalOptions,
    confirmTemplateParams: ModalOptions = GeneralService.BsModalOptions({
      class: 'modal-dialog-centered',
      initialState: {
        title: this.translateService.instant('modals.general.confirm.title'),
        description: this.translateService.instant('modals.general.confirm.description'),
        yes: this.translateService.instant('modals.general.confirm.confirm'),
        no: this.translateService.instant('modals.general.confirm.cancel')
      }
    })
  ): BsModalRef {
    let confirmResolve: () => void;
    let bsModalRef: BsModalRef;

    const closeInterceptor = () => {
      this.confirmPromise = new Promise((resolve) => {
        confirmResolve = resolve;
      });

      if (
        bsModalRef.content.showModalInterceptor === true ||
        bsModalRef.content.showModalInterceptor === undefined
      ) {
        const confirmModalRef = this.show(ConfirmModalComponent, confirmTemplateParams);

        confirmModalRef.content.onChoice.subscribe(value => {
          if (value) {
            confirmResolve();
            confirmModalRef?.hide();
          }
        });
      } else {
        confirmResolve();
      }

      return this.confirmPromise;
    };

    bsModalRef = this.show(modal, { closeInterceptor, ...modalOptions });
    return bsModalRef;
  }


  // Due to a bug in ngx-bootstrap the confirmation modal is only shown when pressing 'ESC' not when .onHide() is called.
  // That's why I used this function to do the same.
  // You need to add 'keyboard': false to the modal config and add an event listener to the ESC btn to make this work on all hide actions.
  hideWithInterceptor(
    bsModalRef: BsModalRef,
    confirmTemplateParams: ModalOptions = GeneralService.BsModalOptions({
      class: 'modal-dialog-centered',
      initialState: {
        title: this.translateService.instant('modals.general.confirm.title'),
        description: this.translateService.instant('modals.general.confirm.description'),
        yes: this.translateService.instant('modals.general.confirm.confirm'),
        no: this.translateService.instant('modals.general.confirm.cancel')
      }
    })
  ): void {
    if (
      bsModalRef.content.showModalInterceptor === true ||
      bsModalRef.content.showModalInterceptor === undefined
    ) {
      const confirmModalRef = this.show(ConfirmModalComponent, confirmTemplateParams);

      confirmModalRef.content.onChoice.subscribe(value => {
        if (value) {
          confirmModalRef?.hide();
          bsModalRef.hide();
        }
      });
    } else {
      bsModalRef.hide();
    }
  };
}
