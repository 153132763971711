import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FaqCategory } from '../models/faq-category';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends ApiService {
  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public languageService: LanguageService
  ) {
    super(http, authenticationService);
  }

  getFaqCategoriess(patientUid, patientPathwayUid): Observable<FaqCategory[]> {
    const url = `${environment.platformUrl}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/faqs`;

    return new Observable(observer => {

      this.authenticatedGet(url).subscribe(result => {
        const ids = result.map(item => item.faq.key);
        this.getFaqList(ids).subscribe(details => {
          observer.next(details);
          observer.complete();
        });
      }, error => {
        observer.error(error);
      });

    });
  }

  getFaqList(ids: string[]): Observable<FaqCategory[]> {
    return new Observable(observer => {
      const url = environment.cmsUrl + '/v3/content_types/faq/entries';
      const locale = this.languageService.getCurrentLanguage().locale;

      this.cmsGet(url, locale, null, ids).subscribe(result => {
        const entries = result['entries'] || [];
        const categories = [];
        entries.forEach(entry => {
          const items = entry['paragraphs']['items'].filter(item => { return item['category'] })
          items.forEach(item => {
            categories.push(new FaqCategory(item.category));
          });
        });

        observer.next(categories);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
