<h3 class="mb-1">
  <em [inlineSVG]="'/assets/svg-color/safety.svg'"></em>
  <span class="align-middle">{{ 'components.password_policy_rules.choose_strong_pw' | translate }}</span>
</h3>

<p>{{ 'components.password_policy_rules.pw_criteria' | translate }}</p>

<p *ngIf="!policy && policy!==null" class="text-info"><em class="spinner-border spinner-border-sm"></em></p>

<ul class="ul-color m-0" *ngIf="policy">
  <li [ngClass]="{'text-secondary font-weight-bold':isPolicyBreach('password_policy_min_length')}">{{ 'components.password_policy_rules.min_char_count' | translate }} {{ policy?.min_length }}</li>
  <li [ngClass]="{'text-secondary font-weight-bold':isPolicyBreach('password_policy_max_length')}">{{ 'components.password_policy_rules.max_char_count' | translate }} {{ policy?.max_length }}</li>

  <li *ngIf="policy?.use_uppercase" [ngClass]="{'text-secondary font-weight-bold':isPolicyBreach('password_policy_use_uppercase')}">
    <span>{{ 'components.password_policy_rules.use_upper_char' | translate }} </span>
    <span *ngIf="policy?.use_uppercase==='REQUIRED'">{{ 'components.password_policy_rules.required' | translate }}</span>
    <span *ngIf="policy?.use_uppercase==='OPTIONAL'">{{ 'components.password_policy_rules.optional' | translate }}</span>
    <span *ngIf="policy?.use_uppercase==='DISABLED'">{{ 'components.password_policy_rules.disabled' | translate }}</span>
  </li>

  <li *ngIf="policy?.use_lowercase" [ngClass]="{'text-secondary font-weight-bold':isPolicyBreach('password_policy_use_lowercase')}">
    <span>{{ 'components.password_policy_rules.use_lower_char' | translate }} </span>
    <span *ngIf="policy?.use_lowercase==='REQUIRED'">{{ 'components.password_policy_rules.required' | translate }}</span>
    <span *ngIf="policy?.use_lowercase==='OPTIONAL'">{{ 'components.password_policy_rules.optional' | translate }}</span>
    <span *ngIf="policy?.use_lowercase==='DISABLED'">{{ 'components.password_policy_rules.disabled' | translate }}</span>
  </li>

  <li *ngIf="policy?.use_numbers" [ngClass]="{'text-secondary font-weight-bold':isPolicyBreach('password_policy_use_numbers')}">
    <span>{{ 'components.password_policy_rules.use_number' | translate }} </span>
    <span *ngIf="policy?.use_numbers==='REQUIRED'">{{ 'components.password_policy_rules.required' | translate }}</span>
    <span *ngIf="policy?.use_numbers==='OPTIONAL'">{{ 'components.password_policy_rules.optional' | translate }}</span>
    <span *ngIf="policy?.use_numbers==='DISABLED'">{{ 'components.password_policy_rules.disabled' | translate }}</span>
  </li>
  <li *ngIf="policy?.use_specials" [ngClass]="{'text-secondary font-weight-bold':isPolicyBreach('password_policy_use_specials')}">
    <span>{{ 'components.password_policy_rules.use_special_char' | translate }} </span>
    <span *ngIf="policy?.use_specials==='REQUIRED'">{{ 'components.password_policy_rules.required' | translate }}</span>
    <span *ngIf="policy?.use_specials==='OPTIONAL'">{{ 'components.password_policy_rules.optional' | translate }}</span>
    <span *ngIf="policy?.use_specials==='DISABLED'">{{ 'components.password_policy_rules.disabled' | translate }}</span>
  </li>
  <li *ngIf="policy?.history" [ngClass]="{'text-secondary font-weight-bold':isPolicyBreach('password_already_used')}">
    <span>{{ 'components.password_policy_rules.different_from_last' | translate }} {{ policy?.history_size }} {{ 'components.password_policy_rules.used_pw' | translate }}</span>
  </li>
</ul>
