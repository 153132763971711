import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import * as i0 from "@angular/core";
var ClickOutsideModule = function () {
  function ClickOutsideModule() {}
  ClickOutsideModule.ɵfac = function ClickOutsideModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ClickOutsideModule)();
  };
  ClickOutsideModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ClickOutsideModule
  });
  ClickOutsideModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  return ClickOutsideModule;
}();
export { ClickOutsideModule };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClickOutsideModule, [{
    type: NgModule,
    args: [{
      declarations: [ClickOutsideDirective],
      exports: [ClickOutsideDirective]
    }]
  }], null, null);
})();