import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpcenterArticle } from '../../../../models/helpcenter-article';
import { HelpcenterSection } from '../../../../models/helpcenter-section';
import { PageTabItem } from '../../../../models/page-tab-item';
import { DataService } from '../../../../services/data.service';
import { GeneralService } from '../../../../services/general.service';
import { PatientService } from '../../../../services/patient.service';
import { HelpcenterService } from '../../../../services/helpcenter.service';
import { Faq } from '../../../../models/faq';
import { FaqCategory } from '../../../../models/faq-category';

@Component({
  selector: 'app-help-center-overview',
  templateUrl: './help-center-overview.component.html',
  styleUrls: ['./help-center-overview.component.scss']
})
export class HelpCenterOverviewComponent implements OnInit {
  public pageTabItems: PageTabItem[] = [];
  public pageLoading = false;
  public helpcenterSections: HelpcenterSection[];

  constructor(
    public helpcenterService: HelpcenterService,
    public router: Router,
    public dataService: DataService,
    public generalService: GeneralService,
    public patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.pageLoading = true;
    this.getHelpcenter();
  }

  getHelpcenter() {
    this.helpcenterService.getHelpCenter().subscribe((data: HelpcenterSection[]) => {
      this.handleHelpCenterResponse(data);
    });
  }

  handleHelpCenterResponse(helpcenterSections: HelpcenterSection[]) {
    helpcenterSections.forEach((section: HelpcenterSection, i) => {
      if (!section.isBanner) {
        this.pageTabItems.push(new PageTabItem(section.title, `zone_${i}`));
        section['slug'] = `zone_${i}`;
      }
    });

    this.helpcenterSections = helpcenterSections;
    this.pageLoading = false;

    this.goToPageTabItem();
  }

  goToSectionOverview(section: HelpcenterSection, event?: Event) {
    event?.preventDefault();
    this.dataService.set(DataService.HelpcenterSectionSelected, section);
    this.router.navigateByUrl(`/help-center/${section.type}`);
  }

  goToPageTabItem() {
    const cachedSelectedSection: HelpcenterSection = this.dataService.get(DataService.HelpcenterSectionSelected) as HelpcenterSection;
    let cacheSelectedPageTabItem;
    this.pageTabItems.forEach(element => {
      if (element.translationKey === cachedSelectedSection?.title) {
        cacheSelectedPageTabItem = element;
      }
    });

    if (cacheSelectedPageTabItem) {
      this.generalService.scrollToPageTabItem(cacheSelectedPageTabItem, false);
    }
  }

  goToArticleDetail(event: Event, article: HelpcenterArticle, section) {
    event.preventDefault();
    this.dataService.set(DataService.HelpcenterArticleSelected, article);
    this.dataService.set(DataService.HelpcenterSectionSelected, section);
    this.router.navigate(['/help-center', 'articles', article.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }
    });
  }

  goToFaq(result: {faq: Faq, category: FaqCategory, section: HelpcenterSection}) {
    this.dataService.set(DataService.HelpcenterFaqSelected, result.faq);
    this.dataService.set(DataService.HelpcenterFaqCategorySelected, result.category);
    this.dataService.set(DataService.HelpcenterSectionSelected, result.section);
    this.router.navigate(['/help-center', 'faqs', result.faq.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }
    });
  }
}
