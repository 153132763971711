<audio #audioElement >  
  <source [src]="source" type='audio/mp3' *ngIf="source && source !== ''" />
</audio>

<div [ngClass]="['audio-player', inputClass]">
  <button (click)="play()" id="audio_player_btn_play" class="btn p-0" [hidden]="playing"> <span class="icon" [inlineSVG]="'/assets/svg/play.svg'"></span></button>
  <button (click)="pause()" id="audio_player_btn_pause" class="btn p-0" [hidden]="!playing"><span class="icon" [inlineSVG]="'/assets/svg/pause.svg'"></span></button>
  <div class="wave ml-1" id="audio_player_wave">
    <input type="range"  min="0" max="100" [value]="progressPercent" (change)="stopSeek($event)" (mousedown)="startSeek($event)" (input)="seek($event.target.value)"/>
    <div class="background h-100">
      <div class="progress" [style.width.%]="progressPercent"></div>
    </div>
  </div>
  <div class="m-start-auto my-auto mr-1" id="audio_player_progress">
    {{ progressTime * 1000 | date:'mm:ss' }}
  </div>
</div>
