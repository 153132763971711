import { Host, Optional, SkipSelf } from '@angular/core';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateInputComponent),
    multi: true
  }]
})
export class DateInputComponent implements OnInit {
  private control: AbstractControl;

  public editDateValue: Date;
  public value: string;

  public onChange: any = () => { };
  public onTouched: any = () => { };
  public disabled: boolean;

  @Input() name: String;
  @Input() formControlName: string;
  @Output() change: EventEmitter<string> = new EventEmitter();

  constructor(
    public localeService: LocaleService,

    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
    }
  }

  dateToString(date: Date): string {
    if(date) {
      return (date.toISOString()).split('T').splice(0,1).join();
    } else {
      return null;
    }
  }

  writeValue(value: string): void {
    if(value) {
      this.editDateValue = new Date(value);
      this.value = value;
    } else {
      this.editDateValue = null;
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateNgModelChange(date: Date) {
    this.tryCompileValue()
  }

  tryCompileValue() {
    this.value = this.dateToString(this.editDateValue);

    this.onChange(this.value);
    this.onTouched();

    this.change.emit(this.value);
  }

  public get editDateValid(): boolean {
    if(this.control?.errors?.required && !this.editDateValue) {
      return false;
    }

    return true;
  }

  public get isValid(): boolean {
    return this.editDateValid;
  }
}
