import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { LocaleService } from '../services/locale.service';

@Pipe({name: 'weekday'})
export class WeekdayPipe implements PipeTransform {
  constructor(
    private readonly localeService: LocaleService
  ) {}

  transform(date: string, short = false): string {
    const timeZone = this.localeService.getLocalePreferences().locale.time_zone;
    if (!timeZone || !date) {
      return;
    }

    const mDate = moment(date);
    const format = (short) ? 'ddd' : 'dddd';

    return mDate.tz(timeZone).format(format);
  }
}
