export class HcpFunction {
  public key: string;
  public is_medical: boolean;

  // select purposes
  public label: string;
  public value: string;

  constructor(item?) {
    if (!item) {
      return;
    }

    this.key = item.key;
    this.is_medical = item.is_medical;

    // Label is deprecated;
    this.label = 'Depr. HcpFunction';
  }

  get translationKey():string {
    return `shared.business_function_keys.${this.key}`;
  }
}
