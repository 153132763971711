import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class MatchingFieldsValidator {
  static validate(fieldName: string, matchFieldName: string, errorKey) {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      let control: AbstractControl = group.get(fieldName);
      let matchControl: AbstractControl = group.get(matchFieldName);

      if(control.value!==matchControl.value) {
        let error = {};
        error[errorKey] = true;
        return error;
      } else {
        return null;
      }
    };
  }
}
