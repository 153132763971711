import { MobileNumber } from '../interfaces/mobile-number.interface';
import { Image } from './image';
import { MfaMethod } from './mfa-method';
import { UserProfileLocale } from './user-profile-locale';

enum ContactChannel {
  EMAIL,
  PHONE
}
export class Profile {
  uid: string;
  email: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  mobile_number: MobileNumber;
  profile_picture: Image;
  locale: UserProfileLocale;
  contact_channel: ContactChannel;
  contact_consented: boolean;
  mobile_verification_code: string;
  mfa_enabled: boolean;
  mfas: Array<MfaMethod>;

  constructor(item?) {
    if (!item) {
      return;
    }

    this.uid = item.uid;
    this.email = item.email;
    this.first_name = item.first_name;
    this.last_name = item.last_name;
    this.preferred_name = item.preferred_name;
    this.mobile_number = item.mobile_number;
    this.contact_channel = item.contact_channel;
    this.contact_consented = item.contact_consented;
    this.mfa_enabled = item?.mfa_enabled || false;

    if(item.profile_picture) {
      this.profile_picture  = new Image(item.profile_picture);
    }

    if(item.locale) {
      this.locale = new UserProfileLocale(item.locale);
    }

    if(item?.mfas) {
      this.mfas = item.mfas.map((_mfa) => new MfaMethod(_mfa));
    } else {
      this.mfas = [];
    }
  }

  get mfaPrimaryMethods(): MfaMethod[] {
    return this.mfas.filter((mfaMethod: MfaMethod) => mfaMethod.primary)
  }

  get mfaBackupMethods(): MfaMethod[] {
    return this.mfas.filter((mfaMethod: MfaMethod) => !mfaMethod.primary)
  }
}
