<div class="layout-header-pusher header-tabs"></div>

<div class="layout-header header-tabs">
  <div class="header-content">
    <div class="container">
      <h1>{{ 'pages.default.help_center.help_center' | translate }}</h1>
      <app-page-tabs [items]="pageTabItems" *ngIf="!pageLoading"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="pageLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!pageLoading">

    <div id="{{ helpcenterSection.slug }}" class="mb-5" *ngFor="let helpcenterSection of helpcenterSections">
      <!-- Articles -->
      <div *ngIf="helpcenterSection.isArticles">
        <div class="heading-block mb-3">
          <div class="block-wrapper overflow-hidden py-2">
            <h2 class="text-truncate">{{ helpcenterSection?.title }}</h2>
            <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
          </div>
          <a href="" class="block-action m-start-auto" (click)="goToSectionOverview(helpcenterSection, $event)" *ngIf="(helpcenterSection.isArticles && !helpcenterSection.has2LBlocks && helpcenterSection?.articles?.length > 3) || (helpcenterSection.has2LBlocks && helpcenterSection.isArticles && helpcenterSection?.articles?.length > 5)">
            <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon icon-small rtl-mirrored-inline-svg"></span>
          </a>
        </div>

        <div *ngIf="!helpcenterSection?.articles || helpcenterSection?.articles.length == 0">
          <p class="text-center text-muted">{{ 'pages.default.help_center.no_articles_yet' | translate }}</p>
        </div>

        <div *ngIf="helpcenterSection?.articles">
          <div class="row mb-2">
            <div class="mb-2" [ngClass]="(helpcenterSection.has2LBlocks && index < 2)? 'col-6' : 'col-4'" *ngFor="let article of helpcenterSection?.articles; index as index">
              <a href="" (click)="goToArticleDetail($event, article, helpcenterSection)" class="card h-100"
                *ngIf="index < 3 || (helpcenterSection.has2LBlocks && index < 5)">
                <!-- Small block -->
                <div class="card-cover"
                  *ngIf="(helpcenterSection.has2LBlocks && index > 1) || !helpcenterSection.has2LBlocks"
                  [style.background-image]="'url('+ article?.thumbnail?.image?.url + '?width=920&disable=upscale&format=pjpg&auto=webp' +')'">

                  <div class="cover-meta">
                    <div class="meta-category" *ngIf="article?.thumbnail?.icon?.includes('Video')">
                      <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/play.svg'"></span>
                    </div>
                  </div>
                </div>
                <!-- /Small block -->
                <!-- Big block -->
                <div class="card-cover" *ngIf="helpcenterSection.has2LBlocks && index < 2"
                  [style.background-image]="'url('+ article?.thumbnail?.image?.url + '?width=1394&disable=upscale&format=pjpg&auto=webp' +')'">

                  <div class="cover-meta">
                    <div class="meta-category" *ngIf="article?.thumbnail?.icon.includes('Video')">
                      <span [inlineSVG]="'/assets/svg/play.svg'"></span>
                    </div>
                  </div>
                </div>
                <!-- Big block -->

                <div class="card-body bg-primary" *ngIf="helpcenterSection.has2LBlocks && index < 2">
                  <h2 class="mb-1 text-white">{{ article?.title }}</h2>
                  <p class="m-0 text-white">{{ article?.description }}</p>
                </div>

                <div class="card-body"
                  *ngIf="!helpcenterSection.has2LBlocks || (helpcenterSection.has2LBlocks && index >= 2)">
                  <h3 class="mb-1">{{ article?.title }}</h3>
                  <p class="m-0">{{ article?.description }}</p>
                </div>

                <div class="card-footer"
                  *ngIf="!helpcenterSection.has2LBlocks || (helpcenterSection.has2LBlocks && index >= 2)">
                  <div class="btn btn-secondary m-start-auto">
                    <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                  </div>
                </div>
              </a>
            </div>

          </div>

          <div class="text-end" *ngIf="(!helpcenterSection.has2LBlocks && helpcenterSection?.articles.length > 3) || (helpcenterSection.has2LBlocks && helpcenterSection?.articles.length > 5)">
            <a href="" (click)="goToSectionOverview(helpcenterSection, $event)" class="text-secondary d-flex align-items-center justify-content-end">
              <span>{{ 'pages.default.help_center.show_all' | translate }}</span>
              <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>

        </div>
      </div>
      <!-- /Articles -->

      <!-- Faqs -->
      <div *ngIf="helpcenterSection.isFaqs">
        <app-help-center-faq-list [helpcenterSection]="helpcenterSection" (goToOverview)="goToSectionOverview(helpcenterSection, $event)"
          (goToFaq)="goToFaq($event)"></app-help-center-faq-list>
      </div>
      <!-- /Faqs -->

      <!-- Contact -->
      <div *ngIf="helpcenterSection.isContact">
        <app-help-center-contact [helpcenterSection]="helpcenterSection"></app-help-center-contact>
      </div>
      <!-- /Contact -->

      <!-- Banner -->
      <div *ngIf="helpcenterSection.isBanner">
        <div class="picture-card mb-4 mb-lg-5">
          <div class="card bg-info text-white">
            <div class="card-body p-3">
              <h2 class="text-white">{{ helpcenterSection?.title }}</h2>
              <p class="text-white">{{ helpcenterSection?.subtitle }}</p>

              <div *ngIf="helpcenterSection?.path.href">
                <div class="pb-2 pb-md-3"></div>

                <a href="{{ helpcenterSection?.path.href }}" target="_blank" rel="noopener noreferrer"
                  class="text-white">
                  <span class="align-middle">{{ helpcenterSection?.path?.title }}</span>
                  <span class="rtl-mirrored-inline-svg m-start-1"
                    [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="picture"
            [style.background-image]="'url('+ helpcenterSection?.thumbnail?.url + '?width=1394&disable=upscale&format=pjpg&auto=webp' +')'">
          </div>
        </div>
      </div>
      <!-- /Banner -->

    </div>
  </div>
</div>
