import { Component, OnInit } from '@angular/core';
import { ConsentService } from './../../../services/consent.service';
import { Consent, ConsentStatus } from './../../../models/consent';
import { DoNotConsentModalComponent } from './../../../modals/do-not-consent-modal/do-not-consent-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from './../../../services/authentication.service';
import { HomeFlowService } from '../../../services/home-flow.service';
import { GeneralService } from '../../../services/general.service';
import { OnBoardingType } from '../../../enums/onboarding-type';
import { OnboardingState } from '../../../enums/onboarding-state';
import { OnboardingFlow } from '../../../enums/onboarding-flow';


@Component({
  selector: 'app-onboarding-consent',
  templateUrl: './onboarding-consent.component.html',
  styleUrls: ['./onboarding-consent.component.scss']
})
export class OnboardingConsentComponent implements OnInit {
  public allConsents: Consent[];
  public currentConsent: Consent;
  public revokeModal: BsModalRef;
  public isLoadingSkip: boolean;
  public isLoadingConsent: boolean;

  public onBoardingType = OnBoardingType.CC;
  public currentState = OnboardingState.CONSENTS;

  public showOnboardingIconSteps: boolean = false;

  constructor(
    public consentService: ConsentService,
    public modalService: BsModalService,
    public homeFlowService: HomeFlowService,
    public authenticationService: AuthenticationService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.flow === OnboardingFlow.ONBOARDING) this.showOnboardingIconSteps = true;
    });

    this.allConsents = [];
    this.consentService.getAllConsents().subscribe(consents => this.consentsLoaded(consents));
  }

  consentsLoaded(consents: Consent[]) {
    this.allConsents = consents;
    this.currentConsent = this.allConsents[0];
  }

  canRevokeConsent() {
    return (this.currentConsent && this.currentConsent.isHospitalConsent());
  }

  canSkipConsent() {
    return (this.currentConsent && this.currentConsent.isPlatformConsent());
  }

  handleDoConsent() {
    if (this.isLoadingConsent || this.isLoadingSkip) {
      return;
    }

    this.isLoadingConsent = true;
    this.currentConsent.handled = true;
    this.currentConsent.status = ConsentStatus.CONSENTED;
    this.consentService.postConsents([
      this.currentConsent
    ]).subscribe(() => this.handleNextAction());
  }

  handleSkipConsent() {
    if (this.isLoadingConsent || this.isLoadingSkip) {
      return;
    }

    this.isLoadingSkip = true;
    this.currentConsent.handled = true;
    this.currentConsent.status = ConsentStatus.DECLINED;
    this.consentService.postConsents([
      this.currentConsent
    ]).subscribe(() => this.handleNextAction());
  }

  showRevokeConsentModal() {
    if (this.isLoadingConsent || this.isLoadingSkip) {
      return;
    }

    this.revokeModal = this.modalService.show(DoNotConsentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
      })
    );

    this.revokeModal.content.onChoice.subscribe(() => this.handleRevokeConsent());
  }

  handleRevokeConsent() {
    this.revokeModal.content.isLoading = true;

    this.currentConsent.status = ConsentStatus.DECLINED;
    this.consentService.postConsents([
      this.currentConsent
    ]).subscribe(() => this.onRevokingSuccess());
  }

  onRevokingSuccess() {
    this.revokeModal.content.isLoading = false;
    this.revokeModal.hide();

    this.authenticationService.logout();
  }

  handleNextAction() {
    const currentConsentIndex = this.allConsents.indexOf(this.currentConsent);
    if (currentConsentIndex < this.allConsents.length - 1) {
      this.isLoadingConsent = false;
      this.isLoadingSkip = false;

      this.showNextConsent();
    } else {
      this.doComplete();
    }
  }

  showNextConsent() {
    const currentConsentIndex = this.allConsents.indexOf(this.currentConsent);
    this.currentConsent = this.allConsents[currentConsentIndex + 1];
    window.scroll(0, 0);
  }

  doComplete() {
    this.homeFlowService.goNextStepOrDone().subscribe(() => {
      this.isLoadingConsent = false;
      this.isLoadingSkip = false;
    });
  }
}
