import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgHttpCachingHeaders } from 'ng-http-caching';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { TranslationInterface } from '../interfaces/translation.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  @Output() onError: EventEmitter<any> = new EventEmitter();

  constructor(
    public http: HttpClient,
    public authenticationService: AuthenticationService
  ) {
  }

  defaultObserverResultResolver(result, observer: Subscriber<any>) {
    observer.next(result);
    observer.complete();
  }

  authObserverResultResolver(result, observer: Subscriber<any>) {
    this.authenticationService.setAuthenticationData(result);
    observer.next(result);
    observer.complete();
  }

  defaultObserverErrorResolver(error, observer: Subscriber<any>) {
    observer.error(error);
    observer.complete();
  }

  basicAuthGet(url, version?:string|null, cached:boolean = false): Observable<any> {
    return new Observable(observer => {
      let headers = this.getBasicAuthHeaders();

      if (version) {
        headers = this.appendVersionHeaders(headers, version);
      }

      if (cached) {
        headers = headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
      }

      this.http.get(url, {headers}).subscribe(
        result => this.defaultObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  basicAuthPost(url, params): Observable<any> {
    return new Observable(observer => {
      const headers = this.getBasicAuthHeaders();

      this.http.post(url, params, {headers}).subscribe(
        result => this.authObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  basicAuthPut(url, params): Observable<any> {
    return new Observable(observer => {
      const headers = this.getBasicAuthHeaders();

      this.http.put(url, params, {headers}).subscribe(
        result => this.authObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  authenticatedPost(url, params, version?: string, xtraHeaders?): Observable<any> {
    return new Observable(observer => {
      let headers:HttpHeaders = this.getAuthHeaders();

      if (version) {
        headers = this.appendVersionHeaders(headers, version);
      }

      for (let key in xtraHeaders) {
        let value = xtraHeaders[key];
        headers = headers.append(key, value);
      }

      this.http.post(url, params, {headers}).subscribe(
        result => this.authObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  authenticatedGet(url:string, version?:string, cached:boolean = false): Observable<any> {
    return new Observable(observer => {
      let headers = this.getAuthHeaders();

      if (version) {
        headers = this.appendVersionHeaders(headers, version);
      }

      if (cached) {
        headers = headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
      }

      this.http.get(url, {headers: headers}).subscribe(
        result => this.authObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  authenticatedPatch(url, params): Observable<any> {
    return new Observable(observer => {
      const headers = this.getAuthHeaders();

      this.http.patch(url, params, {headers}).subscribe(
        result => this.authObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  authenticatedPut(url: string, params, version?: string): Observable<any> {
    return new Observable(observer => {
      let headers = this.getAuthHeaders();

      if(version) {
        headers = this.appendVersionHeaders(headers, version);
      }

      this.http.put(url, params, {headers}).subscribe(
        result => this.authObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  authenticatedDelete(url): Observable<any> {
    return new Observable(observer => {
      const headers = this.getAuthHeaders();

      this.http.delete(url, {headers}).subscribe(
        result => this.authObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  cmsGet(url:string, locale:string, version?:number|null, query?: string[]|null, cached:boolean = false): Observable<any> {
    return new Observable(observer => {
      const params: any = {
        environment: environment.cmsEnvironment,
        locale: locale.toLowerCase(),
        include_fallback: true
      };

      if (version) {
        params.version = String(version);
      }

      if (query) {
        params.query = `{"uid":{"$in":["${query.join('","')}"]}}`;
      }

      let httpHeaders = this.getCmsHeaders();

      if (cached) {
        httpHeaders = httpHeaders.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
      }

      const httpOptions = {
        headers: httpHeaders,
        params
      };

      this.http.get(url, httpOptions).subscribe(
        result => this.defaultObserverResultResolver(result, observer),
        error => this.defaultObserverErrorResolver(error, observer)
      );
    });
  }

  cmsRegionGet(translation:TranslationInterface, locale:string, version?:number|null, query?: string[]): Observable<any> {
    return new Observable(observer => {
      const url = `${environment.cmsUrl}/v3/content_types/${translation.region}/entries`;

      this.cmsGet(url, locale, version, query).subscribe( response => {
        if(response?.hasOwnProperty('entries')) {
          response = response.entries;
        }

        observer.next(response);
        observer.complete();
      },
      error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  cmsRegionGetDetail(translation:TranslationInterface, locale:string, version?:number|null, query?:string[]): Observable<any> {
    return new Observable(observer => {
      const url = `${environment.cmsUrl}/v3/content_types/${translation.region}/entries/${translation.key}`;

      this.cmsGet(url, locale, version, query).subscribe( response => {
        if(response?.hasOwnProperty('entry')) {
          response = response.entry;
        }

        observer.next(response);
        observer.complete();
      },
      error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public getCmsHeaders(): HttpHeaders {
    return new HttpHeaders({
      api_key:  environment.cmsApiKey,
      access_token: environment.cmsAccessToken
    });
  }

  private appendVersionHeaders(headers, version) {
    if (version !== 'v1') {
      var re = /v1/gi;
      return new HttpHeaders({
        'Authorization': headers.get('Authorization'),
        'Accept': environment.accept_header.replace(re, version),
        'Content-Type': environment.content_type_header.replace(re, version)
      })
    }

    return headers;
  }

  private getBasicAuthHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Basic ${btoa(`${environment.auth_username}:${environment.auth_password}`)}`
    });
  }
  public  getAuthHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.authenticationService.getAccessToken()}`
    });
  }
}
