import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatchingFieldsValidator } from './../../../validators/matching-fields-validator';
import { PasswordPolicyValidator } from './../../../validators/password-policy-validator';
import { PasswordPolicy } from './../../../models/password-policy';
import { PasswordService } from './../../../services/password.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HelpCenterModalComponent } from '../../../modals/help-center-modal/help-center-modal.component';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-forgot-password-reset',
  templateUrl: './forgot-password-reset.component.html',
  styleUrls: ['./forgot-password-reset.component.scss']
})
export class ForgotPasswordResetComponent implements OnInit {
  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public policy: PasswordPolicy;
  public isLoading: boolean;
  public code: string;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public passwordService: PasswordService,
    public router: Router,
    public route: ActivatedRoute,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public modalService: BsModalService
  ) { }

  ngOnInit() {
    this.formSetup();

    if(this.route.snapshot.queryParams.code) {
      this.code = this.route.snapshot.queryParams.code;
    } else {
      this.router.navigateByUrl('/');
    }

    this.passwordService.forgotPasswordPolicy(this.code).subscribe(
      (policy) => this.onPolicySuccess(policy),
      (error) => this.onPolicyError()
    );
  }

  formSetup() {
    this.form = this.formBuilder.group({
      new_password: ['', [Validators.required]],
      new_password_verification: ['', [Validators.required]]
    }, {
      validators: [MatchingFieldsValidator.validate('new_password', 'new_password_verification', 'mismatch')]
    });

    this.setPolicyValidator();
  }

  onPolicySuccess(policy: PasswordPolicy) {
    this.policy = policy;
    this.setPolicyValidator();
  }

  onPolicyError() {
    this.policy = null;
  }

  setPolicyValidator() {
    if(this.form && this.policy)  {
      this.form.get('new_password').setValidators([
        Validators.required,
        PasswordPolicyValidator.validate(this.policy)
      ])
    }
  }

  formSubmit() {
    if(!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.resetPassword();
    }
  }

  resetPassword() {
    this.isLoading = true;
    this.passwordService.resetPassword(this.code, this.form.value.new_password, this.form.value.new_password_verification).subscribe(
      () => this.onResetPasswordSuccess(),
      error => this.onResetPasswordError(error)
    );
  }

  onResetPasswordSuccess() {
    this.isLoading = false;
    const msg = this.translate.instant('pages.flow.reset_password.success');

    this.toastrService.info(msg, null, {
      disableTimeOut: false,
      timeOut: 6000
    });

    this.router.navigateByUrl('/');
  }

  onResetPasswordError(response) {
    this.isLoading = false;

    if(response?.error?.errors[0]?.key) {
      const key = response.error.errors[0].key;

      // Cases for the newly provided password
      if(key === 'ERROR_IDENTITY_PASSWORD_NOT_MATCHING_CRITERIA' || key === 'ERROR_IDENTITY_PASSWORD_USED_ALREADY') {

        this.validationVisible = true;

        this.form.get('new_password').reset();
        this.form.get('new_password_verification').reset();

        if(key == 'ERROR_IDENTITY_PASSWORD_NOT_MATCHING_CRITERIA') {
          this.form.get('new_password').setErrors({
            password_policy: true
          });
        }

        if(key == 'ERROR_IDENTITY_PASSWORD_USED_ALREADY') {
          this.form.get('new_password').setErrors({
            password_already_used: true
          });
        }

      // Case for invalid password code (by query param)
      } else if (key === 'ERROR_IDENTITY_FORGOT_PASSWORD_CODE_INVALID' || key === 'ERROR_IDENTITY_FORGOT_PASSWORD_CODE_EXPIRED') {
        this.router.navigateByUrl('link-invalid');
      }
    }
  }

  openHelpCenter() {
    this.modalService.show(HelpCenterModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg modal-compact'
      })
    );
  }
}
