import { TranslationInterface } from '../interfaces/translation.interface';

export class PathwayMessage {
  public id: string;
  public subject: TranslationInterface;
  public message: TranslationInterface;
  public message_data: any;
  public message_content: Array<string>;
  public send_at: string;
  public read_at: string;
  public className =  'PathwayMessage';
  
  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item?.id;
    this.subject = item?.subject;
    this.message = item?.message;
    this.message_data = item?.message_data;
    this.send_at = item?.send_at;
    this.read_at = item?.read_at;
  }

  addMessageContent(input: Array<string>) {
    this.message_content = [];

    input.forEach((text: string) => {
      this.message_content.push(this.replaceVariables(text));
    });
  }

  replaceVariables(text: string) {
    if(!this.message_data) {
      this.message_data = {};
    }

    let keys = Object.keys(this.message_data);
    keys.forEach(key => {
      const search = '{{' + key + '}}';
      const value = this.message_data[key];
      const regex = new RegExp(search, "g");
      text =  text.replace(regex, value);
    });
    return text;
  }

  get contextTiming(): Date {
    if(this.send_at) {
      return new Date(this.send_at);
    } else {
      return new Date(null);
    }
  }

  get translationKey():string {
    return `shared.${this.subject?.region}.${this.subject?.key}`;
  }

  get messageTranslationKey():string {
    return `shared.${this.message?.region}.${this.message?.key}`;
  }

  get isPreview(): boolean {
    if(!this.message) {
      return true;
    } else {
      return false;
    }
  }

  get testingID(): string {
    return ['pathway-message', this.id].join('-');
  }

  get isRead():boolean {
    return this.read_at ? true : false;
  }

  get isRecent() {
    if(this.isRead) {
      if (this.daysOld <= 7) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  get daysOld():number {
    if(this.read_at) {
      let current = new Date();
      let read = new Date(this.read_at);
      let difference = current.getTime() - read.getTime();
      return Math.ceil(difference / (1000 * 3600 * 24));
    } else {
      return 0;
    }
  }
}
