<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/dashboard" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.dashboard.checklists' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!checklists?.length">
      <span [inlineSVG]="'/assets/svg-color/approve.svg'"></span>
      <h3>{{ 'pages.default.checklists.no_checklists_yet' | translate }}</h3>
    </div>
    <div class="row" *ngIf="">

    </div>
  </div>
</div>
