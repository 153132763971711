<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/settings" class="text-secondary font-weight-bold d-flex align-items-center">
          <span [inlineSVG]="'/assets/svg/left-arrow-big.svg'" class="rtl-d-none m-end-1"></span>
          <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="d-none rtl-d-inline m-end-1"></span>
          <span>{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ patient?.first_name }}&nbsp;{{ patient?.last_name }}</h1>
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div id="zone_your_information">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.personal.personal_details' | translate }}</h2>
        </div>
      </div>

      <app-page-loader *ngIf="!patient" [padding]="'30px 0'"></app-page-loader>

      <div *ngIf="patient" class="bg-white mt-3 mb-1 action-item-block">

        <div *ngIf="patient" class="row align-items-center p-3">
          <div class="col-auto m-end-4">
            <app-avatar class="profile-picture" [profilePicture]="patient?.profile_picture"></app-avatar>
          </div>

          <div class="col-auto">
            <div class="label-value-pairs row">

              <div class="pair m-end-8">
                <label class="tiny m-0">{{ 'form.labels.preferred_name' | translate }}</label>
                <p *ngIf="patient?.preferred_name">{{ patient?.preferred_name }}</p>
                <p *ngIf="!patient?.preferred_name" class="text-muted">{{ patient?.first_name }}</p>
              </div>
              <div class="pair m-end-8">
                <label class="tiny m-0">{{ 'form.labels.country' | translate }}</label>
                <p class="m-0">{{country?.label}}</p>
              </div>
              <div class="pair m-end-8">
                <label class="tiny m-0">{{ 'form.labels.language' | translate }}</label>
                <p class="m-0">{{ language?.translationKey | translate }}</p>
              </div>

            </div>
          </div>

        </div>

        <a (click)="showEditPersonalDetailsModal($event)"
           *ngIf="patient"
           class="block-action m-start-auto"
           href=""
           id="settings-personal-show-personal-details"
        >
          <span>{{ 'action.edit' | translate }}</span>
          <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
        </a>
      </div>
    </div>

    <div *ngIf="patient" class="bg-white p-3 mb-5">
      <div class="col-auto">
        <div class="row">
          <div class="col-auto m-end-6">
            <div class="label-value-pairs">

              <div class="pair mb-4">
                <label class="tiny m-0">{{ 'form.labels.first_name' | translate }}</label>
                <p>{{ patient?.first_name }}</p>
              </div>
              <div class="pair">
                <label class="tiny m-0">{{ 'form.labels.gender' | translate }}</label>
                <p *ngIf="patient?.gender==='MALE'">{{ 'form.labels.male' | translate }}</p>
                <p *ngIf="patient?.gender==='FEMALE'">{{ 'form.labels.female' | translate }}</p>
                <p *ngIf="patient?.gender==='UNKNOWN'">{{ 'form.labels.other' | translate }}</p>
              </div>

            </div>
          </div>

          <div class="col-auto m-end-6">
            <div class="label-value-pairs">

              <div class="pair mb-4">
                <label class="tiny m-0">{{ 'form.labels.last_name' | translate }}</label>
                <p>{{ patient?.last_name }}</p>
              </div>
              <div class="pair">
                <label class="tiny m-0">{{ 'form.labels.date_of_birth' | translate }}</label>
                <p>{{patient?.date_of_birth | timeZoneDate:dateFormat?.format}}</p>
              </div>

            </div>
          </div>

          <div class="text-muted align-self-center m-start-auto">
            <p class="mb-0">
              {{ 'pages.default.settings.personal.managed_by_your_hospital' | translate }}
            </p>
          </div>

        </div>
      </div>

    </div>

    <div id="zone_contact_details">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.personal.contact_details' | translate }}</h2>
        </div>
      </div>

      <div class="mt-3 mb-5">
        <div class="single-item-block mb-2 action-item-block">
          <div class="block-wrapper p-start-3 p-end-0">
            <span [inlineSVG]="'/assets/svg/mail.svg'" class="icon rtl-mirrored-inline-svg"></span>
          </div>
          <div class="block-wrapper">
            <p class="label">{{ 'form.labels.email' | translate }}</p>
            <p class="value" id="EmailValue">{{ profile?.email }}</p>
          </div>

          <a (click)="showEditEmailModal($event)" class="block-action m-start-auto" href="">
            <span>{{ 'action.edit' | translate }}</span>
            <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon m-start-1 rtl-mirrored-inline-svg"></span>
          </a>
        </div>

        <div class="single-item-block action-item-block">
          <div class="block-wrapper p-start-3 p-end-0">
            <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/phone.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label">{{ 'form.labels.phone' | translate }}</p>
            <p class="value" id="PhoneValue">{{ dialCode?.label }} {{ profile?.mobile_number?.number }}</p>
          </div>

          <a (click)="showEditPhoneModal($event)" class="block-action m-start-auto" href="">
            <span>{{ 'action.edit' | translate }}</span>
            <span class="icon m-start-1 rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/edit.svg'"></span>
          </a>
        </div>


          <!--  -->
          <!-- HIDDEN BY D-NONE - AS PER REQUEST OF: AGIK-5008 -->
          <!--  -->
          <div class="mb-2 mt-4 d-none" *ngIf="profile?.contact_consented">
            <p>{{ 'pages.default.settings.personal_info.contact_info' | translate }}</p>

            <form [formGroup]="contactForm" id="contactForm" class="form-width-limit">
              <div class="custom-control custom-radio mb-1">
                <input type="radio" class="custom-control-input" id="radioContactEmail" value="EMAIL"
                      formControlName="contact_channel" (change)="onHandleContactFormSubmission()">
                <label class="custom-control-label" for="radioContactEmail">{{ 'form.labels.email' | translate }}</label>
              </div>


              <div class="custom-control custom-radio mb-3" *ngIf="profile?.mobile_number?.number">
                <input type="radio" class="custom-control-input" id="radioContactPhone" value="PHONE"
                      formControlName="contact_channel" (change)="onHandleContactFormSubmission()">
                <label class="custom-control-label" for="radioContactPhone">{{ 'form.labels.phone' | translate }}</label>
              </div>
            </form>

            <div class="custom-control custom-radio mb-3" *ngIf="!profile?.mobile_number?.number">
              <input type="radio" class="custom-control-input" id="radioContactPhoneDisabled" disabled="disabled">
              <label class="custom-control-label" for="radioContactPhoneDisabled">{{ 'form.labels.phone' | translate }}
                <p class="small text-muted">{{ 'pages.default.settings.preferences.please_update_with_phone' | translate }}</p>
              </label>
            </div>

            <div class="bg-white p-3 mt-4">
              <h3 class="mb-1">
                <em [inlineSVG]="'/assets/svg-color/safety.svg'"></em>
                <span class="align-middle">{{ 'pages.default.settings.preferences.prefer_no_contact' | translate }}</span>
              </h3>

              <p class="m-0">{{ 'pages.default.settings.preferences.prefer_no_contact_info1' | translate }}</p>
              <p class="m-0">
                <a routerLink="/settings/consents" class="font-weight-bold text-secondary" id="settings-personal-prefer-no-contact">
                  {{ 'pages.default.settings.preferences.prefer_no_contact_info2' | translate }}
                </a>
              </p>
            </div>
          </div>

          <div *ngIf="!profile?.contact_consented">
            <div class="bg-white p-3 mt-4">
              <h3 class="mb-1">
                <em [inlineSVG]="'/assets/svg-color/safety.svg'"></em>
                <span class="align-middle">{{ 'pages.default.settings.preferences.no_consent_given' | translate }}</span>
              </h3>

              <p class="m-0">{{ 'pages.default.settings.preferences.no_consent_given_text' | translate }}</p>
              <p class="m-0">
                <a routerLink="/settings/consents" class="font-weight-bold text-secondary" id="settings-personal-contact-consent">
                  {{ 'pages.default.settings.preferences.prefer_no_contact_info2' | translate }}
                </a>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div id="zone_notifications" class="container">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.personal.notifications' | translate }}</h2>
        </div>
      </div>

      <div class="mt-2">
        <form [formGroup]="notificationsForm" id="notificationsForm">
          <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="checkboxNotificationsEmail" value="EMAIL"
            formControlName="email_notifications" (change)="onHandleNotificationsFormSubmission()">

            <label class="custom-control-label" for="checkboxNotificationsEmail">{{ 'form.labels.email' | translate }}</label>
          </div>
          <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="checkboxNotificationsPhone" value="PUSH"
                  formControlName="phone_notifications" >
            <label class="custom-control-label" for="checkboxNotificationsPhone">{{ 'form.labels.push' | translate }}</label>
          </div>

          <p class="text-muted">
            <span *ngIf="notificationsForm.get('phone_notifications').value">{{ 'pages.default.settings.personal.push_enabled' | translate }}</span>
            <span *ngIf="!notificationsForm.get('phone_notifications').value">{{ 'pages.default.settings.personal.push_disabled' | translate }}</span>
            {{ 'pages.default.settings.personal.modified_phone' | translate }}
          </p>
        </form>
      </div>
    </div>
  </div>
