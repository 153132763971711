<div class="layout-header-pusher header-tabs"></div>

<div class="layout-header header-tabs">
  <div class="header-content">
    <div class="container my-auto">
      <h1>{{ 'pages.default.dashboard.your_treatments' | translate }}</h1>
    </div>

    <div class="container">
      <app-page-tabs [asClassicTabs]="true" [items]="pageTabItems" (onTabChange)="onTabChangeHandler($event)"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading && !pathways?.length">
    <div class="empty-state my-5 my-lg-7 my-xl-9">
      <span [inlineSVG]="'/assets/svg-color/stethoscope.svg'"></span>
      <h3>{{ 'pages.default.dashboard.no_active_pathways' | translate }}</h3>
    </div>
  </div>

  <div class="container" *ngIf="!isLoading && pathwayId">
    <app-surgery-dates [pathway]="selectedPathway"></app-surgery-dates>
    <app-patient-reminders [pathway]="selectedPathway" (onCountChange)="onRemindersCountchange($event)" (onUpdate)="onRemindersUpdate($event)"></app-patient-reminders>

    <div class="mb-4 mb-lg-5">
      <h2 *ngIf="hasReminders" class="mt-4">{{ 'pages.default.dashboard.dashboard' | translate }}</h2>
      <div class="row">
        <div class="col-6">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'timeline']" id="btn-block-timeline">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg-color/process.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.timeline' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>

        <div class="col-6" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule() && selectedPathway?.goals?.length">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'goals']" id="btn-block-goals">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg-color/target.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.goals' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'learning-materials']" id="btn-block-learning-materials">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg-color/newsletter.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.learning_materials' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'query-lists']" [queryParams]="{filter: 'CHECKLIST'}" id="btn-block-checklists">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg" [inlineSVG]="'/assets/svg-color/approve.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.checklists' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>

        <div class="col-6" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'query-lists']" [queryParams]="{filter: 'QUESTIONNAIRE'}" id="btn-block-query-lists">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg" [inlineSVG]="'/assets/svg-color/forms.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.essential-forms' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'faq']" id="btn-block-faq">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg-color/info.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.faqs' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'hospital']" id="btn-block-hospital">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg-color/hospital.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.hospital' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>

        <div class="col-6" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
          <div class="single-item-block item-clickable mb-2" [routerLink]="['/patient', patientUid, pathwayId, 'medical-team']" id="btn-block-medical-team">
            <div class="block-wrapper p-end-1">
              <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg-color/medical-professional.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ 'pages.default.dashboard.medical_team' | translate }}</p>
            </div>
            <div class="block-action m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
