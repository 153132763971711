import { environment } from "../../environments/environment";

export class Image {
  private type: string;
  public uuid: string;
  public url: string;
  public data: string;
  public deleted: boolean;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.type = item.type;
    this.uuid = item.uuid;

    if(this.type && this.uuid) {
      this.url = `${environment.platformUrl}/images/${this.type}/${this.uuid}`;
    }

    this.deleted = false;
  }
}
