import { Component, OnInit } from '@angular/core';
import { DialCode } from '../../../models/dial-code';
import { Hospital, OpeningHoursSelectOptions } from '../../../models/hospital';
import { GeneralService } from '../../../services/general.service';
import { HospitalService } from '../../../services/hospital.service';
import { LanguageService } from '../../../services/language.service';


@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.scss']
})
export class HospitalComponent implements OnInit {
  public isLoading = false;
  public hospital: Hospital;
  public openingHoursVisible: boolean;

  public dialCodePhone;

  constructor(
    public hospitalService: HospitalService,
    public generalService: GeneralService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.getHospital();
  }

  getHospital() {
    this.isLoading = true;
    this.hospitalService.getCurrentHospital().subscribe(
      (result: Hospital) => {
        this.getHospitalSuccessHandler(result);
      },
      () => {
        this.getHospitalErrorHandler();
      }
    );
  }

  getHospitalSuccessHandler(result: Hospital) {
    this.hospital = result;
    this.isLoading = false;
  }

  getHospitalErrorHandler() {
    this.isLoading = false;
  }

  toggleOpeningHours(event) {
    event.preventDefault();
    this.openingHoursVisible = !this.openingHoursVisible;
  }

  getDialCode(code: string): DialCode {
    return this.generalService.mapPatientPhoneNumberCodeToDialCode(code);
  }

  isClosed(index): boolean {
    return this.hospital.opening_hours[index].hours.select === OpeningHoursSelectOptions.CLOSED;
  }

  isOpen24Hours(index): boolean {
    return this.hospital.opening_hours[index].hours.select === OpeningHoursSelectOptions.OPEN;
  }

  isCustom(index): boolean {
    return this.hospital.opening_hours[index].hours.select === OpeningHoursSelectOptions.CUSTOM;
  }

  isLocaleHebrew(): boolean {
    return this.languageService.getCurrentLanguageCode().isHebrew;
  }

}
