export class AttentionCenter {
  public appointmentsPending: number;
  public conversationMessagesUnread: number;

  constructor(item?) {
    this.fillFromJson(item || {});
  }

  fillFromJson(item) {
    this.appointmentsPending = item.appointments_pending || 0;
    this.conversationMessagesUnread = item.conversation_messages_unread || 0;
  }
}
