import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UserService} from '../../services/user.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-export-personal-data-modal',
  templateUrl: './export-personal-data-modal.component.html',
  styleUrls: ['./export-personal-data-modal.component.scss']
})
export class ExportPersonalDataModalComponent implements OnInit {
  public isLoading: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public userService: UserService,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {

  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleExport() {
    this.isLoading = true;
    this.userService.rightOfPortability().subscribe(
      () => this.onExportSuccess(),
      () => this.onExportError()
    )
  }

  onExportSuccess() {
    this.isLoading = false;
    this.toastrService.info(this.translate.instant('modals.export_personal_data.personal_data_exported'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
    return this.bsModalRef.hide();
  }

  onExportError() {
    this.isLoading = false;
    this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.router.navigateByUrl('error');
    return this.bsModalRef.hide();
  }
}
