import { Component, Input, OnInit } from '@angular/core';
import { Country } from '../../models/country';
import { HelpcenterSection } from '../../models/helpcenter-section';
import { CountryService } from '../../services/country.service';
import { PatientService } from '../../services/patient.service';

@Component({
  selector: 'app-help-center-contact',
  templateUrl: './help-center-contact.component.html'
})
export class HelpCenterContactComponent implements OnInit {
  @Input() helpcenterSection: HelpcenterSection;
  @Input() compact: boolean = false;

  public countries: Country[];
  public currentCountry: Country;

  constructor(
    public countryService: CountryService,
    public patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.getCountries();
  }

  getCountries(): void {
    this.countryService.getCountriesFromCms().subscribe((countries: Country[]) => {
      this.countries = countries.filter(country => country.phones?.length > 0);

      const currentPatient = this.patientService.getCurrentStoredPatient();

      if (currentPatient?.hasOwnProperty('country')) {
        this.currentCountry = this.countries.find(country => country.code === currentPatient['country']);
      } else {
        this.currentCountry = this.countries[0];
      }
    });
  }

  changeCountry(event, country: Country) {
    event.preventDefault();
    this.currentCountry = country;
  }
}
