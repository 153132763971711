import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-feedback-field',
  templateUrl: './feedback-field.component.html'
})
export class FeedbackFieldComponent {
  @Input() field: UntypedFormControl;
  @Input() errors: any;
  @Input() extraClass: string;
  @Input() meta: any;
}
