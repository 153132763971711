import { Component, OnInit } from '@angular/core';
import { SelfRegistrationService } from './../../../services/self-registration.service';
import { DataService } from './../../../services/data.service';
import { Hospital } from './../../../models/hospital';
import { Consent } from './../../../models/consent';
import { Router } from '@angular/router';
import { CareModule } from '../../../models/care-module';
import { LanguageService } from '../../../services/language.service';
import { LanguageCode } from 'src/app/models/language-code';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HelpCenterModalComponent } from '../../../modals/help-center-modal/help-center-modal.component';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-self-onboarding-code',
  templateUrl: './self-onboarding-code.component.html',
  styleUrls: ['./self-onboarding-code.component.scss']
})
export class SelfOnboardingCodeComponent implements OnInit {
  public codeInput: string;
  public codeIncorrect: boolean;
  public codeEmpty: boolean;
  public isLoading: boolean;
  protected languageCode: LanguageCode;

  constructor(
    public selfRegistrationService: SelfRegistrationService,
    public dataService: DataService,
    public languageService: LanguageService,
    public router: Router,
    public modalService: BsModalService
  ) {
    this.languageCode = this.languageService.getCurrentLanguageCode();
  }

  ngOnInit() {

  }

  submit() {
    if(this.isLoading) {
      return;
    }

    this.codeIncorrect = false;
    this.codeEmpty = false;

    if(!this.codeInput) {
      this.codeEmpty = true;
      return;
    }

    this.isLoading = true;
    this.selfRegistrationService.startSelfRegistration(this.codeInput, this.languageCode).subscribe(
      response => this.onStartSelfRegistrationSuccess(response),
      error  => this.onStartSelfRegistrationError(error)
    );
  }

  onStartSelfRegistrationSuccess(response: {hospital: Hospital, consents: Consent[], careModule: CareModule}) {
    this.isLoading = false;
    this.dataService.set(DataService.KeySelfOnboardingCode, this.codeInput);
    this.dataService.set(DataService.KeySelfOnboardingHospital, response.hospital);
    this.dataService.set(DataService.KeySelfOnboardingConsents, response.consents);
    this.dataService.set(DataService.KeySelfOnboardingCareModule, response.careModule);
    this.router.navigateByUrl('register/confirm-treatment');
  }

  onStartSelfRegistrationError(error: any) {
    this.isLoading = false;
    if(error.status === 404) {
      this.codeIncorrect = true;
    } else {
      this.router.navigateByUrl('error');
    }
  }

  showFieldValidation() {
    return Boolean(this.codeIncorrect || (this.codeEmpty && !this.codeInput));
  }

  openHelpCenter() {
    this.modalService.show(HelpCenterModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg modal-compact'
      })
    );
  }

}
