import { Component, OnInit, Input } from '@angular/core';
import { OnboardingState } from '../../enums/onboarding-state';
import { OnBoardingType } from '../../enums/onboarding-type';
import { Consent } from './../../models/consent';

@Component({
  selector: 'app-onboaring-icon-steps',
  templateUrl: './onboaring-icon-steps.component.html',
  styleUrls: ['./onboaring-icon-steps.component.scss']
})
export class OnboaringIconStepsComponent implements OnInit {
  @Input() onBoardingType: OnBoardingType;

  @Input() currentState: OnboardingState;

  @Input() consents: Consent[];
  @Input() currentConsent: Consent;

  public states: OnboardingState[] = [];
  public onboardingState = OnboardingState;
  public selfOnboardingStates = [OnboardingState.CONSENTS, OnboardingState.PROFILE, OnboardingState.APPROVAL];
  public ccOnboardingStates = [OnboardingState.CONSENTS, OnboardingState.PASSWORD, OnboardingState.PROFILE];

  constructor() {
  }

  ngOnInit() {
    if (this.onBoardingType === OnBoardingType.SELF) {
      this.states = this.selfOnboardingStates;
    } else if (this.onBoardingType === OnBoardingType.CC) {
      this.states = this.ccOnboardingStates;
    }
  }

  isStateActiveOrDone(state) {
    if (!state || !this.currentState) {
      return false;
    }

    const stateIndex = this.states.indexOf(state);
    const currentStateIndex = this.states.indexOf(this.currentState);
    return stateIndex <= currentStateIndex;
  }

}
