<div class="user-task-form">
  <div class="evaluation-loader" *ngIf="isEvaluating">
    <app-page-loader></app-page-loader>
  </div>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible, 'is-evaluating': isEvaluating}">
    <ng-container *ngFor="let field of userTask?.form_fields | sortBy:'asc': 'order'">
      <app-dynamic-input *ngIf="field?.visible" id="no-section-{{field?.id}}" [field]="field"
        [formControl]="form.get(field.id)" />

    </ng-container>

    <div *ngIf="userTask?.form_fields?.length > 0 && userTask?.sections?.length>0">
      <hr class="mt-4 mb-3" />
    </div>

    <ng-container *ngFor="let section of userTask?.sections | sortBy:'asc': 'order'; first as first">
      <div *ngIf="section.visible" class="card mb-3 mb-xl-4">
        <div class="card-body">
          <h3 class="text-dark mb-2" *ngIf="section?.title">{{ section?.translationKey | translate | evalTranslation }}
          </h3>
          <p *ngIf="section?.description" class="text-muted">{{ section.description | translateHelp | translate }}</p>
          <div class="mb-2" *ngIf="!section?.title"></div>

          <ng-container *ngFor="let field of section.form_fields | sortBy:'asc': 'order';">
            <app-dynamic-input *ngIf="field?.visible" id="section-{{field?.id}}" [field]="field"
              [formControl]="form.get(field.id)" />
          </ng-container>
          <div class="mt-n2"></div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
