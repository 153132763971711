import { Component, OnInit } from '@angular/core';
import { Consent, ConsentStatus } from './../../../models/consent';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from './../../../services/data.service';
import { SelfRegistrationService } from './../../../services/self-registration.service';
import { ConsentService } from './../../../services/consent.service';
import { Router } from '@angular/router';
import { DoNotConsentModalComponent } from './../../../modals/do-not-consent-modal/do-not-consent-modal.component';
import { GeneralService } from '../../../services/general.service';
import { OnboardingState } from '../../../enums/onboarding-state';
import { OnBoardingType } from '../../../enums/onboarding-type';

@Component({
  selector: 'app-self-onboarding-consent',
  templateUrl: './self-onboarding-consent.component.html',
  styleUrls: ['./self-onboarding-consent.component.scss']
})
export class SelfOnboardingConsentComponent implements OnInit {
  public allConsents: Consent[];
  public currentConsent: Consent;
  public revokeModal: BsModalRef;
  public isLoadingSkip: boolean;
  public isLoadingConsent: boolean;

  public onBoardingType = OnBoardingType.SELF;
  public currentState = OnboardingState.CONSENTS;

  constructor(
    public dataService: DataService,
    public selfRegistrationService: SelfRegistrationService,
    public router: Router,
    public modalService: BsModalService,
    public consentService: ConsentService
  ) { }

  ngOnInit() {
    if (!this.theOnboardingCode()) {
      this.dataService.clearSelfOnboardingKeys();
      this.router.navigateByUrl('register');
    }

    this.allConsents = this.dataService.get(DataService.KeySelfOnboardingConsents);
    this.consentService.getCmsInfo(this.allConsents).subscribe(result => {
      if (this.allConsents && this.allConsents.length) {
        this.currentConsent = this.allConsents[0];
      } else {
        this.doComplete();
      }
    });
  }

  canRevokeConsent() {
    return (this.currentConsent && this.currentConsent.isHospitalConsent());
  }

  canSkipConsent() {
    return (this.currentConsent && this.currentConsent.isPlatformConsent());
  }

  theOnboardingCode(): string {
    return this.dataService.get(DataService.KeySelfOnboardingCode);
  }

  handleDoConsent() {
    if (this.isLoadingConsent || this.isLoadingSkip) {
      return;
    }

    this.isLoadingConsent = true;
    this.currentConsent.handled = true;
    this.currentConsent.status = ConsentStatus.CONSENTED;

    this.handleNextAction();
  }

  handleSkipConsent() {
    if (this.isLoadingConsent || this.isLoadingSkip) {
      return;
    }

    this.isLoadingSkip = true;
    this.currentConsent.handled = true;
    this.currentConsent.status = ConsentStatus.DECLINED;

    this.handleNextAction();
  }

  showRevokeConsentModal() {
    if (this.isLoadingConsent || this.isLoadingSkip) {
      return;
    }

    this.revokeModal = this.modalService.show(DoNotConsentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
      })
    );

    this.revokeModal.content.onChoice.subscribe(() => this.handleRevokeConsent());
  }

  handleRevokeConsent() {
    this.currentConsent.status = ConsentStatus.DECLINED;
    this.dataService.clearSelfOnboardingKeys();
    this.router.navigateByUrl('register');
    this.revokeModal.hide();
  }

  handleNextAction() {
    this.isLoadingConsent = false;
    this.isLoadingSkip = false;

    const currentConsentIndex = this.allConsents.indexOf(this.currentConsent);

    if (currentConsentIndex < this.allConsents.length - 1) {
      this.showNextConsent();
    } else {
      this.doComplete();
    }
  }

  showNextConsent()  {
    const currentConsentIndex = this.allConsents.indexOf(this.currentConsent);
    this.currentConsent = this.allConsents[currentConsentIndex+1];
    window.scroll(0, 0);
  }
  doComplete() {
    this.router.navigateByUrl('/register/details');
  }
}
