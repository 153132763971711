<div class="container layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-info font-weight-bolder m-0 m-sm-auto" *ngIf="homeFlowService.getSidebarTitleKey()">
        {{ homeFlowService.getSidebarTitleKey() | translate }}</p>

      <app-onboaring-icon-steps *ngIf="showOnboardingIconSteps" [onBoardingType]="onBoardingType" [currentState]="currentState" [consents]="allConsents"
        [currentConsent]="currentConsent"></app-onboaring-icon-steps>
    </div>


    <div class="footer-part d-none d-sm-block mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">
    <div class="container">

      <app-page-loader *ngIf="!currentConsent"></app-page-loader>

      <div class="text-content-max" *ngIf="currentConsent">
        <h1 class="display-1 mb-3">{{ currentConsent?.title }}</h1>
        <h2 class="text-info mb-5">{{ currentConsent?.introduction }}</h2>
        <div [innerHtml]="currentConsent?.body"></div>
      </div>
    </div>

    <div class="container footer-part d-sm-none d-block my-8" *ngIf="currentConsent">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <div class="w-100 d-flex flex-column-reverse flex-xs-row justify-content-xs-end">
          <button id="button_onboarding_consents_revoke" class="btn btn-sm btn-link my-1 my-xs-0"
            *ngIf="canRevokeConsent()" (click)="showRevokeConsentModal()">{{ 'action.i_do_not_consent' | translate
            }}</button>
          <button id="button_onboarding_consents_skip" class="btn btn-sm btn-secondary m-start-xs-3 my-1 my-xs-0"
            *ngIf="canSkipConsent()" (click)="handleSkipConsent()" [ngClass]="{'loader': isLoadingSkip}">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoadingSkip"></em></span>
            <span>{{ 'pages.flow.onboarding_consent.maybe_later' | translate }}</span>
          </button>
          <button id="button_onboarding_consents_consent" class="btn btn-sm btn-secondary m-start-xs-3 my-1 my-xs-0"
            *ngIf="currentConsent" (click)="handleDoConsent()" [ngClass]="{'loader': isLoadingConsent}">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoadingConsent"></em></span>
            <span>{{ 'action.i_consent' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
