import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeepLinkService} from '../../services/deep-link.service';

@Component({
  selector: 'app-mobile-redirect',
  templateUrl: './mobile-redirect.component.html',
  styleUrls: ['./mobile-redirect.component.scss']
})
export class MobileRedirectComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly deepLinkService: DeepLinkService,
    private readonly activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.deepLinkService.deeplink(params.deepLinkUrl);
    });
  }
}
