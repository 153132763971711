import { TranslationInterface } from '../interfaces/translation.interface';
interface AppointmentSubjectInterface {
  user_provided: string;
  translation: TranslationInterface;
}

export class Reminder {
  uid: string;
  order: number;
  reminder_type: string;

  // appointment
  start_date: string;
  end_date: string;
  date: string;
  appointmentSubject: AppointmentSubjectInterface;

  // task
  taskType: string;
  createdAt: string;
  dueAt: string;
  title: TranslationInterface;
  title_key: TranslationInterface;
  className =  'Reminder';

  // educational material
  unreadMaterials: boolean;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.id;

    if (item.uid) {
      this.uid  = item.uid;
    }

    this.order = item.order;

    this.start_date = item.start_date;
    this.end_date = item.end_date;
    this.date = item.date;

    if ((item.start_date && item.end_date) || item.date) {
      this.appointmentSubject = item.subject;
    }

    this.taskType = item.type;
    this.createdAt = item.created_at;
    this.dueAt = item.due_at;
    this.title = item.title;
    this.title_key = item.title_key;

    this.unreadMaterials = item.unread_material;
    this.reminder_type = item?.reminder_type;
  }

  passed() {
    const date = new Date();
    date.setDate(date.getDate() - 2);
    const hours48Ago: Date = date;

    if (this.type === 'TASK' && new Date(this.createdAt).getTime() < hours48Ago.getTime()) {
      return true;
    }
    return false;
  }

  get contextTiming(): Date {
    if(this.type === 'TASK') {
      return new Date(this.createdAt);
    } else if(this.type === 'APPOINTMENT') {
      return new Date(this.start_date || this.date);
    } else {
      return new Date(null);
    }
  }

  public get isValid():boolean {
    if(!this.type) {
      return false;
    }  else {
      return true;
    }
  }

  get translationKey():string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  get type(): string {
    if((this.start_date || this.date) || this.reminder_type?.includes('PatientPathwayAppointmentReminder')) {
      return 'APPOINTMENT';
    } else if(this.unreadMaterials && this.reminder_type?.includes('PatientPathwayEducationalMaterialReminder')) {
      return 'UNREAD_MATERIALS';
    } else if(this.taskType) {
      return 'TASK';
    }
    return null;
  }

  get isActionReminder(): boolean {
    return ["TASK", "UNREAD_MATERIALS"].includes(this.type);
  }

  get testingID(): string {
    const bits: Array<string> = ["reminder", this.type?.toLocaleLowerCase()];
    if(this.uid) {
      bits.push(this.uid);
    }
    return bits.join('-');
  }
}
