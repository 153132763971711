<div class="modal-body pb-0">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h3>{{ 'modals.surgery_date.title' | translate }}</h3>
  <p class="m-0">{{ 'modals.surgery_date.description' | translate }}</p>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary m-start-3" (click)="bsModalRef.hide()">{{ 'action.close' | translate }}</button>
  </div>
</div>
