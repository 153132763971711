<div class="container layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-info font-weight-bolder" *ngIf="homeFlowService.getSidebarTitleKey()">{{
        homeFlowService.getSidebarTitleKey() | translate }}</p>

      <app-onboaring-icon-steps *ngIf="showOnboardingIconSteps" [onBoardingType]="onBoardingType"
        [currentState]="currentState"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part d-none d-sm-block mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">
    <div class="container">
      <h1 class="display-1 mb-3">{{ 'form.labels.your_pw' | translate }}</h1>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" [ngClass]="{'validation-visible': validationVisible}"
        autocomplete="off">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div class="form-group styled floating-label">
              <input type="password" id="inputAccountPassword" class="form-control w-100"
                [placeholder]="('pages.flow.onboarding_password.acc_pw' | translate)" formControlName="account_password"
                [ngClass]="{'ng-invalid-important': (form?.errors?.mismatch || passwordIsBad)}">
              <label for="inputAccountPassword" class="required">{{ 'pages.flow.onboarding_password.acc_pw' | translate
                }}</label>
              <p class="validation-feedback" *ngIf="form?.controls?.account_password?.errors">{{
                'form.feedback.pw_complexity_error' | translate }}</p>
              <p class="validation-feedback general-feedback" *ngIf="passwordIsBad">{{
                'form.feedback.pw_complexity_error' | translate }}</p>
            </div>

            <div class="form-group styled floating-label">
              <input type="password" id="inputConfirmPassword" class="form-control w-100"
                [placeholder]="('pages.flow.onboarding_password.confirm_pw' | translate)"
                formControlName="confirm_password" [ngClass]="{'ng-invalid-important': form?.errors?.mismatch}">
              <label for="inputConfirmPassword" class="required">{{ 'pages.flow.onboarding_password.confirm_pw' |
                translate }}</label>
              <p class="validation-feedback general-feedback" *ngIf="form?.errors?.mismatch">{{
                'pages.flow.onboarding_password.pw_not_the_same' | translate }}</p>
            </div>
          </div>
        </div>
      </form>

      <div class="bg-light p-3">
        <app-password-policy-rules [policy]="policy" [validationVisible]="validationVisible"
          [passwordControl]="form?.controls?.account_password"></app-password-policy-rules>
      </div>
    </div>

    <div class="container footer-part d-sm-none d-block my-8">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <div class="w-100 d-flex flex-column-reverse flex-xs-row justify-content-xs-end">
          <button class="btn btn-sm btn-secondary m-start-xs-3 my-1 my-xs-0" type="submit" form="form"
            [ngClass]="{'loader': isLoading}" [disabled]="!policy">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
            <span>{{ 'action.continue' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
