import { FileMetaData } from "./file-meta-data";

export enum Status {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  SAFE = 'SAFE',
  UNSAFE = 'UNSAFE',
  VIRUS_DETECTED = 'VIRUS_DETECTED',
  INCONSISTENT_MIME_TYPE = 'INCONSISTENT_MIME_TYPE',
  TOO_LARGE = 'TOO_LARGE'
}

export class Attachment {
  public uid:string;
  public status: string;
  public meta: FileMetaData;
  public file: File;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.status = item.status ? item.status : 'WAITING';
    this.meta = item.meta ? item.meta : {};
    this.file = item;

    if (item.metadata) {
      this.meta = new FileMetaData(item.metadata);
    }
  }

  get isAudio():boolean {
    if(this.meta?.extension?.includes('audio')
    || this.meta?.file_name?.endsWith('.mp3')
    || this.file?.type?.includes('audio') 
    || this.file?.name?.endsWith('.mp3')) {
      return true;
    } else {
      return false;
    }
  }
}