<div class="modal-body pb-0">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  <h2>{{ pathwayMessage?.translationKey | translate }}</h2>

  <div *ngIf="isLoading">
    <app-page-loader [padding]="'30px 0'"></app-page-loader>
  </div>

  <ng-container *ngIf="!isLoading">
    <ng-container *ngFor="let text of pathwayMessage?.message_content">
      <div [innerHtml]="text"></div>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" (click)="handleClose()">{{ 'action.close' | translate }}</button>
  </div>
</div>
