import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-banner-info-modal',
  templateUrl: './banner-info-modal.component.html',
  styleUrls: ['./banner-info-modal.component.scss']
})
export class BannerInfoModalComponent {
  public title: string;
  public description: string;

  constructor(
    public bsModalRef: BsModalRef,
  ) {}
}
