import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-banner-init-modal',
  templateUrl: './banner-init-modal.component.html',
  styleUrls: ['./banner-init-modal.component.scss']
})
export class BannerInitModalComponent {
  @Output() onShowMoreInfoModal: EventEmitter<any> = new EventEmitter();
  @Output() onAcknowledgeBanner: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  public title: string;
  public hasMoreInfo: boolean;
  public description: string

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  confirm() {
    this.onAcknowledgeBanner.emit();
  }

  showMoreInfoModal() {
    this.onShowMoreInfoModal.emit();
  }

  hide() {
    this.onHide.emit();
  }
}
