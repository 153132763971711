import { Component } from '@angular/core';
import { OnboardingState } from '../../../enums/onboarding-state';
import { OnBoardingType } from '../../../enums/onboarding-type';

@Component({
  selector: 'app-self-onboarding-approval',
  templateUrl: './self-onboarding-approval.component.html',
  styleUrls: ['./self-onboarding-approval.component.scss']
})
export class SelfOnboardingApprovalComponent{
  public onBoardingType = OnBoardingType.SELF;
  public currentState = OnboardingState.APPROVAL
}
