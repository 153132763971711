import { HealthCareProfessional } from "./health-care-professional";

interface Mdt {
  uid: string;
  name: string;
  hcp_uids: Array<string>;
  hcps: Array<HealthCareProfessional>;
}

export class PatientMdt {
  public hcps: Array<HealthCareProfessional>;
  public mdts: Array<Mdt>;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.hcps) {
      this.hcps = item.hcps.map(_hcp => new HealthCareProfessional(_hcp));
    }

    this.mdts = item.mdts;

    if (this.mdts) {
      this.mdts.forEach(_mdt => {
        _mdt.hcps = this.hcps.filter(_hcp => _mdt.hcp_uids.includes(_hcp.uid))
      });
    }
  }

  private filterOnlyActiveHcps(list: Array<HealthCareProfessional>): Array<HealthCareProfessional> {
    if(list) {
      return list.filter(_hcp => _hcp.status === 'ACTIVE');
    } else {
      return undefined;
    }
  }

  getAllHcps(onlyActiveHcps: boolean = true) {
    if (onlyActiveHcps) {
      return this.filterOnlyActiveHcps(this.hcps);
    } else {
      return this.hcps;
    }
  }

  getMdt(uid: string, onlyActiveHcps: boolean = true) {
    if (this.mdts) {
      let mdt:Mdt = this.mdts.find(_mdt => _mdt.uid === uid);

      if (mdt) {
        if (onlyActiveHcps) {
          mdt = Object.assign({}, mdt); // clone, so the original is not affected
          mdt.hcps = this.filterOnlyActiveHcps(mdt.hcps);
          mdt.hcp_uids = mdt.hcps.map(_hcp => _hcp.uid);
        }

        return mdt;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getHcpsByMdt(uid: string, onlyActiveHcps: boolean = true) {
    let mdt: Mdt = this.getMdt(uid, onlyActiveHcps);

    if (mdt) {
      return mdt.hcps;
    } else {
      return undefined;
    }
  }
}
