import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { ConsentService } from '../../../services/consent.service';


@Component({
  selector: 'app-cookie-page',
  templateUrl: './cookie-page.component.html',
  styleUrls: ['./cookie-page.component.scss']
})
export class CookiePageComponent implements OnInit {
  public policy:any;
  public description: SafeHtml;

  constructor(
    public consentService: ConsentService,
    public route: ActivatedRoute,
    public languageService: LanguageService,
    private sanitized: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    let locale: string;

    const queryParams:Params = this.route.snapshot.queryParams;

    if(queryParams && queryParams?.locale) {
      locale = queryParams?.locale
    } else {
      locale = this.languageService.getCurrentLanguageCode().locale;
    }

    this.getCookiePolicy(locale);
  }

  getCookiePolicy(locale) {
    this.consentService.getCookiePolicy(locale).subscribe(result => {
      this.policy = result;
      this.description = this.sanitized.bypassSecurityTrustHtml(result['description']);

      setTimeout(() => {
        (<any>window).window.OneTrust.initializeCookiePolicyHtml();
      }, 1);
    });
  }
}
