<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/patient', patientUid, patientPathwayUid, 'faq']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'pages.default.faq_detail.back_to_faq' | translate }}</span>
        </a>
      </p>
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container">
    <div class="article-content">

      <!-- content-head - start -->
      <!-- (should always be here, is not in the repeater loop) -->
      <div class="content-head">
        <h1 class="m-0">{{ faq?.question }}</h1>
      </div>
      <!-- content-head - end -->
      <ng-container *ngFor="let answer of faq?.answer">
        <div class="content-partial" *ngIf="answer['text']" [innerHtml]="answer.text.text"></div>
        <div class="content-image" *ngIf="answer['image']">
          <img [src]="answer.image.file.url">
        </div>
      </ng-container>

      <div class="content-custom mt-7 mt-lg-8" *ngIf="otherFaqs?.length">
        <div class="single-item-block block-muted mb-2">
          <div class="block-wrapper">
            <p class="label">{{ 'pages.default.faq_detail.other_in' | translate }} {{ category?.title }}</p>
          </div>
        </div>

        <div class="faq-block">
          <div class="items">
            <ng-container *ngFor="let item of otherFaqs">
              <a href="" (click)="goToFaq($event,item, category)" class="d-flex align-items-center">
                <span>{{ item.question }}</span>
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
