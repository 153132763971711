
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HealthCareProfessional } from '../../../models/health-care-professional';
import { Pathway } from '../../../models/pathway';
import { GeneralService } from '../../../services/general.service';
import { PatientService } from '../../../services/patient.service';

@Component({
  selector: 'app-medical-team',
  templateUrl: './medical-team.component.html',
  styleUrls: ['./medical-team.component.scss']
})
export class MedicalTeamComponent implements OnInit {
  public isLoading = false;

  public pathway: Pathway;
  public caseManager: HealthCareProfessional;
  public clinicalLead: HealthCareProfessional;
  public hcps: Array<HealthCareProfessional>

  constructor(
    public patientService: PatientService,
    private _route: ActivatedRoute,
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.getMedicalTeam(params.patientPathwayUid);
    });
  }

  getMedicalTeam(patientPathwayUid: string) {
    this.isLoading = true;
    this.patientService.getPathway(patientPathwayUid).subscribe(pathway => {
      this.onGetPathwaySuccess(pathway);
    }, () => {
      this.isLoading = false;
    });
  }

  onGetPathwaySuccess(pathway) {
    this.isLoading = false;

    this.pathway = pathway;

    this.caseManager = this.pathway.case_manager;
    this.clinicalLead = this.pathway.clinical_lead;

    this.hcps = this.pathway.getHcps(false, false, true);
    this.generalService.sortByKey(this.hcps, 'last_name');
  }

}
