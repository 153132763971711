import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Material } from '../../../models/material';
import { MaterialPhase } from '../../../models/material-phase';
import { DataService } from '../../../services/data.service';
import { MaterialService } from '../../../services/material.service';

@Component({
  selector: 'app-material-detail',
  templateUrl: './material-detail.component.html',
  styleUrls: ['./material-detail.component.scss']
})
export class MaterialDetailComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;
  public educationalMaterialUid: string;
  public material: Material;
  public phase: MaterialPhase;
  public isLoadingMaterial: boolean;
  public unread: Array<Material>;
  private pageProgress: number = 0;
  private interval: number;

  @HostListener('window:scroll', [])
  onScroll() {
    const articleContent = document.querySelector('.article-content');

    if (articleContent) {
      const scrollOffset = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
      const articleTop = articleContent.getBoundingClientRect().top + scrollOffset;
      const articleHeight = articleContent.scrollHeight;
      const windowHeight = window.innerHeight;

      const articleScrollOffset = scrollOffset - articleTop;
      const scrollProgress = Math.round((articleScrollOffset / (articleHeight - windowHeight)) * 100);

      if (scrollProgress > this.pageProgress && scrollProgress <= 100) {
        this.pageProgress = scrollProgress;
      }
    }
  }

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public materialService: MaterialService,
    public dataService: DataService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;
      this.educationalMaterialUid = params.educationalMaterialUid;

      this.interval = window.setInterval(() => {
        this.updateProgress();
      }, 5000);

      this.getPhaseData();
    });
  }

  ngOnDestroy() {
    this.updateProgress();

    window.clearInterval(this.interval);
  }

  getPhaseData() {
    this.phase = this.dataService.get(DataService.KeyLearningPhaseSelected);

    if (!this.phase) {
      return;
    }

    this.material = this.phase.educational_materials.find((m: Material) => m.uid === this.educationalMaterialUid)

    this.unread = this.phase.educational_materials.filter((m: Material) => {
      return (!m.isCompleted && this.educationalMaterialUid !== m.uid)
    });
  }

  setProgress(progress = 100) {
    if (!this.material || progress <= this.material.progress) {
      return;
    }

    this.material.progress = progress;
    this.materialService.setMaterialProgress(this.patientUid, this.patientPathwayUid, this.educationalMaterialUid, progress).subscribe(() => {
      this.refreshPhaseData();
    });
  }

  updateProgress() {
    // use scroll for progress indication, only for articles
    if (this.material && !this.material.isMedia) {
      this.setProgress(this.pageProgress);
    }
  }

  refreshPhaseData() {
    this.materialService.getTranslatedMaterialsMappedToMainPhase(this.patientUid, this.patientPathwayUid).subscribe(result => {
      if(result?.length) {
        const foundPhase: MaterialPhase = result.find((_phase: MaterialPhase) => _phase.phase_id == this.phase.phase_id);

        if(foundPhase) {
          this.phase = foundPhase;
          this.dataService.set(DataService.KeyLearningPhaseSelected, this.phase);
        }
      }
    });
  }

  goBack(event) {
    event.preventDefault();
    this.router.navigateByUrl(`/patient/${this.patientUid}/${this.patientPathwayUid}/learning-materials`);
  }

  openMaterial(event, material?: any) {
    event.preventDefault();
    this.dataService.set(DataService.KeyLearningMaterialSelected, material);
    this.router.navigateByUrl(`/patient/${this.patientUid}/${this.patientPathwayUid}/learning-materials/article/${material.uid}`);
  }
}
