import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormField } from '../../models/form-field';
import { FFUiStyleOption, FFUiValueStyleOption } from '../../models/form-field-ui-config';

@Component({
  selector: 'app-styled-question',
  templateUrl: './styled-question.component.html',
})
export class StyledQuestionComponent {
  @Input() field: FormField;
  @Input() value: any;
  @Input() errors: any;
  @Output() valueChange = new EventEmitter<any>();

  public uiStyleOption = FFUiStyleOption;
  public uiValueStyleOption = FFUiValueStyleOption;

  onValueChange(e) {
    this.value = e;
    this.valueChange.emit(e);
  }
}
