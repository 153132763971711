<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">

    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2>{{ 'modals.edit_convo_subject.edit_subject' | translate }}</h2>

    <div class="form-group styled required floating-label mb-0">
      <input type="text" id="inputSubject" class="form-control w-100" [placeholder]="('modals.edit_convo_subject.subject' | translate)" formControlName="subject" maxlength="{{subjectMaxLength}}" required>
      <label for="inputSubject">{{ 'modals.edit_convo_subject.subject' | translate }}</label>
      <p class="small text-muted text-align-end m-0">{{form?.controls?.subject?.value?.length}}/{{subjectMaxLength}}</p>
      <app-feedback-field [field]="form.get('subject')"></app-feedback-field>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isSaving}" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
