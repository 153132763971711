import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserTask } from '../models/user-task';
import { UserTaskBasic } from '../models/user-task-basic';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserTaskService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getUserTasks(
    patient_uid: string,
    filters: any = {},
    page = 0,
    size = 10,
    sort: string[] = []
  ): Observable<{
    tasks: Array<UserTaskBasic>,
    pagination: any
  }> {
    return new Observable(observer => {
      const paramBits = [
        `page=${page}`,
        `size=${size}`
      ];

      if (sort.length) {
        sort.forEach(s => {
          paramBits.push(`sort=${s}`);
        });
      }

      if (filters.hasOwnProperty('min_priority')) {
        paramBits.push(`min_priority=${filters.min_priority}`);
      }

      if (filters.hasOwnProperty('max_priority')) {
        paramBits.push(`max_priority=${filters.max_priority}`);
      }

      if (filters.hasOwnProperty('goal_id')) {
        paramBits.push(`goal_id=${filters.goal_id}`);
      }

      if (filters.hasOwnProperty('patient_pathway_id')) {
        paramBits.push(`patient_pathway_id=${filters.patient_pathway_id}`);
      }

      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/patients/${patient_uid}/user-tasks?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        const tasks = this.mapBasicUserTasks(result['items']);

        observer.next({ tasks, pagination: result['pagination'] });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }

  getUserTask(id, patient_uid): Observable<UserTask> {
    const url = `${this.platformUrl}/patients/${patient_uid}/user-tasks/${id}`;
    return new Observable(observer => {
      // TODO: Hide AFSQ-1565
      // this.authenticatedGet(url, 'v2').subscribe(result => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapUserTask(result));
        observer.complete();
      }, err => {
        observer.error(err);
        observer.complete();
      });
    });
  }

  evaluateUserTask(id, patient_uid, fields): Observable<any> {
    const url = `${this.platformUrl}/patients/${patient_uid}/user-tasks/${id}/evaluate-form`;
    const data = { fields };

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  completeUserTask(id, patient_uid, data): Observable<UserTask> {
    const url = `${this.platformUrl}/patients/${patient_uid}/user-tasks/${id}`;
    return new Observable(observer => {
      this.authenticatedPut(url, data).subscribe(result => {
        observer.next(this.mapUserTask(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  saveUserTask(id, patient_uid, data): Observable<UserTask> {
    const url = `${this.platformUrl}/patients/${patient_uid}/user-tasks/${id}`;
    return new Observable(observer => {
      this.authenticatedPatch(url, { variables: this.mapPayload(data) }).subscribe(result => {
        observer.next(this.mapUserTask(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  mapBasicUserTasks(result: any): Array<UserTaskBasic> {
    return result.map(item => this.mapBasicUserTask(item));
  }

  mapBasicUserTask(item) {
    return new UserTaskBasic(item);
  }

  mapUserTask(item) {
    return new UserTask(item);
  }

  mapPayload(data: any): any {
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    }

    return data;
  }
}
