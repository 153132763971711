export class PageTabItem {
  public translationKey: string;
  public htmlID: string;
  public data: any;

  constructor(translationKey, htmlID?, data?) {
    // The first param should now always be a complete transation slug, and not the translated value.
    this.translationKey = translationKey;
    this.htmlID = htmlID;
    this.data = data;
  }
}
