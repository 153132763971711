import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() {
    this.clearAll();
  }

  public static KeySelfOnboardingCode = 'self_onboarding_code';
  public static KeySelfOnboardingHospital = 'self_onboarding_hospital';
  public static KeySelfOnboardingConsents = 'self_onboarding_consents';
  public static KeySelfOnboardingCareModule = 'self_onboarding_careModule';

  public static KeyHcpOnboardingBoolean = 'hcp_onboarding_boolean';
  public static KeyLearningMaterialSelected = 'learning_material_selected';
  public static KeyLearningPhaseSelected = 'learning_phase_selected';

  public static KeyConsentSelected = 'consent_selected';

  public static HelpcenterSectionSelected = 'helpcenter_section_selected';
  public static HelpcenterArticleSelected = 'helpcenter_article_selected';
  public static HelpcenterFaqSelected = 'helpcenter_faq_selected';
  public static HelpcenterFaqCategorySelected = 'helpcenter_faq_category_selected';

  protected store: any;

  set(slug: string, data: any) {
    return this.store[slug] = data;
  }

  get(slug: string) {
    return this.store[slug];
  }

  clear(slug: string) {
    return delete this.store[slug];
  }

  clearAll() {
    this.store = {};
    return this.store;
  }

  clearSelfOnboardingKeys() {
    this.clear(DataService.KeySelfOnboardingHospital);
    this.clear(DataService.KeySelfOnboardingConsents);
    this.clear(DataService.KeySelfOnboardingCareModule);
    this.clear(DataService.KeySelfOnboardingCode);
  }

  clearHcpOnboardingKeys() {
    this.clear(DataService.KeyHcpOnboardingBoolean);
  }
}
