import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PathwayMessage } from '../../models/pathway-message';
import { PatientService } from '../../services/patient.service';

@Component({
  selector: 'app-pathway-message-modal',
  templateUrl: './pathway-message-modal.component.html',
  styleUrls: ['./pathway-message-modal.component.scss']
})
export class PathwayMessageModalComponent implements OnInit {
  @Input() pathwayMessage: PathwayMessage;
  @Input() patientPathwayID: string;

  @Output() onRead: EventEmitter<void> = new EventEmitter();

  public isLoading: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public patientService: PatientService
  ) { }

  ngOnInit(): void {
    this.getMessage();
  }

  getMessage() {
    if(!this.pathwayMessage) {
      return;
    }

    this.isLoading = true;
    this.patientService.getPathwayMessage(this.patientPathwayID, this.pathwayMessage.id).subscribe((pathwayMessage: PathwayMessage) => this.onMessageSuccess(pathwayMessage));
  }

  onMessageSuccess(pathwayMessage: PathwayMessage) {
    this.pathwayMessage = pathwayMessage;
    this.readPathwayMessage();

    setTimeout(() => {
      this.isLoading = false;
    }, 600);
  }

  readPathwayMessage() {
    if(this.pathwayMessage.isRead) {
      return;
    }

    this.patientService.readPathwayMessage(this.patientPathwayID, this.pathwayMessage, true).subscribe(() => {
      this.pathwayMessage.read_at = new Date().toDateString();
      this.onRead.emit();
    });
  }

  handleClose() {
    return this.bsModalRef.hide();
  }
}
