<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  
  <h3>{{ 'modals.logout.log_out' | translate }}</h3>
  <p class="m-0">{{ 'modals.logout.log_out_confirm' | translate }}<br/>{{ 'modals.logout.redirect_info' | translate }}</p>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleNo()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary m-start-3" (click)="handleYes()">{{ 'action.i_am_sure' | translate }}</button>
  </div>
</div>