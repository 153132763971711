<div class="modal-body">
  <image-cropper
    [imageBase64]="picture"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary m-start-3" (click)="handleCropped()">
      <span>{{ 'modal.profile_picture.finish' | translate }}</span>
    </button>
  </div>
</div>