import { Injectable, Output, EventEmitter } from '@angular/core';
import { UserService } from './user.service';
import { forkJoin, Observable } from 'rxjs';
import { PatientService } from './patient.service';
import { Patient } from '../models/patient';
import { PermissionHospital } from '../models/permissions';
import { CareModule } from '../models/care-module';

@Injectable({
  providedIn: 'root'
})
export class ScopeService {
  public static StorageKeyCurrentPatient = 'C4T_current_patient';

  @Output() public onScopeChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    public userService: UserService,
    public patientService: PatientService
  ) {
  }

  public initializeScope() {
    return new Observable((observer) => {
      ;
      this.userService.getPermissions().subscribe(() => {
        this.defineFirstPatient()
        .subscribe(() => {
          this.defineCareModules().subscribe(() => {
            observer.next();
            observer.complete();
          });
        });
      });
    });
  }

  public defineFirstPatient() {
    const permissions: any = this.userService.getStoredPermissions();

    if (!permissions.hospitals || !permissions.hospitals[0].patient) {
      return new Observable(observer => {
        observer.error();
      });
    } else {
      const patientMeta: any =  permissions.hospitals[0].patient;
      return this.changeCurrentPatient(patientMeta.uid, false);
    }
  }

  public defineCareModules() {
    return new Observable((observer) => {
      this.patientService.getPathways().subscribe(result => {
        const careModules = result.map((pathway) => pathway.care_module);
        this.patientService.storeCurrentCareModules(careModules);
        observer.next();
        observer.complete();
      });
    });
  }

  //
  // Used to change the app's current patient context
  //
  public changeCurrentPatient(uid: string, broadcastScopeChange = true): Observable<Patient> {
    return new Observable((observer) => {
      this.patientService.setCurrentPatient(uid).subscribe(patient => {
        observer.next(patient);

        if (broadcastScopeChange) {
          this.onScopeChanged.emit();
        }

        observer.complete();
      });
    });
  }

  public getCurrentHospitalBasic(): PermissionHospital {
    const permissions = this.userService.getStoredPermissions();
    const hospital_uid = this.patientService.getCurrentStoredHospitalUid();

    if (permissions && hospital_uid != null) {
      return permissions.getHospital(hospital_uid);
    } else {
      return null;
    }
  }

  public doesCurrentPatientHasPermission(rule: string) {
    const storedPatient = this.patientService.getCurrentStoredPatient();
    const permissions = this.userService.getStoredPermissions();

    if (permissions) {
      return permissions.doesPatientHasPermission(storedPatient.uid, rule);
    } else {
      return false;
    }
  }

  public doesCurrentPatientHasClicinalCareModule(): boolean {
    const careModules: CareModule[] = this.patientService.getCurrentStoredCareModules();

    if (careModules) {
      const types = careModules.map((cm) => {return cm.type})
      return types.includes('CLINICAL');
    }

    return false;
  }

  public refreshCurrentPatient(): Observable<Patient> {
    const patient = this.patientService.getCurrentStoredPatient();
    return this.changeCurrentPatient(patient.uid);
  }

  public refreshPermissions(): Observable<any> {
    return this.userService.getPermissions();
  }
}
