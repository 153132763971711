import { TranslationInterface } from '../interfaces/translation.interface';

export enum WizardOption {
  SECTIONS = 'SECTIONS',
  QUESTIONS = 'QUESTIONS'
}

export class UserTaskUiConfig {
  public intro: TranslationInterface;
  public intro_button: TranslationInterface;
  public wizard: WizardOption;
  public wizard_steps: WizardOption;

  constructor(item?: any) {
    if (item) {
      this.intro = item.intro;
      this.intro_button = item.intro_button;
      this.wizard = item.wizard;
      this.wizard_steps = item.wizard_steps;
    }
  }

  get translationIntroKey(): string {
    return `shared.${this.intro?.region}.${this.intro?.key}`;
  }

  get translationIntroButtonKey(): string {
    return `shared.${this.intro_button?.region}.${this.intro_button?.key}`;
  }

  get hasIntro(): boolean {
    return !!this.intro;
  }
}
