import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './../../../services/user.service';
import { PatientService } from './../../../services/patient.service';
import { Patient } from './../../../models/patient';
import { Profile } from './../../../models/profile';
import { forkJoin } from 'rxjs';
import { HomeFlowService } from '../../../services/home-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { OnBoardingType } from '../../../enums/onboarding-type';
import { OnboardingState } from '../../../enums/onboarding-state';
import { OnboardingFlow } from '../../../enums/onboarding-flow';

@Component({
  selector: 'app-onboarding-details',
  templateUrl: './onboarding-details.component.html',
  styleUrls: ['./onboarding-details.component.scss']
})
export class OnboardingDetailsComponent implements OnInit {
  @ViewChild('inputProfilePicture') inputProfilePicture: ElementRef;

  public patient: Patient;
  public profile: Profile;
  public isFormDataLoaded: boolean;
  public isUpdatingPatient: boolean;
  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public errorToast: any;
  public formComponentData: any;

  public contactMethodPhoneEnabled: boolean;

  public onBoardingType = OnBoardingType.CC;
  public currentState = OnboardingState.PROFILE;

  public showOnboardingIconSteps: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public toastService: ToastrService,
    public homeFlowService: HomeFlowService,
    public userService: UserService,
    public patientService: PatientService,
    public router: Router,
    public translate: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.flow === OnboardingFlow.ONBOARDING) this.showOnboardingIconSteps = true;
    });

    forkJoin([
      this.homeFlowService.getFirstPatientByPermissions(),
      this.userService.getProfile(),
    ]).subscribe(data => this.onDataLoaded(data))
  }

  onDataLoaded(data) {
    this.patient = data[0];
    this.profile = data[1];

    this.formComponentData = {
      patient: this.patient,
      profile: this.profile,
      locale: this.profile.locale
    }

    this.isFormDataLoaded = true;
  }

  onFormInitialized(form: UntypedFormGroup) {
    this.form = form;
    this.form.statusChanges.subscribe(() => this.toggleErrorToast());
  }

  formSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.handleSubmission();
    }

    this.toggleErrorToast();
  }

  toggleErrorToast() {
    if (this.validationVisible && !this.form.valid && !this.toastService.toasts.includes(this.errorToast)) {
      this.errorToast = this.toastService.error(this.translate.instant('form.feedback.data_error'), null);
    } else if (this.form.valid && this.errorToast) {
      this.toastService.clear(this.errorToast.toastId);
      this.errorToast = undefined;
    }
  }

  handleSubmission() {
    this.isUpdatingPatient = true;

    this.patient.preferred_name = this.form.get('preferred_name')?.value;
    this.patient.country = this.form.get('country')?.value;
    this.profile.mobile_number = {
      code: this.form.get('phone_number.code')?.value,
      number: this.form.get('phone_number.number')?.value
    };

    this.profile.mobile_verification_code = this.form.get('mobile_verification_code')?.value;
    this.patient.contact_channel = this.form.get('contact_channel')?.value;
    this.patient.profile_picture = this.form.get('profile_picture')?.value;

    this.profile.locale.notification_channels = [];
    if (this.form.get('notifications_method_email')?.value) {
      this.profile.locale.notification_channels.push('EMAIL');
    }

    if (this.form.get('notifications_method_text')?.value) {
      this.profile.locale.notification_channels.push('SMS');
    }


    forkJoin([
      this.patientService.update(this.patient),
      this.userService.updateProfile(this.profile)
    ]).subscribe(
      () => this.onUpdateSuccess(),
      (error) => this.onUpdateError(error)
    );
  }

  onUpdateSuccess() {
    this.homeFlowService.goNextStepOrDone().subscribe(() => {
      this.isUpdatingPatient = false;
    });
  }

  onUpdateError(error) {
    this.isUpdatingPatient = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        if (this.form.get(err.field)) {
          this.form.get(err.field).setErrors({
            backend_errors: true,
            message: err.key
          });
        }
      });
    }
  }
}
