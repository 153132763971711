<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_contact_details.edit_contact_details' | translate }}</h2>

  <form [formGroup]="form">
    <div *ngIf="(step == 'phone')">
      <div class="row">
        <div class="col-5" [ngClass]="{'order-last': isLocaleHebrew()}">
          <div class="form-group">
            <app-select
              [placeholder]="('form.labels.country_code' | translate)"
              [clearable]="false"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="dialCodeOptions"
              [bindValue]="'value'"

              [autoSortOnLabel]="true"
              [selectedItemsOnTop]="false"

              formControlName="country_code"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
          </div>
        </div>
        <div class="col-7" [ngClass]="{'order-first': isLocaleHebrew()}">
          <div class="form-group styled floating-label">
            <input type="tel" id="inputPhone" class="form-control w-100"
              [placeholder]="('form.labels.phone_number' | translate)" formControlName="phone">
            <label for="inputPhone">{{ 'form.labels.phone_number' | translate }}</label>
            <p class="validation-feedback" *ngIf="form?.controls['phone']?.errors?.phone_is_wrong">{{
              'form.feedback.phone_wrong_format' | translate }}</p>
          </div>
        </div>
      </div>



      <!--
      <div class="form-group styled floating-label">
        <div class="custom-control custom-checkbox custom-control-inline mb-1">
          <input type="checkbox" class="custom-control-input" id="checkboxNotificationsPhone"
            formControlName="notifications_method_sms">
          <label class="custom-control-label" for="checkboxNotificationsPhone">{{
            'modals.edit_contact_details.send_notification_number' | translate }}</label>
        </div>
      </div>
      -->
    </div>

    <div *ngIf="(step === 'code')">
      <p>{{ 'modals.edit_contact_details.enter_code' | translate }}</p>

      <div class="form-group styled floating-label">
        <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.edit_contact_details.verification_code' | translate)" formControlName="mobile_verification_code" required>
        <label for="inputCode">{{ 'modals.edit_contact_details.verification_code' | translate }}</label>

        <app-feedback-field [field]="form.get('mobile_verification_code')"></app-feedback-field>

        <p class="mt-1"><a href="" (click)="resendCode($event)">Resend code</a></p>

        <p class="text-muted mt-1">{{ 'modals.edit_contact_details.not_mobile_number' | translate }}</p>

      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isLoading}" *ngIf="(step === 'phone')" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span *ngIf="verificationCodeRequired()">{{ 'action.next_step' | translate }}</span>
      <span *ngIf="!verificationCodeRequired()">{{ 'action.confirm' | translate }}</span>
    </button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isLoading}" *ngIf="(step === 'code')" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
