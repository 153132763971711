<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo" [inlineSVG]="'/assets/svg/logo-patient.svg'"></div>
    </div>

    <div class="content-part">
      <h2>{{ 'pages.flow.device_not_supported.device_no_support' | translate }}</h2>
      <p>{{ 'pages.flow.device_not_supported.open_link_on_mobile' | translate }}</p>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>
</div>
