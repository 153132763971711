import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Faq } from '../../models/faq';
import { FaqCategory } from '../../models/faq-category';

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html'
})
export class FaqContentComponent{
  @Input() faq: Faq;
  @Input() category: FaqCategory;

  @Output() goToFaq = new EventEmitter<{faq: Faq, category: FaqCategory}>();
}
