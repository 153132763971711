<div class="row">
  <div class="col">
    <div class="form-group styled floating-label">
      <input type="text" id="inputDate" class="form-control w-100" [ngClass]="{'ng-invalid-important': !editDateValid}" bsDatepicker autocomplete="off" [(ngModel)]="editDateValue" (ngModelChange)="onDateNgModelChange($event)">
      <label for="inputDate">{{ 'general.date' | translate }}</label>
    </div>  
  </div>
  <div class="col">
    <div class="form-group">
      <timepicker id="timePickerField" class="with-label" [ngClass]="{'ng-invalid-important': !editTimeValid}" [showMeridian]="!time_24_hours" [showSpinners]="false" dir="ltr" [(ngModel)]="editTimeValue" (ngModelChange)="onTimeNgModelChange($event)"></timepicker>
      <label for="timePickerField">{{ 'general.time' | translate }}</label>
    </div>
  </div>
</div>