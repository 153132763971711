import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { PasswordPolicy } from '../models/password-policy';

@Injectable({
  providedIn: 'root'
})
export class PasswordService extends ApiService {
  private readonly platformUrl: string;

  constructor (
    http: HttpClient,
    authenticationService: AuthenticationService)
  {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  setPassword(new_password, new_password_verification) {
    const url = this.platformUrl + "/oauth/change-password";
    const params = {
      new_password: new_password,
      new_password_verification: new_password_verification
    };

    return this.authenticatedPost(url, params)
  }

  resetPassword(verification_code: string, new_password:string, new_password_verification) {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/forgot-password";
      const params = {
        verification_code: verification_code,
        new_password: new_password,
        new_password_verification
      }

      this.basicAuthPut(url, params).subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      })
    });
  }

  forgotPassword(email:string) {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/forgot-password";
      const params = {
        email
      }
      this.basicAuthPost(url, params).subscribe(result => {
        this.authenticationService.setAuthenticationData(result);
        observer.next(result);
      }, error => {
        observer.error(error);
      })
    });
  }

  policy():Observable<PasswordPolicy> {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/password-policy";
      this.authenticatedGet(url).subscribe(result => {
        const policy = new PasswordPolicy(result);
        observer.next(policy);
      }, error => {
        observer.error(error);
      });
    })
  }

  forgotPasswordPolicy(code):Observable<PasswordPolicy> {
    return new Observable(observer => {
      const url = this.platformUrl + "/oauth/forgot-password/policy";
      this.http.post(url, {code: code}).subscribe(result => {
        const policy = new PasswordPolicy(result);
        observer.next(policy);
      }, error => {
        observer.error(error);
      })
    })
  }
}

