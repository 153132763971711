import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.authentication;
    
    if (currentUser) {
        return true;
    }

    if(state.url && state.url!=='/dashboard') {
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    } else {
      this.router.navigate(['/']);
    }
    
    return false;
  }
}