import {Component, OnInit} from '@angular/core';
import {Patient} from '../../../../models/patient';
import {Profile} from '../../../../models/profile';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../services/user.service';
import {forkJoin} from 'rxjs';
import {GeneralService} from '../../../../services/general.service';
import {Router} from '@angular/router';
import {HomeFlowService} from '../../../../services/home-flow.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-preferences',
  templateUrl: './settings-preferences.component.html',
  styleUrls: ['./settings-preferences.component.scss']
})
export class SettingsPreferencesComponent implements OnInit {
  patient: Patient;
  profile: Profile;
  isFormDataLoaded = false;
  isUpdating: boolean;

  form: UntypedFormGroup;

  validationVisible: boolean;

  measurementUnits: any[];
  numberFormats: any[];
  dateFormats: any;
  timeFormats: any[];
  weekdays: any[];
  timeZones: any[];

  constructor(
    public formBuilder: UntypedFormBuilder,
    public toastService: ToastrService,
    public userService: UserService,
    public generalService: GeneralService,
    public router: Router,
    public homeFlowService: HomeFlowService,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();

    forkJoin([
      this.userService.getProfile(),
      this.generalService.getMeasurementUnits(),
      this.generalService.getNumberFormats(),
      this.generalService.getTimeFormats(),
      this.generalService.getWeekDays(),
      this.generalService.getTimeZones()
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data): void {
    this.profile = data[0];
    this.measurementUnits = data[1];
    this.numberFormats = data[2];
    this.timeFormats = data[3];
    this.weekdays = data[4];
    this.timeZones = data[5];

    if (this.generalService.dateFormatMap) {
      this.dateFormats = this.generalService.dateFormatMap.dateFormats;
    }

    this.setProfile();

    this.isFormDataLoaded = true;
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      measurement_unit: [''],
      number_format: [''],
      date_format: [''],
      time_24_hours: [''],
      first_day_of_week: [''],
      time_zone: ['']
    });
  }

  setProfile(): void {
    this.form.get('measurement_unit').setValue(this.profile.locale.measurement_unit);
    this.form.get('number_format').setValue(this.profile.locale.number_format);
    this.form.get('date_format').setValue(this.profile.locale.date_format);
    this.form.get('time_24_hours').setValue(this.profile.locale.time_24_hours);
    this.form.get('first_day_of_week').setValue(this.profile.locale.first_day_of_week);
    this.form.get('time_zone').setValue(this.profile.locale.time_zone);
  }

  onHandleUnitFormSubmission(): void {
    this.isUpdating = true;

    this.profile.locale.first_day_of_week = this.form.value.first_day_of_week;
    this.profile.locale.measurement_unit = this.form.value.measurement_unit;
    this.profile.locale.date_format = this.form.value.date_format;
    this.profile.locale.time_24_hours = this.form.value.time_24_hours;
    this.profile.locale.number_format = this.form.value.number_format;
    this.profile.locale.time_zone = this.form.value.time_zone;
    this.userService.updateProfile(this.profile).subscribe(
      () => this.onUpdateSuccess(),
      () => this.onUpdateError()
    );
  }

  onUpdateSuccess(): void {
    this.isUpdating = false;
    this.toastService.clear();
    this.toastService.info(this.translateService.instant('pages.default.settings.preferences.update_success'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  onUpdateError(): void {
    this.isUpdating = false;
    this.router.navigateByUrl('error');
  }
}
