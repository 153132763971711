import { Component, OnInit } from '@angular/core';
import { HomeFlowService } from '../../../services/home-flow.service';

@Component({
  selector: 'app-onboarding-redirect-to-app',
  templateUrl: './onboarding-redirect-to-app.component.html',
  styleUrls: ['./onboarding-redirect-to-app.component.scss']
})
export class OnboardingRedirectToAppComponent implements OnInit {
  public storeUrl:string;

  constructor(
    public homeFlowService: HomeFlowService
  ) { }

  ngOnInit(): void {
    this.evaluateDevice();
  }

  evaluateDevice() {
    if (/Android/i.test(navigator.userAgent)) {
        this.getStoreIconUrl('android');
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
       this.getStoreIconUrl('ios');
    } else {
        this.homeFlowService.escape();
    }
  }

  getStoreIconUrl(store: 'android' | 'ios') {
    this.homeFlowService.getStoreIconUrl(store).subscribe(url => {
      this.storeUrl = url;
    })
  }

  redirectToStore(): void {
    this.homeFlowService.redirectToStore();
  }
}
