import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Appointment } from '../../models/appointment';
import { LocaleService } from '../../services/locale.service';
import { DateFormat } from '../../models/date-format';
import { AppointmentSelectionEvent } from '../../events/appointment-selection-event';

@Component({
  selector: 'app-calendar-pending-invitations',
  templateUrl: './calendar-pending-invitations.component.html',
  styleUrls: ['./calendar-pending-invitations.component.scss']
})
export class CalendarPendingInvitationsComponent implements OnInit {
  @Output() onAppointmentSelect: EventEmitter<AppointmentSelectionEvent> = new EventEmitter();
  @Input() appointments: Appointment[];

  public dateFormat: DateFormat;
  public time24Hours: boolean;

  constructor(
    public localeService: LocaleService
  ) { }

  ngOnInit() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.time24Hours = this.localeService.getLocalePreferences().locale.time_24_hours;
  }

  appointmentSelectHandler(appointment: Appointment) {
    this.onAppointmentSelect.emit(new AppointmentSelectionEvent(null, appointment));
  }
}
