
<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo" [inlineSVG]="'/assets/svg/logo-patient.svg'"></div>
    </div>

    <div class="content-part">
      <h1 class="display-1 text-primary">
        <em [inlineSVG]="'/assets/svg-color/caution.svg'" class="icon m-end-2"></em>
        <span>{{ 'pages.general.maintenance.title' | translate }}</span>
      </h1>
      <h2 class="mb-4">{{ 'pages.general.maintenance.description' | translate }}</h2>
      <p *ngIf="from && until"><strong>{{ 'pages.general.maintenance.info' | translate:{ from: from, until: until } }}</strong></p>
      <p class="text-muted">{{ 'pages.general.maintenance.help' | translate }}</p>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights [languageSelect]="false"></app-flow-copyrights>
    </div>
  </div>
</div>
<div class="layout-body fixed with-background d-flex flex-column"></div>
