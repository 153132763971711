import {Participant} from './participant';
import {ConversationItem} from './conversation_item';
import moment from 'moment';

export class Conversation {
  public uid: string;
  public patient_pathway_id: string;
  public subject: string;
  public standard_reply_in_days: number;
  public draft: boolean;
  public owner: boolean;
  public unread_messages: number;
  public latest_message: string;
  public latest_message_type: string;
  public latest_message_time: string;
  public latest_message_from: Participant;
  public created_at: string;
  
  public patient_uid: string;
  public hcp_uids: Array<string>;

  public participants: Array<Participant>;
  public items: Array<ConversationItem> = [];


  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.patient_pathway_id = item.patient_pathway_id;
    this.subject = item.subject;
    this.standard_reply_in_days = item.standard_reply_in_days;
    this.draft = item.draft as boolean;
    this.owner = item.owner as boolean;
    this.unread_messages = item.unread_messages;
    this.latest_message = item.latest_message;
    this.latest_message_type = item.latest_message_type;
    this.latest_message_time = item.latest_message_time;
    this.latest_message_from = item.latest_message_from;
    this.created_at = item.created_at;
    
    this.patient_uid = item.patient_uid;
    this.hcp_uids = item.hcp_uids ? item.hcp_uids : '';

    this.participants = [];
    if(item.hcps) {
      item.hcps.forEach(hcp => {
        this.participants.push(new Participant(hcp));
      });
    }
  }

  setParticipants(participants: Array<Participant>) {
    this.participants = [];

    this.hcp_uids.forEach(uid => {
      const filtered = participants.filter((participant) => {
        return participant.uid === uid;
      });
      if (filtered[0] !== undefined) {
        this.participants.push(filtered[0]);
      }
    });
    this.sortParticipants();
  }

  getParticipantForUid(uid: string) {
    const filtered = this.participants.filter((participant) => {
      return participant.uid === uid;
    });
    if (filtered[0] !== undefined) {
      return filtered[0];
    }
  }

  activeParticipants(): Array<Participant> {
    return this.participants.filter( (participant) => (!participant.removed && participant.type != 'PATIENT') );
  }


  addItem(item) {
    this.items.push(new ConversationItem(item));
    this.latest_message = item.message;
    this.latest_message_time = item.created_at || moment.now().toLocaleString();
  }

  addItems(result, page) {
    if(page==0) {
      this.items = [];
    }
    
    result.forEach(item => {
      const conversation_item = new ConversationItem(item);
      conversation_item.page = page;
      this.items.unshift(conversation_item);
    });
  }

  fullNames() {
    this.sortParticipants();
    return this.participants.map((p) => `${p.first_name} ${p.last_name}`).join(', ');
  }

  sortParticipants() {
    this.participants.sort(function(a, b) {
      let textA = a.last_name.toUpperCase();
      let textB = b.last_name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  mergeDetails(convo: Conversation) {
    this.subject = convo.subject;
    this.unread_messages = convo.unread_messages;
    this.draft = convo.draft;
    this.latest_message = convo.latest_message;
    this.latest_message_time = convo.latest_message_time;
    this.items = convo.items;
  }
}
