import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

import { StartNewConversationModalComponent } from '../../../modals/start-new-conversation-modal/start-new-conversation-modal.component';
import { EditConversationSubjectModalComponent } from '../../../modals/edit-conversation-subject-modal/edit-conversation-subject-modal.component';
import { ConversationParticipantsModalComponent } from '../../../modals/conversation-participants-modal/conversation-participants-modal.component';
import { Conversation } from '../../../models/conversation';

import { DateFormat } from '../../../models/date-format';
import { GeneralService } from '../../../services/general.service';
import { ConversationService } from '../../../services/conversation.service';
import { ConversationItem } from '../../../models/conversation_item';
import { PatientService } from '../../../services/patient.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../services/locale.service';
import { from, Subscription } from 'rxjs';
import { AttentionCenterService } from '../../../services/attention-center.service';
import { Attachment, Status } from '../../../models/attachment';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit, OnDestroy {
  @ViewChild('newMsg') newMsgField: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('end')
  viewportRef!: ElementRef;

  public loadingInitialConversations: boolean;
  public currentConversation: Conversation;
  public conversations: Conversation[] = [];
  public dateFormat: DateFormat;
  public extensionList: string;
  public time_24_hours = false;
  public nrOfParticipants = 2;
  public isLoading = false;
  public isConversationLoading = false;

  public conversationsPagination: { current_page: number, total_pages: number } = { current_page: null, total_pages: null };
  public itemsPagination: { current_page: number, total_pages: number } = { current_page: null, total_pages: null };

  public loadingPageIndex: number = null;
  public newMsgInput;
  public newMsgMaxLength = 1000;
  public pendingAttachments: Attachment[];
  public savedScrollPosition: number;

  public isProcessingAttachments: boolean;
  public isAddingAttachments: boolean;

  public convoItemHover: ConversationItem;
  public convoItemOptionsOpen: ConversationItem;

  public onNewConversationMessagesUnreadSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) public document: any,
    public pageScrollService: PageScrollService,
    public modalService: BsModalService,
    public conversationService: ConversationService,
    public generalService: GeneralService,
    public patientService: PatientService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public localeService: LocaleService,
    public attentionCenterService: AttentionCenterService,
  ) {
  }

  ngOnInit(): void {
    const localePreferences: any = this.localeService.getLocalePreferences();
    this.dateFormat = localePreferences.dateFormat;
    this.time_24_hours = localePreferences.locale.time_24_hours;
    this.extensionList = this.generalService.allSupportedTypes.fileInputAccept;

    this.loadingInitialConversations = true;
    this.getConversations(0);

    this.onNewConversationMessagesUnreadSubscription = this.attentionCenterService.onNewConversationMessagesUnread.subscribe(() => {
      this.onNewConversationMessagesUnread();
    });
  }

  ngOnDestroy() {
    this.onNewConversationMessagesUnreadSubscription?.unsubscribe();
  }

  getConversations(page, showIsLoading = true): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = showIsLoading ?? true;


    this.conversationService.getConversations(page).subscribe(
      result => this.onGetConversationsSuccess(page, result));
  }

  onGetConversationsSuccess(page: number, data): void {
    this.isLoading = false;
    this.loadingInitialConversations = false;

    if (page === 0) {
      this.conversations = data.conversations;
    } else {
      this.conversations = this.conversations.concat(data.conversations);
    }

    this.conversationsPagination = data.pagination;

    if (this.currentConversation) {
      const storeCurrentConversation = Object.assign({}, this.currentConversation) as Conversation;
      this.conversations.forEach(_convo => {
        if (this.currentConversation.uid === _convo.uid) {
          _convo.items = storeCurrentConversation.items;
          this.currentConversation = _convo;
        }
      });

      if (this.currentConversation.unread_messages) {
        this.getConversationEntries(0, false);
      }
    }
  }

  onNewConversationMessagesUnread() {
    this.getConversations(0);
  }

  getConversationEntries(page: number, showConversationLoading: boolean = true): void {
    if (showConversationLoading) {
      this.isConversationLoading = true;
    }

    this.loadingPageIndex = page;

    if (page === 0) {
      this.savedScrollPosition = undefined;
    }

    this.conversationService.getConversationEntries(this.currentConversation, page).subscribe(
      result => this.onGetConversationEntriesSuccess(page, result)
    );
  }

  onGetConversationEntriesSuccess(page: number, data): void {
    this.loadingPageIndex = null;
    this.itemsPagination = data.pagination;
    this.isConversationLoading = false;

    this.syncUnreadCount();

    setTimeout(() => {
      if (page === 0) {
        this.scrollToConversationEnd();
      } else {
        this.restoreScrollPosition();
      }
    });
  }

  syncUnreadCount() {
    if (!this.currentConversation.unread_messages) {
      return;
    }

    this.attentionCenterService.refresh();

    this.conversationService.getConversation(this.currentConversation.uid).subscribe(_convo => {
      this.currentConversation.unread_messages = _convo.unread_messages;
    });
  }

  scrollToConversationsListTop() {
    let itemsContainers: HTMLCollection = document.getElementsByClassName('items');
    let itemsContainer: Element = itemsContainers[0];

    if (itemsContainer?.scroll) {
      itemsContainer.scroll(0, 0);
    }
  }

  scrollToConversationEnd() {
    let itemsContainers: HTMLCollection = document.getElementsByClassName('conversation_items');
    let itemsContainer: Element = itemsContainers[0];

    if (itemsContainer?.scroll) {
      itemsContainer.scroll(0, itemsContainer.scrollHeight);
    }
  }

  restoreScrollPosition(): void {
    let itemsContainers: HTMLCollection = document.getElementsByClassName('conversation_items');
    let itemsContainer: Element = itemsContainers[0];

    if (itemsContainer?.scroll) {
      let newLocation = (itemsContainer?.scrollHeight - this.savedScrollPosition);
      itemsContainer.scroll(0, newLocation);
    }
  }

  selectConversation(conversation: Conversation): void {
    if (conversation !== this.currentConversation) {
      this.newMsgInput = null;
      this.pendingAttachments = [];
      conversation.items = [];
      this.currentConversation = conversation;
      this.isConversationLoading = true;
      this.getConversationEntries(0);
    }
  }

  showNewConversationModal(): void {
    const modal = this.modalService.show(StartNewConversationModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-compact'
      })
    );

    modal.content.isNewConversationStarted.subscribe(newConversation => {
      this.conversations.unshift(newConversation);
      this.selectConversation(newConversation);
      this.getConversations(0);
      this.scrollToConversationsListTop();
    }, error => {
      // Catch error
    });
  }

  showEditSubjectModal(event?): void {
    event?.preventDefault();

    const initialState = {
      conversation: this.currentConversation
    };

    const modalRef = this.modalService.show(EditConversationSubjectModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );
  }

  showParticipantsModal(event, participants): void {
    event.preventDefault();
    const initialState = {
      participants
    };

    this.modalService.show(ConversationParticipantsModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-compact',
        initialState
      })
    );
  }

  onEntryMouseEnter(item: ConversationItem) {
    if (this.convoItemHover !== item && this.isSender(item)) {
      this.convoItemHover = item;
    }
  }

  onEntryMouseLeave(item: ConversationItem) {
    if (this.convoItemHover == item) {
      this.convoItemHover = null;
    }
  }

  public mustShowItemOptions(item: ConversationItem): Boolean {
    if (item.type === 'MESSAGE_REMOVED') {
      return false;
    }

    if (this.convoItemHover == item || this.convoItemOptionsOpen == item) {
      return true;
    } else {
      return false;
    }
  }

  isSender(item: ConversationItem): boolean {
    return item.created_by === this.patientService.getCurrentStoredPatientUid();
  }

  differentDate(current: ConversationItem, previous: ConversationItem): boolean {
    return !moment(current.created_at).isSame(previous.created_at, 'day');
  }

  differentPage(current: ConversationItem, previous: ConversationItem): boolean {
    return current.page !== previous.page;
  }

  onScroll(event): void {
    const current_page = this.conversationsPagination.current_page;
    const total_pages = this.conversationsPagination.total_pages;

    if ((current_page < (total_pages - 1))) {
      const newPage = current_page + 1;
      this.getConversations(newPage);
    }
  }

  onScrollItems($event): void {
    const current_page = this.itemsPagination.current_page;
    const total_pages = this.itemsPagination.total_pages;

    if ((current_page < (total_pages - 1)) && this.loadingPageIndex === null) {
      const newPage = current_page + 1;

      this.saveScrollPosition();

      this.getConversationEntries(newPage, false);
    }
  }

  saveScrollPosition() {
    let containers: HTMLCollection = document.getElementsByClassName('conversation_items');
    let container: Element = containers[0];
    this.savedScrollPosition = container.scrollHeight;
  }

  trackByFn(index, item): string {
    return item.created_at;
  }

  public get canAddMessage(): boolean {
    if (this.isProcessingAttachments) {
      return false;
    }

    let messageStr: string = this.messageInput;

    if (messageStr && messageStr.length > 0) {
      return true;
    } else if (this.pendingAttachments && this.pendingAttachments.length > 0 && !this.pendingAttachments.some((attachtment: Attachment) => attachtment.status === Status.TOO_LARGE)) {
      return true;
    } else {
      return false;
    }
  }

  public get messageInput(): string {
    let messageStr: string = this.newMsgInput;

    if (messageStr) {
      messageStr = messageStr.trim();
    }

    return messageStr;
  }

  loadingPage(page): boolean {
    return (page + 1) === this.loadingPageIndex;
  }

  addMessage(): void {
    if (!this.canAddMessage) {
      return;
    }

    if (this.pendingAttachments && this.pendingAttachments.length > 0) {
      this.addAttachments();
      return;
    }

    let messageStr: string = this.messageInput;

    if (messageStr) {
      this.currentConversation.latest_message_type = 'MESSAGE';
      this.currentConversation.draft = false;

      this.conversationService.addConversationEntry(this.currentConversation.uid, messageStr).subscribe(
        () => this.actionsAfterSubmit(),
        () => this.onAddMessageError()
      );
    } else {
      this.newMsgInput = '';
    }
  }

  addAttachments() {
    var toSaveAttachments = this.pendingAttachments.filter(att => att.status === "UNSAVED");

    let messageStr: string = this.messageInput;

    if (!toSaveAttachments?.length) {
      this.onAddMessageError();
      return false;
    }

    this.isAddingAttachments = true;

    let last_message: string = messageStr;
    let latest_message_type: string;

    const queued = from(toSaveAttachments)
      .pipe(mergeMap(att => {
        if (toSaveAttachments.indexOf(att) === (toSaveAttachments.length - 1)) {
          latest_message_type = att.isAudio ? 'AUDIO_MESSAGE' : 'FILE_MESSAGE';
          return this.conversationService.addConversationEntry(this.currentConversation.uid, messageStr, att);
        } else {
          return this.conversationService.addConversationEntry(this.currentConversation.uid, null, att);
        }
      }, null, 1));

    return queued.subscribe(() => {
      this.currentConversation.latest_message = last_message;
      this.currentConversation.latest_message_type = latest_message_type;

      this.actionsAfterSubmit();
    },
      () => { },
      () => {
        this.isAddingAttachments = false;
      });
  }

  actionsAfterSubmit(syncEntries: boolean = true) {
    this.newMsgInput = '';
    this.pendingAttachments = [];

    if (this.newMsgField) {
      this.newMsgField.nativeElement.focus();
    }

    setTimeout(() => {
      this.scrollToConversationEnd();
    });

    if (syncEntries) {
      this.getConversationEntries(0, false);
    }
  }

  deleteMessage(event: MouseEvent, item: ConversationItem) {
    event?.preventDefault();

    this.conversationService.deleteConversationEntry(this.currentConversation.uid, item.id).subscribe(() => {
      item.type = 'MESSAGE_REMOVED';
      this.currentConversation.latest_message_type = item.type;
    });
  }

  onAddMessageError() {
    this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  openAttachment(event: MouseEvent, convoItem: ConversationItem) {
    event?.preventDefault();
    this.conversationService.getDownloadLink(this.currentConversation.uid, convoItem.id).subscribe(link => window.location.href = link);
  }

  handleAttachmentInput(files: Array<any>) {
    if (!this.pendingAttachments) {
      this.pendingAttachments = [];
    }

    Array.from(files).forEach(file => this.fileInputToAttachments(file));

    this.postAttachments();

    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = "";
    }
  }

  fileInputToAttachments(file: any) {
    const attachment = new Attachment(file);
    attachment.meta.created_at = moment().format();
    attachment.meta.file_name = file?.name;
    this.pendingAttachments.push(attachment);
  }

  postAttachments() {
    this.isProcessingAttachments = true;

    const waiting = this.pendingAttachments.filter(a => { return a.status === "WAITING" });
    if (waiting && waiting[0]) {
      this.postAttachment(waiting[0]);
    } else {
      this.isProcessingAttachments = false;
    }
  }

  postAttachment(attachment: Attachment) {
    attachment.meta.size = attachment.file.size;
    attachment.meta.extension = attachment.file.type.replace('image/', '');

    if (attachment.file.size > 20971520) {
      attachment.status = "TOO_LARGE";
      return;
    }

    this.conversationService.postAttachment(this.currentConversation.uid, attachment.file).subscribe(
      result => this.postAttachmentSuccess(result, attachment),
      error => this.postAttachmentError(error, attachment)
    );
  }

  postAttachmentSuccess(result, attachment) {
    attachment.status = "UNSAVED";
    attachment.uid = result['uid'];
    this.postAttachments();
  }

  postAttachmentError(error, attachment) {
    if (error['error'] && error['error']['errors'] && error['error']['errors'][0]['key'] === 'ERROR_FILE_INVALID_EXTENSION') {
      attachment.status = "INCONSISTENT_MIME_TYPE";
    } else {
      attachment.status = "ERROR";
    }
    this.postAttachments();
  }

  setStateToDeleted(event: MouseEvent, attachment: Attachment) {
    event?.preventDefault();
    this.pendingAttachments.splice(this.pendingAttachments.indexOf(attachment), 1);
    this.postAttachments();
  }
}
