import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ExportPersonalDataModalComponent} from "../../../../modals/export-personal-data-modal/export-personal-data-modal.component";
import { DeleteAccountModalComponent } from '../../../../modals/delete-account-modal/delete-account-modal.component';
import {ToastrService} from "ngx-toastr";
import { RestrictProcessingDataModalComponent } from '../../../../modals/restrict-processing-data-modal/restrict-processing-data-modal.component';
import { GeneralService } from '../../../../services/general.service';

@Component({
  selector: 'app-settings-privacy',
  templateUrl: './settings-privacy.component.html',
  styleUrls: ['./settings-privacy.component.scss']
})
export class SettingsPrivacyComponent implements OnInit {

  public deleteAccountModal: BsModalRef;

  constructor(
    public modalService: BsModalService,
    public toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  showExportPersonalDataModal(event) {
    event.preventDefault();

    this.modalService.show(ExportPersonalDataModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
      })
    );
  }

  showRestrictProcessingDataModal() {
    this.deleteAccountModal = this.modalService.show(RestrictProcessingDataModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
      })
    );
  }

  showDeleteAccountModal() {
    this.deleteAccountModal = this.modalService.show(DeleteAccountModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg'
      })
    );
  }
}
