<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_personal_details.edit_info' | translate }}</h2>

  <form [formGroup]="form"
        *ngIf="form"
        id="form"
        [ngClass]="{'validation-visible': validationVisible}">
    <label class="small">{{ 'form.labels.profile_picture' | translate }}</label>

    <div class="control-profile-picture mb-3">
      <app-avatar-input formControlName="profile_picture"></app-avatar-input>
    </div>

    <div class="form-group styled floating-label">
      <input type="text" id="inputPreferredName" class="form-control w-100" [placeholder]="('form.labels.preferred_name' | translate)" formControlName="preferred_name">
      <label for="inputPreferredName">{{ 'form.labels.preferred_name' | translate }}</label>
    </div>

    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.country' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="countryOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="country"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('country')"></app-feedback-field>
    </div>

    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isSaving}" (click)="handleConfirm()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
