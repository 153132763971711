<div class="styled-question style-{{field?.ui_config?.ui_style}} h-100 d-flex flex-column justify-content-between">
  <div class="labels overflow-hidden">
    <h2>
      {{ field?.translationKey | translate }}
    </h2>
  </div>

  @switch (field?.ui_config?.ui_style) {
    @case (uiStyleOption.SLIDER_HORIZONTAL) {
      <div class="range-slider h-100 d-flex flex-column justify-content-center">
        <app-range-slider class="slider-horizontal ui-config" [value]="value" [floor]="field?.ui_config.number_min"
                          [ceil]="field?.ui_config.number_max" [step]="field?.ui_config.number_steps_interval.sub"
                          [thickStep]="field?.ui_config.number_steps_interval.main" [colors]="field?.ui_config.colors"
                          [reverse]="field?.ui_config.number_slider_reverse" [vertical]="false"
                          (valueChange)="onValueChange($event)"/>
      </div>
    }
    @case (uiStyleOption.SLIDER_VERTICAL) {
      <div class="range-slider h-100">
        <app-range-slider class="slider-vertical ui-config h-100 d-block" [ngClass]="{
          'slider-center': field?.ui_config.ui_value_style === uiValueStyleOption.VALUE,
          'has-labels': field?.ui_config.ui_value_style === uiValueStyleOption.VALUE_LABEL
          }" [value]="value" [floor]="field?.ui_config.number_min" [ceil]="field?.ui_config.number_max"
                          [step]="field?.ui_config.number_steps_interval.sub"
                          [thickStep]="field?.ui_config.number_steps_interval.main"
                          [colors]="field?.ui_config.colors" [labels]="field?.ui_config.labels"
                          [reverse]="field?.ui_config.number_slider_reverse" [vertical]="true"
                          (valueChange)="onValueChange($event)"/>
      </div>
    }
    @default {
      <div class="enum-options mx-auto">
        <label class="advice-label small text-muted mb-1" [ngClass]="{'required' : field?.required}">
<!--          TODO: Hide AFSQ-1565-->
<!--          @if (field?.field_value?.multi_select_allowed) {-->
<!--            {{ 'action.select_multiple' | translate }}-->
<!--          } @else {-->
            {{ 'action.select_one' | translate }}
<!--          }-->
        </label>

        <app-styled-enum class="ui-config enum-radio" [field]="field" [value]="value"
                         (valueChange)="onValueChange($event)"/>
      </div>
    }
  }
</div>
