import { TranslationInterface } from '../interfaces/translation.interface';


export class CareModule {
  public uid: string;
  public name: TranslationInterface;
  public description: TranslationInterface;
  public pathway_template_uid: string;
  public value: string;
  public type: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid  = item.uid;
    this.name  = item.name;
    this.description  = item.description;
    this.pathway_template_uid  = item.pathway_template_uid;
    this.value = this.uid;
    this.type = item.type;
  }


  get translationKey():string {
    return `shared.${this.name?.region}.${this.name?.key}`;
  }
}
