import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Patient} from '../../models/patient';
import {LanguageService} from '../../services/language.service';
import {CountryService} from '../../services/country.service';
import {LanguageCode} from '../../models/language-code';
import {PatientService} from '../../services/patient.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { ScopeService } from '../../services/scope.service';
import { Country } from '../../models/country';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-edit-personal-details-modal',
  templateUrl: './edit-personal-details-modal.component.html',
  styleUrls: ['./edit-personal-details-modal.component.scss']
})
export class EditPersonalDetailsModalComponent implements OnInit {
  @ViewChild('inputProfilePicture') inputProfilePicture: ElementRef;
  @Output() patientUpdatedEvent = new EventEmitter<void>();

  patient: Patient;

  isSaving: boolean;
  form: UntypedFormGroup;
  validationVisible: boolean;
  languageOptions: LanguageCode[] = [];
  countryOptions: Country[] = [];
  profilePicturePreview: any;
  profilePictureDeleted = false;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public languageService: LanguageService,
    public countryService: CountryService,
    public patientService: PatientService,
    public scopeService: ScopeService,
    public toastrService: ToastrService,
    public translateService: TranslateService,
    public userService: UserService,
  ) {
  }

  ngOnInit(): void {
    if (!this.patient) {
      this.handleCancel();
    } else {
      this.formSetup();

      this.languageService.getSupportedAppLanguages().subscribe(languageCodes => {
        this.languageOptions = languageCodes;
      });

      this.countryService.getCountriesFromCms().subscribe((countries: Country[])  => {
        this.countryOptions = countries;
      });
    }
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = new UntypedFormGroup({
      preferred_name: new UntypedFormControl(this.patient.preferred_name),
      country: new UntypedFormControl(this.patient.country, Validators.required),
      language: new UntypedFormControl(this.patient.language, [Validators.required]),
      profile_picture: new UntypedFormControl(this.patient.profile_picture)
    });
  }

  handleCancel(): void {
    return this.bsModalRef.hide();
  }

  handleConfirm(): void {
    if (!this.form.dirty) {
      this.bsModalRef.hide();
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.handleSubmission();
    }
  }

  handleSubmission() {
    this.isSaving = true;

    this.patient.preferred_name = this.form.get('preferred_name').value || null;
    this.patient.country = this.form.get('country').value || null;
    this.patient.language = this.form.get('language').value;
    this.patient.profile_picture = this.form.get('profile_picture').value;

    this.patientService.update(this.patient).subscribe(
      (patient) => this.onUpdatePatientSuccess(patient),
      (error) => this.onRequestError(error)
    );
  }

  onUpdatePatientSuccess(patient) {
    this.patient = patient;
    this.patientService.storeCurrentPatientByModel(this.patient);
    this.scopeService.refreshCurrentPatient().subscribe(() => this.onScopeRefreshSuccess());
  }

  onScopeRefreshSuccess() {
    const profile = this.userService.getStoredProfile();

    if(this.form.get('language').dirty) {
      profile.locale.language = this.patient.language;

      this.languageService.setCurrentLanguageByEnum(profile.locale.language);

      this.userService.updateProfile(profile).subscribe(
        () => {
          this.languageService.reloadLanguage().subscribe(() => this.onUpdateProfileSuccess());
        },
        (error) => this.onRequestError(error)
      );
    } else {
      this.onUpdateProfileSuccess();
    }
  }

  onUpdateProfileSuccess() {
    this.isSaving = false;
    this.patientUpdatedEvent.emit();
    this.bsModalRef.hide();
  }

  onRequestError(error) {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach( err => {

        this.form.get(err.field)?.setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }
}
