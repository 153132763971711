<div class="surgery-cards mb-4 mb-lg-5" *ngIf="appointments && appointments.length > 0">
  <div class="surgery-card mb-1" *ngFor="let appointment of appointments; index as i">

    <div class="card bg-primary text-white" *ngIf="i == 0">
      <div class="card-body p-3">
        <div class="row">
          <div class="col-7">
            <h2 class="text-white"><a href="#" (click)="showSurgeryDetails($event, appointment)">{{ 'pages.default.dashboard.surgery.title' | translate }}</a></h2>
            <p class="mt-auto mb-0">{{ 'pages.default.dashboard.surgery.details_description' | translate }}</p>
          </div>
          <div class="col-5 text-right">
            <p>
              <a class="text-icon-link" href="#" (click)="showDateDetails($event)">
                <span class="label" *ngIf="appointment.date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.date | weekday }}</span> {{ appointment.date | timeZoneDate }}
                </span>
                <span class="label" *ngIf="appointment.start_date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.start_date | weekday }}</span> {{ appointment.start_date | timeZoneDate }}
                </span>
                <span class="icon icon-end icon-xs" [inlineSVG]="'/assets/svg/info-circle.svg'"></span>
              </a>
            </p>
            <p class="mt-auto mb-0">
              <a class="text-icon-link hoverable" href="#" (click)="showSurgeryDetails($event, appointment)">
                <span class="label">{{ 'pages.default.dashboard.surgery.details_link' | translate }}</span>
                <span class="icon-end">
                  <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                  <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="i > 0">
      <div class="card-body p-3">
        <div class="row">
          <div class="col-7">
            <h3 class="mb-0"><a href="#" (click)="showSurgeryDetails($event, appointment)">{{ 'pages.default.dashboard.surgery.title' | translate }}</a></h3>
          </div>
          <div class="col-5 text-right">
            <p class="mb-0">
              <a class="text-icon-link" href="#" (click)="showDateDetails($event)">
                <span class="label" *ngIf="appointment.date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.date | weekday }}</span> {{ appointment.date | timeZoneDate }}
                </span>
                <span class="label" *ngIf="appointment.start_date">
                  {{ 'pages.default.dashboard.surgery.date' | translate }} <span class="text-capitalize">{{ appointment.start_date | weekday }}</span> {{ appointment.start_date | timeZoneDate }}
                </span>
                <span class="icon icon-end icon-xs" [inlineSVG]="'/assets/svg/info-circle.svg'"></span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
