<div class="modal-body user-task-standard-modal">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>
    <span *ngIf="userTask?.title">{{ userTask?.translationTitleKey | translate | evalTranslation }}</span>
    <span *ngIf="!userTask?.title">...</span>
  </h2>
  <p *ngIf="userTask?.description">{{ userTask?.translationDescriptionKey | translate | evalTranslation }}</p>

  <div *ngIf="isLoading">
    <app-page-loader [padding]="'20px 0'"></app-page-loader>
  </div>

  <div *ngIf="!isLoading">
    <app-user-task-form [userTask]="userTask" [isReadOnly]="false" [validationVisible]="validationVisible"
      [(form)]="form"></app-user-task-form>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="user-task-standard-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate
      }}</button>

    <button class="btn btn-secondary m-start-3" id="user-task-standard-submit" [ngClass]="{'loader': isSubmitting}"
      (click)="handleSubmit()" [disabled]="!userTask">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></em></span>
      <span>{{ 'action.submit' | translate }}</span>
    </button>
  </div>
</div>
