<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/settings" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none mr-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_settings' | translate }}</span>
          <span class="d-none rtl-d-inline ml-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.settings.preferences.preferences' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content mt-14">
  <div class="container">
    <div id="zone_units">

      <form [formGroup]="form" id="unitForm">
        <div class="mt-3 mb-5">
          <div class="form-group width-limit d-none">
            <app-select
              [placeholder]="('pages.default.settings.preferences.units' | translate)"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="measurementUnits"
              [bindValue]="'value'"

              formControlName="measurement_unit"
              (change)="onHandleUnitFormSubmission()"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              [placeholder]="('pages.default.settings.preferences.number_format' | translate)"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="numberFormats"
              [bindValue]="'value'"

              formControlName="number_format"
              (change)="onHandleUnitFormSubmission()"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              [placeholder]="('pages.default.settings.preferences.date_format' | translate)"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="dateFormats"
              [bindValue]="'value'"

              formControlName="date_format"
              (change)="onHandleUnitFormSubmission()"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              [placeholder]="('pages.default.settings.preferences.time_zone' | translate)"
              [clearable]="false"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="timeZones"
              [bindValue]="'value'"

              formControlName="time_zone"
              (change)="onHandleUnitFormSubmission()"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              [placeholder]="('pages.default.settings.preferences.time_format' | translate)"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="timeFormats"
              [bindValue]="'value'"

              formControlName="time_24_hours"
              (change)="onHandleUnitFormSubmission()"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              [placeholder]="('pages.default.settings.preferences.first_day_of_week' | translate)"
              [clearable]="false"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="weekdays"
              [bindValue]="'value'"

              formControlName="first_day_of_week"
              (change)="onHandleUnitFormSubmission()"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
