import { MobileNumber } from '../interfaces/mobile-number.interface';
import { Image } from './image';

export class Patient {
  public uid: string;
  public hospital_uid: string;
  public first_name: string;
  public last_name: string;
  public preferred_name: string;
  public gender: string;
  public email: string;
  public phone_number: MobileNumber;
  public date_of_birth: string;
  public profile_picture: Image;
  public country: string;
  public contact_channel: string;
  public contact_consented: boolean;
  public language: string;
  public reviewed: boolean;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.hospital_uid = item.hospital_uid;
    this.first_name  = item.first_name;
    this.last_name  = item.last_name;
    this.preferred_name  = item.preferred_name;
    this.gender  = item.gender;
    this.email  = item.email;
    this.date_of_birth  = item.date_of_birth;
    this.country = item.country;
    this.contact_channel = item.contact_channel;
    this.contact_consented = item.contact_consented;
    this.language = item.language;
    this.reviewed = item.reviewed;
    this.phone_number  = item.phone_number;

    if(item.profile_picture) {
      this.profile_picture = new Image(item.profile_picture);
    }
  }

  fromStorage(patientData: any) {
    this.fillFromJson(patientData);
  }

  getBirthDate() {
    if (this.date_of_birth) {
      return new Date(this.date_of_birth);
    } else {
      return undefined;
    }
  }

  public getFullName() {
    return [this.first_name, this.last_name].join(' ');
  }
}
