<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/dashboard" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container mb-auto">
      <h1>{{ 'pages.default.timeline.your_timeline' | translate }}</h1>
      <app-page-tabs [items]="pageTabItems" *ngIf="!isLoading"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="timeline-vertical">
      <div *ngFor="let phase of phases; last as last; first as first; index as index">

        <!-- TODAY MARKER -->
        <div class="timeline-item-wrapper heading" [ngClass]="{ 'pt-4': !first }"
          *ngIf="printToday(index) && scopeService.doesCurrentPatientHasClicinalCareModule()">
          <div class="tl-day">
            <div class="tl-bullet"></div>
            <p class="text-secondary m-0"><strong>{{ 'pages.default.timeline.today' | translate }}, {{ today |
                date:dateFormat.format | translateNumber }}</strong></p>
          </div>
        </div>
        <!-- /TODAY MARKER -->

        <div *ngIf="printUpcoming(index) && scopeService.doesCurrentPatientHasClicinalCareModule()">

          <!-- UPCOMING APPOINTMENTS -->
          <div *ngIf="futureAppointments?.length>0 && phase.inTheFuture">
            <div class="timeline-item-wrapper muted">
              <div class="tl-title">
                <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/history.svg'"></span>
                <p><strong>{{ 'pages.default.timeline.upcoming_appointments' | translate }}</strong></p>
              </div>
            </div>

            <div id="future-appointment-{{appointmentIndex}}" class="timeline-item-wrapper muted"
              *ngFor="let appointment of futureAppointments; let appointmentIndex = index" [ngClass]="{'pb-0': last}">
              <div class="single-item-block item-clickable" (click)="selectAppointment($event, appointment);">
                <div class="block-wrapper p-start-3 p-end-1">
                  <span class="icon" [inlineSVG]="'/assets/svg/calendar.svg'"></span>
                </div>
                <div class="block-wrapper">
                  <p class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format | translateNumber }}</p>

                  <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                  <p class="label" *ngIf="!appointment?.title">{{ appointment?.title_key | translateHelp | translate }}
                  </p>

                  <p *ngIf="appointment?.description">{{ appointment?.description }}</p>
                  <p *ngIf="!appointment?.description">{{ appointment?.description_key | translateHelp | translate }}
                  </p>
                </div>
                <div class="block-action m-start-auto">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- /UPCOMING APPOINTMENTS -->

          <!-- UPCOMING MARKER -->
          <div class="timeline-item-wrapper muted" *ngIf="!phase.isDummy && phase.inTheFuture">
            <div class="tl-title">
              <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/history.svg'"></span>
              <p><strong>{{ 'pages.default.timeline.upcoming' | translate }}</strong></p>
            </div>
          </div>
          <!-- /UPCOMING MARKER -->
        </div>

        <!-- PHASE  -->
        <div class="timeline-item-wrapper heading" *ngIf="!phase.isSubPhase && !phase.isDummy"
          [ngClass]="{muted: phase.inTheFuture, 'pb-0': (last && phase.inTheFuture) , 'pt-4': (!first && !phase.inTheFuture)}"
          id="{{phase?.id}}">

          <!-- force pt-4 on the item that markes the treatment as stopped, which is the very very last item -->
          <div class="tl-phase-card">
            <div class="tl-bullet"></div>
            <div class="card">
              <div class="card-body">
                <h2>{{ phase?.translationKey | translate | evalTranslation }}</h2>
                <div *ngIf="phase?.translated_description" [innerHTML]="phase?.translated_description"></div>
              </div>
            </div>

            <div class="picture" *ngIf="phase?.picture && phase.media_selector !== 'video'"
              [ngStyle]="{'background-image': 'url(' + phase?.picture + '?width=1832&disable=upscale&format=pjpg&auto=webp' + ')'}">
            </div>

            <!-- Video -->
            <div class="content-video" *ngIf="phase?.videos && phase.media_selector === 'video'">
              <app-video-carousel [videos]="phase.videos" [controlsPosition]="'bottom'"></app-video-carousel>
            </div>
            <!-- /Video -->
          </div>
        </div>
        <!-- /PHASE  -->

        <!-- SUBPHASE subphases are never shown in future -->
        <div class="timeline-item-wrapper" *ngIf="phase.isSubPhase && !phase.inTheFuture && phase.phase_visible"
          [ngClass]="{muted: phase.inTheFuture, 'pb-0': phase.isDummy}">
          <div class="tl-sub-phase-card">
            <div class="tl-bullet"></div>
            <div class="card">
              <div class="card-body">
                <p class="small">{{ phase?.started_at | timeZoneDate:dateFormat.format | translateNumber }}</p>
                <h3>{{( phase?.translationKey ) | translate | evalTranslation }}</h3>
                <div *ngIf="phase?.translated_description" [innerHTML]="phase?.translated_description"></div>
              </div>
            </div>

            <div class="picture" *ngIf="phase?.picture && phase.media_selector !== 'video'"
              [ngStyle]="{'background-image': 'url(' + phase?.picture + '?width=846&disable=upscale&format=pjpg&auto=webp' + ')'}">
            </div>

            <!-- Video -->
            <div class="content-video" *ngIf="phase?.videos && phase.media_selector === 'video'">
              <app-video-carousel [videos]="phase.videos" [controlsPosition]="'bottom'"></app-video-carousel>
            </div>
            <!-- Video -->
          </div>
        </div>
        <!-- /SUBPHASE -->

        <div class="e2e-past-appointments"
          *ngIf="!phase.inTheFuture && scopeService.doesCurrentPatientHasClicinalCareModule()">

          <!-- PASSED APPOINTMENTS -->
          <div class="timeline-item-wrapper"
            *ngFor="let appointment of phase?.appointments; let appointmentIndex = index">
            <div id="passed-appointment-{{appointmentIndex}}" class="single-item-block item-clickable"
              (click)="selectAppointment($event, appointment);">
              <div class="block-wrapper p-start-3 p-end-1">
                <span class="icon" [inlineSVG]="'/assets/svg/calendar.svg'"></span>
              </div>
              <div class="block-wrapper">
                <p class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format | translateNumber }}</p>

                <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                <p class="label" *ngIf="!appointment?.title">{{ appointment?.title_key | translateHelp | translate }}
                </p>

                <p *ngIf="appointment?.description">{{ appointment?.description }}</p>
                <p *ngIf="!appointment?.description">{{ appointment?.description_key | translateHelp |translate }}</p>
              </div>
              <div class="block-action m-start-auto">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </div>
            </div>
          </div>
          <!-- PASSED APPOINTMENTS -->
        </div>

        <!-- Learning Materials -->
        <div class="row m-0 no-gutters">
          <div class="timeline-item-wrapper col-4" *ngIf="phase?.learning_materials?.length">
            <div id="learning-material-{{phase.id}}" class="single-item-block item-clickable"
              (click)="goToMaterialPhase($event, phase)">
              <div class="block-wrapper p-start-3 p-end-1">
                <span class="icon" [inlineSVG]="'/assets/svg-color/document.svg'"></span>
              </div>
              <div class="block-wrapper font-weight-bold"><span>{{phase?.learning_materials?.length}} {{
                  'pages.default.timeline.learning_materials' | translate }}</span></div>
              <div class="block-action m-start-auto">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Learning Materials -->
      </div>
    </div>

    <!-- Spacer - do not remove -->
    <div class="pb-4"></div>
    <!-- Spacer - do not remove -->

  </div>
</div>
