<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <div class="mb-2">
    <h2 class="mb-1">{{ appointment?.title }}</h2>
    
    <p class="m-0" *ngIf="appointment?.start_date && !appointment?.date">
      <span class="text-capitalize">{{ appointment?.start_date | timeZoneDate:'dddd' }}</span>
      <span class="rtl-d-none">,</span>
      {{ appointment?.start_date | timeZoneDate }}
      {{ 'modals.appointment.from' | translate }}
      {{ appointment?.start_date | time }} - {{ appointment?.end_date | time }}
    </p>

    <p class="m-0" *ngIf="appointment?.date && !appointment?.start_date">
      <span class="text-capitalize">{{ appointment?.date | timeZoneDate:'dddd' }}</span>
      <span class="rtl-d-none">,</span>
      {{ appointment?.date | timeZoneDate }}
      <span class="text-info font-weight-bold m-start-1">
        {{ 'pages.default.calendar.full_day' | translate }}
      </span>
    </p>
  </div>

  <div class="mb-2" *ngIf="appointment?.location">
    <p class="small text-muted mb-0">{{ 'modals.appointment.location' | translate }}</p>
    <p class="m-0" *ngIf="!appointment.location?.url">{{ appointment.location?.name }}</p>
    <p class="m-0" *ngIf="appointment.location?.url">
      <a [href]="appointment.location.url" class="text-info text-icon-link" target="_blank">
        <span class="label">{{ appointment.location?.name }}</span>
        <span class="icon-end" [inlineSVG]="'/assets/svg/external.svg'"></span>
      </a>
    </p>
  </div>

  <div class="mb-3" *ngIf="appointment?.getInvitees(false)?.length">
    <p class="small text-muted mb-1">{{ 'modals.appointment.hcps' | translate }}</p>
    <div class="row">
      <ng-container *ngFor="let participant of appointment?.getInvitees(false)">
        <div class="col-6">
          <div class="participant-item h-100">
            <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>

            <div class="meta">
              <p class="title">{{ participant.first_name }} {{ participant.last_name }}</p>
              <p class="description">{{ participant?.job_title }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="mb-0" *ngIf="appointment?.description">
    <p class="small text-muted mb-0">{{ 'modals.appointment.desc' | translate }}</p>
    <p>{{ appointment?.description }}</p>
  </div>

  <div class="row">
    <div class="col-auto">
      <p class="small text-muted mb-0">{{ 'modals.appointment.organised_by' | translate }}</p>
      <p>{{ appointment?.organiser.first_name }} {{ appointment?.organiser.last_name }}</p>
    </div>
    <div class="col-auto p-start-5" *ngIf="appointment?.cancelled && appointment?.changed_by">
      <p class="small text-muted mb-0">{{ 'modals.appointment.cancelled_by' | translate }}</p>
      <p>{{ appointment?.changed_by?.first_name }} {{ appointment?.changed_by?.last_name }}</p>
    </div>

    <div class="col-auto p-start-5" *ngIf="!appointment?.cancelled && appointment?.changed_by">
      <p class="small text-muted mb-0">{{ 'modals.appointment.modified_by' | translate }}</p>
      <p>{{ appointment?.changed_by?.first_name }} {{ appointment?.changed_by?.last_name }}</p>
    </div>
  </div>
</div>

<div class="modal-footer footer-muted py-2" *ngIf="appointment?.cancelled || appointment?.status_of_user === 'CANCELLED'">
  <!--
  <p *ngIf="!appointment?.cancelled && appointment?.status_of_user === 'PENDING'" class="m-end-auto font-weight-medium">{{ 'modals.appointment.are_you_going' | translate }}</p>
  <p *ngIf="!appointment?.cancelled && appointment?.status_of_user === 'ACCEPTED'" class="m-end-auto font-weight-medium">{{ 'modals.appointment.you_accepted' | translate }}</p>
  -->
  <p *ngIf="appointment?.cancelled || appointment?.status_of_user === 'CANCELLED'" class="m-end-auto font-weight-medium text-info">{{ 'modals.appointment.appointment_cancelled' | translate }}</p>

  <!--
  <a *ngIf="!appointment?.cancelled && appointment?.status_of_user === 'PENDING'" (click)="handleYes()" class="text-icon-link text-primary m-end-3">
    <span *ngIf="showLoaderFor!=='ACCEPTED'" class="icon-start" [inlineSVG]="'/assets/svg/check.svg'"></span>
    <em *ngIf="showLoaderFor=='ACCEPTED'" class="icon-start spinner-border spinner-border-sm"></em>
    <span class="label">{{ 'action.yes' | translate }}</span>
  </a>

  <a *ngIf="!appointment?.cancelled && appointment?.status_of_user === 'PENDING'" (click)="handleNo()" class="text-icon-link text-secondary">
    <span *ngIf="showLoaderFor!=='DECLINED'" class="icon-start" [inlineSVG]="'/assets/svg/deny.svg'"></span>
    <em *ngIf="showLoaderFor=='DECLINED'" class="icon-start spinner-border spinner-border-sm"></em>
    <span class="label">{{ 'action.no' | translate }}</span>
  </a>

  <a *ngIf="!appointment?.cancelled && appointment?.status_of_user === 'ACCEPTED'" (click)="handleDecline()" class="text-icon-link text-secondary">
    <span *ngIf="showLoaderFor!=='DECLINED'" class="icon-start" [inlineSVG]="'/assets/svg/close.svg'"></span>
    <em *ngIf="showLoaderFor=='DECLINED'" class="icon-start spinner-border spinner-border-sm"></em>
    <span class="label">{{ 'modals.appointment.decline_appointment' | translate }}</span>
  </a>

  <a *ngIf="appointment?.cancelled" (click)="handleRemove()" class="text-icon-link text-secondary">
    <span *ngIf="showLoaderFor!=='REMOVED'" class="icon-start" [inlineSVG]="'/assets/svg/trash.svg'"></span>
    <em *ngIf="showLoaderFor=='REMOVED'" class="icon-start spinner-border spinner-border-sm"></em>
    <span class="label">{{ 'modals.appointment.remove_from_calendar' | translate }}</span>
  </a>
  -->
</div>
