<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/dashboard" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.hospital.hospital' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="hospital-block">
      <div class="hospital-content">

        <div class="row justify-content-between">
          <div class="col col-auto">
            <div *ngIf="hospital?.logo?.url" class="logo" [ngStyle]="{'background-image': 'url(' + hospital.logo.url + '?width=360&disable=upscale&format=pjpg&auto=webp' + ')'}"></div>

            <p class="name" id="hospital_name">{{hospital?.name}}</p>

            <div class="details">
              <div class="item">
                <div class="icon" [inlineSVG]="'/assets/svg/location.svg'"></div>
                <div class="value">
                  <a href="{{ hospital?.google_maps_uri}}" target="_blank" id="link_hospital_location" rel="noopener" [ngSwitch]="hospital?.address.country">
                    <span *ngSwitchCase="'FR'">
                      <span id="hospital_location_address_number">{{hospital?.address.number }}{{hospital?.address.bus }}</span>&nbsp;<span id="hospital_location_address_street">{{hospital?.address.street }}</span>,&nbsp;<span id="hospital_location_address_postal">{{hospital?.address.postal_code }}</span>&nbsp;<span id="hospital_location_address_city">{{hospital?.address.city }}</span>
                    </span>
                    <span *ngSwitchCase="'GB'">
                      <span id="hospital_location_address_number">{{hospital?.address.number }}{{hospital?.address.bus }}</span>&nbsp;<span id="hospital_location_address_street">{{hospital?.address.street }}</span>,&nbsp;<span id="hospital_location_address_city">{{hospital?.address.city }}</span>&nbsp;<span id="hospital_location_address_postal">{{hospital?.address.postal_code }}</span>
                    </span>
                    <span *ngSwitchDefault>
                      <span id="hospital_location_address_street">{{hospital?.address.street }}</span>&nbsp;<span id="hospital_location_address_number">{{hospital?.address.number }}{{hospital?.address.bus }}</span>,&nbsp;<span id="hospital_location_address_postal">{{hospital?.address.postal_code }}</span>&nbsp;<span id="hospital_location_address_city">{{hospital?.address.city }}</span>
                    </span>
                  </a>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.phone_number">
                <div class="icon" [inlineSVG]="'/assets/svg/grid.svg'"></div>
                <div class="value">
                  <p class="mb-0"><span id="hospital_phone_code">{{ getDialCode(hospital?.phone_number.code)?.label}}</span> <span id="hospital_phone_number">{{ hospital?.phone_number.number }}</span></p>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.email">
                <div class="icon" [inlineSVG]="'/assets/svg/mail.svg'"></div>
                <div class="value">
                  <a href="mailto:{{ hospital?.email }}" target="_blank" id="link_hospital_email" rel="noopener">{{ hospital?.email }}</a>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.site">
                <div class="icon" [inlineSVG]="'/assets/svg/world.svg'"></div>
                <div class="value">
                  <a href="{{ hospital?.site.url }}" target="_blank" id="link_hospital_email" rel="noopener">{{ hospital?.site.name }}</a>
                </div>
              </div>
              <div class="item" *ngIf="hospital?.open_24_7 || hospital?.opening_hours">
                <div class="icon mb-auto" [inlineSVG]="'/assets/svg/calendar.svg'"></div>
                  <div class="value" *ngIf="hospital?.open_24_7">
                    <p class="mb-0">{{ 'pages.default.hospital.open_24_7' | translate }}</p>
                  </div>

                <div class="value" *ngIf="!hospital?.open_24_7">
                  <a href="" target="_blank" class="link-list-action" (click)="toggleOpeningHours($event)" rel="noopener">
                    <span class="align-middle">{{ 'pages.default.hospital.opening_hours' | translate }}</span>
                    <span class="align-middle" [inlineSVG]="'/assets/svg/chevron-down.svg'" *ngIf="!openingHoursVisible"></span>
                    <span class="align-middle" [inlineSVG]="'/assets/svg/chevron-up.svg'" *ngIf="openingHoursVisible"></span>
                  </a>
                  <div class="link-list-table" *ngIf="openingHoursVisible">
                    <table cellpadding="0" cellspacing="0">
                      <tr *ngFor="let item of hospital?.opening_hours; index as index">
                        <td>{{ item.day }}</td>
                        <td *ngIf="isClosed(index)">{{ 'pages.default.hospital.closed' | translate }}</td>
                        <td *ngIf="isOpen24Hours(index)">{{ 'pages.default.hospital.open_24' | translate }}</td>
                        <td *ngIf="isCustom(index) && !isLocaleHebrew()">{{ item.hours.open.label }} - {{ item.hours.closed.label}}</td>
                        <td *ngIf="isCustom(index) && isLocaleHebrew()"> {{ item.hours.closed.label}} - {{ item.hours.open.label }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col col-pictures count-pictures-{{hospital?.images?.length}}">
            <div class="row justify-content-end pictures" id="hospital_images">
              <ng-container *ngFor="let image of hospital?.images">
                <div class="col" [ngClass]="(hospital?.images?.length >2) ? 'col-6' : 'col-12'">
                  <div class="item" [ngStyle]="{ 'background-image': 'url(' + image?.url + '?width=768&disable=upscale&format=pjpg&auto=webp' + ')' }"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div [innerHtml]="hospital?.description" class="description mt-5">

        </div>

      </div>



      <!-- <div class="hospital-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.150205217707!2d4.834319009217615!3d51.17945441709056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c15536663cb1f7%3A0x77bd1e5b0e7b402b!2sDigiti!5e0!3m2!1sen!2sbe!4v1607514664115!5m2!1sen!2sbe"></iframe>
      </div> -->
    </div>
  </div>
</div>
