import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { MfaMethod } from '../models/mfa-method';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    public dataService: DataService,
  ) {
    this.authenticationSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('authentication')));
  }

  public get authentication() {
    return this.authenticationSubject.value;
  }
  public static RoleMfaRequired = 'ROLE_MFA_REQUIRED';
  public static RoleConsentRequired = 'ROLE_CONSENT_REQUIRED';
  public static RolePwChangeRequired = 'ROLE_PW_CHANGE_REQUIRED';
  public static RolePatient = 'ROLE_PATIENT';
  public accessTokenValid = true;


  private readonly authenticationSubject: BehaviorSubject<any>;

  @Output() onLogOut: EventEmitter<any> = new EventEmitter();

  public static isFinalRole(role: string) {
    return ([
      AuthenticationService.RolePatient
    ]).includes(role);
  }

  getPayloadFromToken(token: string): any {
    const parts = token.split('.');
    return JSON.parse(atob(parts[1]));
  }

  setAuthenticationData(data: any): boolean {
    if (data?.hasOwnProperty('access_token')) {
      const authDataString = JSON.stringify(data);
      sessionStorage.setItem('authentication', authDataString);
      this.authenticationSubject.next(authDataString);
      return true;
    } else {
      return false;
    }
  }

  getAuthenticationData(): string {
    return sessionStorage.getItem('authentication');
  }

  getAccessToken(): string {
    const authenticationData = JSON.parse(this.getAuthenticationData());
    return !authenticationData ? '' : authenticationData.access_token;
  }

  getRefreshToken(): string {
    const authenticationData = JSON.parse(this.getAuthenticationData());
    return !authenticationData ? '' : authenticationData.refresh_token;
  }

  getCorrelationId(): string {
    const authentication_data = JSON.parse(this.getAuthenticationData());

    if (!authentication_data?.access_token) {
      return '';
    }
    const data = this.getPayloadFromToken(authentication_data.access_token);
    return data.correlation_id;
  }

  getCurrentRoles(): string[] {
    const authentication_data = JSON.parse(this.getAuthenticationData());
    if (!authentication_data) {
      return [];
    }
    const data = this.getPayloadFromToken(authentication_data.access_token);
    return data.authorities;
  }

  getCurrentFirstRole(): string {
    const roles = this.getCurrentRoles();

    if (roles && roles.length) {
      return roles[0];
    } else {
      return '';
    }
  }

  hasFinalRole() {
    const roles = this.getCurrentRoles();
    let hasFinalRole = false;

    roles.forEach(role => {
      if (AuthenticationService.isFinalRole(role)) {
        hasFinalRole = true;
      }
    });

    return hasFinalRole;
  }

  destroyAuth() {
    sessionStorage.removeItem('authentication');
    sessionStorage.removeItem('C4T_shown_banner');

    this.authenticationSubject.next(null);
  }

  logout() {
    // destroy the auth
    this.destroyAuth();

    // clear the dataservice
    this.dataService.clearAll();

    // emit the logOut event
    this.onLogOut.emit();
  }

  validateTimestamp(timestamp: number): boolean {
    const expires_at = timestamp * 1000;
    return (expires_at > Date.now()) ? true : false;
  }

  refreshTokenIsValid(): boolean {
    const refresh_token = this.getRefreshToken();

    if (refresh_token) {
      const payload = this.getPayloadFromToken(refresh_token);
      return this.validateTimestamp(payload.exp);
    }

    return false;
  }

  tokenIsValid(token: string): boolean {
    const payload = this.getPayloadFromToken(token);
    return this.validateTimestamp(payload.exp);
  }

  getMfaMethods(primary?: boolean): Array<MfaMethod> {
    const authentication_data = JSON.parse(this.getAuthenticationData());
    const mfas: any[] = authentication_data?.mfas || [];
    const mfaMethods: MfaMethod[] = mfas.map(mfa => {
      return new MfaMethod(mfa);
    });

    if(primary === true) {
      return mfaMethods.filter((_mfaMethod) => _mfaMethod.primary === true);
    } else if(primary === false) {
      return mfaMethods.filter((_mfaMethod) => _mfaMethod.primary === false);
    } else {
      return mfaMethods;
    }
  }
}
