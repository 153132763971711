import { GoalInstanceBase } from "../interfaces/goal-instance-base.interface";
import { TranslationInterface } from "../interfaces/translation.interface";
import { GoalEntry, GoalEntryInputType } from "./goal-entry";
import { GoalEvaluation } from "./goal-evaluation";

export enum GoalGraphType {
  LINE = 'LINE',
  BAR = 'BAR'
}

export class GoalInstance implements GoalInstanceBase {
  public id: string;
  public title: TranslationInterface;
  public description: TranslationInterface;
  public started_at: string;
  public ended_at: string;
  public input_initial: number;
  public target_initial: number;
  public input_latest: number;
  public target_latest: number;
  public input_unit: TranslationInterface;
  public input_graph: GoalGraphType;
  public target_graph: GoalGraphType;
  public evaluation: GoalEvaluation;
  public entries: GoalEntry[];
  
  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id || item.uid;
    this.title = item.title;
    this.description = item.description;
    this.started_at = item.started_at;
    this.ended_at = item.ended_at;
    this.input_initial = item.input_initial;
    this.target_initial = item.target_initial;
    this.input_latest = item.input_latest;
    this.target_latest = item.target_latest;
    this.input_unit = item.input_unit;
    this.input_graph = item.input_graph;
    this.target_graph = item.target_graph;
    
    if(item.evaluation) {
      this.evaluation = new GoalEvaluation(item.evaluation);
    }

    this.entries = [];
    if(item.entries) {
      item.entries.forEach(entry => {
        this.entries.push(new GoalEntry(entry));
      });
    }
  }

  public hasAverageInputs(): boolean {
    if(!this.entries?.length) {
      return false;
    }

    let response = false;

    this.entries.forEach(entry => {
      if(entry.input_average) {
        response = true;

        return
      }
    });

    return response;
  }

  public getEntriesByInputType(type: GoalEntryInputType) {
    if(!this.entries || !type) {
      return [];
    }

    return this.entries.filter(entry => {
      return !(!entry.getInputByType(type));
    });
  }

  public hasValueFor(property: string):boolean {
    return ![null, undefined].includes(this[property]);
  }

  get translationTitleKey():string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  get translationDescriptionKey():string {
    return `shared.${this.description?.region}.${this.description?.key}`;
  }
  
  get translationInputUnitKey():string {
    return `shared.${this.input_unit?.region}.${this.input_unit?.key}`;
  }
}