export enum GoalEntryInputType {
  PATIENT = 'PATIENT',
  HCP = 'HCP',
  AVERAGE = 'AVERAGE',
  TARGET = 'TARGET'
}

interface GoalEntryInput {
  value: number,
  type: GoalEntryInputType
}

export class GoalEntry {
  public date: string;
  public inputs: GoalEntryInput[];

  public input_hcp: GoalEntryInput;
  public input_patient: GoalEntryInput;
  public input_average: GoalEntryInput;
  public input_target: GoalEntryInput;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.date = item.date;
    this.inputs = [];
    this.input_patient = undefined;
    this.input_hcp = undefined;
    this.input_average = undefined;
    this.input_target = undefined;

    if(item.inputs) {
      item.inputs.forEach(input => {
        this.inputs.push(input);

        switch(input.type) {
          case GoalEntryInputType.PATIENT: this.input_patient = input; break;
          case GoalEntryInputType.HCP: this.input_hcp = input; break;
          case GoalEntryInputType.AVERAGE: this.input_average = input; break;
          case GoalEntryInputType.TARGET: this.input_target = input; break;
        }
      });
    }
  }

  getInputByType(type: GoalEntryInputType) {
    switch(type) {
      case GoalEntryInputType.PATIENT: return this.input_patient;
      case GoalEntryInputType.HCP: return this.input_hcp;
      case GoalEntryInputType.AVERAGE: return this.input_average;
      case GoalEntryInputType.TARGET: return this.input_target;
    }
  }
}