import { StatusEnum } from "../enums/status";
import { LocationInterface } from "../interfaces/location.interface";
import { HcpFunction } from "./hcp-function";
import { Image } from "./image";

export class HealthCareProfessional {
  public uid: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public language: string;
  public location: LocationInterface;
  public status: StatusEnum;
  public function: HcpFunction;
  public job_title: string;
  public is_care_coordinator: boolean;
  public profile_picture: Image;

  public get classType():string {
    return 'HealthCareProfessionalBasic';
  }

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid || '';
    this.first_name = item.first_name || '';
    this.last_name = item.last_name || '';
    this.email = item.email || '';
    this.language = item.language || 'EN';
    this.location = item.location;
    this.status = item.status;
    this.is_care_coordinator = item.is_care_coordinator;
    this.job_title = item.job_title

    if(item.profile_picture) {
      this.profile_picture = new Image(item.profile_picture);
    }

    if(item.function) {
      this.function = new HcpFunction(item.function);
    }

  }

  public getFullName() {
    return [this.first_name, this.last_name].join(' ');
  }
}
