import * as i0 from '@angular/core';
import { InjectionToken, Injectable, inject, NgModule } from '@angular/core';
import * as i1 from '@angular/router';
import { NavigationEnd } from '@angular/router';
const _window = () => window;
const SCROLL_TOKEN = new InjectionToken('preserveScroll');
class WindowRef {
  get nativeWindow() {
    return _window();
  }
  static ɵfac = function WindowRef_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WindowRef)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WindowRef,
    factory: WindowRef.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WindowRef, [{
    type: Injectable
  }], null, null);
})();
class BackButtonDisableModule {
  router;
  windowRef;
  preserveScroll = inject(SCROLL_TOKEN) || false;
  window;
  scrollX = 0;
  scrollY = 0;
  constructor(router, windowRef) {
    this.router = router;
    this.windowRef = windowRef;
    this.window = this.windowRef.nativeWindow;
    this.disableBackButton();
    this.addPopStateEventListener();
  }
  static forRoot(config) {
    return {
      ngModule: BackButtonDisableModule,
      providers: [{
        provide: SCROLL_TOKEN,
        useValue: config?.preserveScroll || false
      }]
    };
  }
  addPopStateEventListener() {
    this.window.addEventListener('popstate', () => {
      if (this.preserveScroll) this.getScrollCoordinates();
      this.window.history.pushState(null, null, null);
      if (this.preserveScroll) setTimeout(this.scrollToThePreviousPosition.bind(this));
    });
  }
  scrollToThePreviousPosition() {
    this.window.scrollTo(this.scrollX, this.scrollY);
  }
  getScrollCoordinates() {
    this.scrollX = this.window.scrollX;
    this.scrollY = this.window.scrollY;
  }
  disableBackButton() {
    this.window.history.pushState(null, null, null);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.window.history.pushState(null, null, null);
      }
      ;
    });
  }
  static ɵfac = function BackButtonDisableModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BackButtonDisableModule)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(WindowRef));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: BackButtonDisableModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [WindowRef]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BackButtonDisableModule, [{
    type: NgModule,
    args: [{
      providers: [WindowRef]
    }]
  }], function () {
    return [{
      type: i1.Router
    }, {
      type: WindowRef
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BackButtonDisableModule, WindowRef };
