<div class="calendar-appointment-compact">
  <div class="modal-content">
    <div class="modal-body">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">{{ appointment?.title }}</h3>
        <button type="button" class="close" id="appointment-compact-close" data-dismiss="modal" aria-label="Close"
          (click)="handleClose()">
          <span [inlineSVG]="'/assets/svg/close.svg'"></span>
        </button>
      </div>

      <p *ngIf="appointment.start_date && appointment.end_date">
        <span class="text-capitalize">{{ appointment.start_date | weekday }}</span>,
        {{ appointment.start_date | timeZoneDate }}
        {{ 'modals.appointment.from' | translate }}
        {{ appointment.start_date | time }} - {{ appointment.end_date | time }}
      </p>

      <p *ngIf="appointment.date">
        <span class="text-capitalize">{{ appointment.date | weekday }}</span>,
        {{ appointment.date | timeZoneDate }}
        <span class="text-info font-weight-bold m-start-1">
          {{ 'pages.default.calendar.full_day' | translate }}
        </span>
      </p>

      <div class="mb-2" *ngIf="appointment?.location?.name">
        <p class="small text-muted mb-0">{{ 'modals.appointment.location' | translate }}</p>
        <p class="m-0" *ngIf="!appointment.location?.url">{{ appointment.location?.name }}</p>
        <p class="m-0" *ngIf="appointment.location?.url">
          <a [href]="appointment.location.url" class="text-info text-icon-link" target="_blank">
            <span class="label">{{ appointment.location?.name }}</span>
            <span class="icon-end" [inlineSVG]="'/assets/svg/external.svg'"></span>
          </a>
        </p>

      </div>

      <div class="mb-2" *ngIf="getAllInviteeNames()">
        <p class="small text-muted mb-0">{{ 'modals.appointment.hcps' | translate }}</p>
        <p class="m-0">{{ getAllInviteeNames() }}</p>
      </div>

      <div class="mt-n2"></div>
    </div>

    <div class="modal-footer">
      <p class="m-end-auto font-weight-medium text-info"
        *ngIf="appointment.status_of_user == 'CANCELLED' || appointment.cancelled">{{
        'modals.appointment.appointment_cancelled' | translate }}</p>
      
      <!--
      <p class="m-end-auto font-weight-medium text-info" *ngIf="appointment.status_of_user == 'ACCEPTED' && !appointment.cancelled">{{ 'modals.appointment.going' | translate }}</p>
      <p class="m-end-auto font-weight-medium" *ngIf="appointment.status_of_user == 'PENDING' && !appointment.cancelled">{{ 'modals.appointment.are_you_going' | translate }}</p>
      -->

      <p class="font-weight-medium">
        <a href="" class="text-secondary" (click)="viewDetailsHandler($event)">
          <span class="label">{{ 'modals.appointment.view_details' | translate }}</span>
        </a>
      </p>
    </div>
  </div>
</div>
