export class MfaMethod {
  public channel: String;
  public primary: Boolean;

  public value: String; // optional

  constructor(item?: any) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.channel = item?.channel;
    this.primary = item?.primary;
    this.value = item?.value;
  }

  get canBeRemoved(): boolean {
    return this.channel !== 'EMAIL';
  }
}
