<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/dashboard" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.medical_team.medical_team' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="row mb-5">
      <div class="col-4" *ngIf="clinicalLead">
        <div class="team-member-item bg-white h-100">
          <app-avatar [profilePicture]="clinicalLead?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ clinicalLead.first_name }} {{ clinicalLead.last_name }}</p>
            <p class="description">{{ clinicalLead.job_title }}</p>
            <p class="tag">{{ 'pages.default.medical_team.clinical_lead' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-4" *ngIf="caseManager">
        <div class="team-member-item bg-white h-100">
          <app-avatar [profilePicture]="caseManager?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ caseManager.first_name }} {{ caseManager.last_name }}</p>
            <p class="description">{{ caseManager.job_title }}</p>
            <p class="tag">{{ 'pages.default.medical_team.case_manager' | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 mb-2" *ngFor="let hcp of hcps">

        <div class="team-member-item bg-white h-100">
          <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

          <div class="meta">
            <p class="title">{{ hcp?.getFullName() }}</p>
            <p class="description">{{ hcp?.job_title }}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
