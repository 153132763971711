import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Faq } from '../../models/faq';
import { FaqCategory } from '../../models/faq-category';
import { HelpcenterSection } from '../../models/helpcenter-section';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-help-center-faq-list',
  templateUrl: './help-center-faq-list.component.html'
})
export class HelpCenterFaqListComponent {
  @Input() helpcenterSection: HelpcenterSection;
  @Input() compact: boolean = false;

  @Output() goToOverview = new EventEmitter<HelpcenterSection>();
  @Output() goToFaq = new EventEmitter<{ faq: Faq, category: FaqCategory, section: HelpcenterSection }>();

  constructor(
    public dataService: DataService,
    public router: Router
  ) { }

  goToSectionOverview(section: HelpcenterSection) {
    this.goToOverview.emit(section);
  }

  onGoToFaq(result: { faq: Faq, category: FaqCategory, section: HelpcenterSection }) {
    this.goToFaq.emit(result);
  }

}
