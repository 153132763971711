import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Appointment } from '../../models/appointment';
import { DateFormat } from '../../models/date-format';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-calendar-appointment-compact',
  templateUrl: './calendar-appointment-compact.component.html',
  styleUrls: ['./calendar-appointment-compact.component.scss']
})
export class CalendarAppointmentCompactComponent implements OnInit {
  @Output() onViewDetails: EventEmitter<any> = new EventEmitter();
  @Output() onCloseCompact: EventEmitter<any> = new EventEmitter();

  @Input() appointment:Appointment;

  public dateFormat: DateFormat;
  public timeFormat: string;

  constructor(
    public localeService: LocaleService
  ) { }

  ngOnInit() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
  }

  viewDetailsHandler(event) {
    event.preventDefault();
    this.onViewDetails.emit();
  }

  getAllInviteeNames(): string {
    const names = [];
    this.appointment.invitees.forEach((invite) => {
      names.push(invite.first_name + ' ' + invite.last_name);
    });
    return names.join(', ');
  }

  handleClose() {
    this.onCloseCompact.emit();
  }

}
