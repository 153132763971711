import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Faq } from '../../models/faq';
import { FaqCategory } from '../../models/faq-category';
import { HelpcenterSection } from '../../models/helpcenter-section';
import { DataService } from '../../services/data.service';
import { HelpcenterService } from '../../services/helpcenter.service';

@Component({
  selector: 'app-help-center-modal',
  templateUrl: './help-center-modal.component.html'
})
export class HelpCenterModalComponent implements OnInit {
  public helpcenterSections: HelpcenterSection[] = []
  public currentView = HelpCenterModalViews.OVERVIEW;
  public helpCenterModalViews = HelpCenterModalViews;

  public faqs: FaqCategory[] = [];

  public faqSelected: Faq;
  public categorySelected: FaqCategory;

  constructor(
    public bsModalRef: BsModalRef,
    public helpcenterService: HelpcenterService,
    public dataService: DataService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getHelpCenterSections();
  }

  getHelpCenterSections() {
    this.helpcenterService.getHelpCenter().subscribe((sections: HelpcenterSection[]) => this.helpcenterSections = sections);
  }

  goToSectionOverview(section: HelpcenterSection) {
    this.currentView = this.helpCenterModalViews.FAQ_OVERVIEW
    this.faqs = section.faqs;
  }

  goToFaq(result: { faq: Faq, category: FaqCategory, section: HelpcenterSection }) {
    this.currentView = this.helpCenterModalViews.FAQ_DETAIL;
    this.faqSelected = result.faq;
    this.categorySelected = result.category;
  }

  goToOverview() {
    this.currentView = this.helpCenterModalViews.OVERVIEW
  }
}

export enum HelpCenterModalViews {
  OVERVIEW = 'OVERVIEW',
  FAQ_OVERVIEW = 'FAQ_OVERVIEW',
  FAQ_DETAIL = 'FAQ_DETAIL'
}
