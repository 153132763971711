<div class="layout-sidebar container">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-info font-weight-bolder">{{ 'action.create_acc' | translate }}</p>
      <app-onboaring-icon-steps [onBoardingType]="onBoardingType" [currentState]="currentState"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part mt-auto d-none d-sm-block">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">

  <div class="body-content">
    <div class="container">
      <h1 class="display-1 mb-3">{{ 'pages.flow.self_onboarding_details.your_details' | translate }}</h1>
      <app-onboarding-details-form #form (onFormInitialized)="onFormInitialized($event)" [contactConsented]="contactConsented" [mode]="'self-onboarding'" [data]="formComponentData"  [validationVisible]="validationVisible" [code]="onboardingCode" [language]="onboardingLanguage"></app-onboarding-details-form>

      <div class="footer-part mt-auto d-block d-sm-none">
        <app-flow-copyrights></app-flow-copyrights>
      </div>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <button id="button_onboarding_details_confirm" class="btn btn-secondary m-start-auto" (click)="formSubmit()" [ngClass]="{'loader': isLoading}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
          <span>{{ 'action.continue' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

</div>
