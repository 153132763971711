import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DateFormat } from '../../models/date-format';
import { UserTask } from '../../models/user-task';
import { UserTaskBasic } from '../../models/user-task-basic';
import { AuthenticationService } from '../../services/authentication.service';
import { ErrorService } from '../../services/error.service';
import { LocaleService } from '../../services/locale.service';
import { PatientService } from '../../services/patient.service';
import { UserTaskService } from '../../services/user-task.service';

@Component({
  selector: 'app-user-task-standard-modal',
  templateUrl: './user-task-standard-modal.component.html',
  styleUrls: ['./user-task-standard-modal.component.scss']
})
export class UserTaskStandardModalComponent implements OnInit {
  @Output() taskSubmitSuccess: EventEmitter<any> = new EventEmitter();

  public userTaskBasic: UserTaskBasic;
  public userTask: UserTask;

  public isLoading: boolean;
  public isSaving: boolean;
  public isSubmitting: boolean;
  public validationVisible: boolean;
  public form: FormGroup = this.formBuilder.group({});

  public patient_uid: string;
  public patient_pathway_uid: string;

  public dateFormat: DateFormat;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public localeService: LocaleService,
    public bsDatepickerConfig: BsDatepickerConfig,
    public patientService: PatientService,
    public authenticationService: AuthenticationService,
    public taskService: UserTaskService,
    public errorService: ErrorService,
    public toastrService: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    const preferences = this.localeService.getLocalePreferences();

    this.bsDatepickerConfig.dateInputFormat = this.localeService.getBsDatePickerInputFormat(preferences.locale);
    this.bsDatepickerConfig.adaptivePosition = true;

    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    if (!this.userTask) this.getUserTask(this.userTaskBasic.uid);
  }

  getUserTask(id) {
    this.isLoading = true;

    const patient_uid = this.patientService.getCurrentStoredPatientUid();
    this.taskService.getUserTask(id, patient_uid).subscribe(result => {
      this.userTask = new UserTask(result);
      this.patient_uid = this.userTask.patient?.uid;
      this.patient_pathway_uid = this.userTask.patient_pathway_id;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.validationVisible = true;
      return;
    }

    this.isSubmitting = true;
    this.taskService.completeUserTask(
      this.userTask?.uid,
      this.userTask?.patient.uid,
      { variables: this.form.value }
    ).subscribe(result => {
      this.resultHandler();
    }, error => {
      this.errorHandler(error);
    });

  }

  resultHandler() {
    this.taskSubmitSuccess.emit();
    this.isSubmitting = false;
    this.validationVisible = false;
    this.toastrService.info(this.translate.instant('modals.user_task_standard.update_success'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.bsModalRef.hide();
  }

  errorHandler(error: any) {
    this.isSaving = false;
    this.isSubmitting = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {

        this.form.get(err.field)?.setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  handleCancel() {
    this.bsModalRef.hide();
  }
}

