<div class="heading-block mb-3 help-center-faq-list" *ngIf="!compact">
  <div class="block-wrapper overflow-hidden py-2">
    <h2 class="text-truncate">{{ helpcenterSection?.title }}</h2>
    <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
  </div>
  <button class="btn block-action m-start-auto" (click)="goToSectionOverview(helpcenterSection)"
    *ngIf="helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow">
    <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon rtl-mirrored-inline-svg"></span>
  </button>
</div>

<div *ngIf="compact">
  <h2>{{ helpcenterSection?.title }}</h2>
  <p class="mb-4">{{ helpcenterSection?.subtitle }}</p>
</div>

<div class="faq-blocks">
  <div class="items">
    <div class="faq-block"
      *ngFor="let category of helpcenterSection?.faqs?.slice(0, helpcenterSection.maxCategoriesToShow)">
      <app-faq-block [category]="category" [section]="helpcenterSection" (goToFaq)="onGoToFaq($event)"></app-faq-block>
    </div>
  </div>
</div>

<div class="text-end" *ngIf="(helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow)">
  <button (click)="goToSectionOverview(helpcenterSection)"
    class="btn text-secondary d-flex align-items-center justify-content-end m-start-auto p-end-0">
    <span>{{ 'pages.default.help_center.show_all' | translate }}</span>
    <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
  </button>
</div>
