import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatchingFieldsValidator } from './../../../validators/matching-fields-validator';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from './../../../services/password.service';
import { PasswordPolicy } from './../../../models/password-policy';
import { PasswordPolicyValidator } from './../../../validators/password-policy-validator';
import { HomeFlowService } from '../../../services/home-flow.service';
import { OnboardingState } from '../../../enums/onboarding-state';
import { OnBoardingType } from '../../../enums/onboarding-type';
import { OnboardingFlow } from '../../../enums/onboarding-flow';

@Component({
  selector: 'app-onboarding-password',
  templateUrl: './onboarding-password.component.html',
  styleUrls: ['./onboarding-password.component.scss']
})
export class OnboardingPasswordComponent implements OnInit {
  public form: UntypedFormGroup;
  public errorToast: any;
  public isLoading: boolean;
  public validationVisible: boolean;
  public passwordIsBad: boolean;
  public policy: PasswordPolicy;

  public onBoardingType = OnBoardingType.CC;
  public currentState = OnboardingState.PASSWORD;

  public showOnboardingIconSteps: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public toastService: ToastrService,
    public router: Router,
    public passwordService: PasswordService,
    public homeFlowService: HomeFlowService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.flow === OnboardingFlow.ONBOARDING) this.showOnboardingIconSteps = true;
    });

    this.formSetup();
    this.passwordService.policy().subscribe(policy => this.onPolicySuccess(policy));
  }

  formSetup() {
    this.form = this.formBuilder.group({
      account_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    }, {
      validators: [MatchingFieldsValidator.validate('account_password', 'confirm_password', 'mismatch')]
    });

    this.setPolicyValidator();
  }

  onPolicySuccess(policy: PasswordPolicy) {
    this.policy = policy;
    this.setPolicyValidator();
  }

  setPolicyValidator() {
    if (this.form && this.policy) {
      this.form.get('account_password').setValidators([
        Validators.required,
        PasswordPolicyValidator.validate(this.policy)
      ]);
    }
  }

  formSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.setPassword();
    }
  }

  setPassword() {
    this.isLoading = true;
    this.passwordService.setPassword(this.form.value.account_password, this.form.value.confirm_password).subscribe(
      () => this.onSetPasswordSuccess(),
      error => this.onSetPasswordError(error)
    );
  }

  onSetPasswordSuccess() {
    this.homeFlowService.goNextStepOrDone().subscribe(() => {
      this.isLoading = false;
    });
  }

  onSetPasswordError(error) {
    this.isLoading = false;

    if (error.status === 400) {
      this.passwordIsBad = true;
    } else {
      this.router.navigateByUrl('error');
    }
  }
}
