import { FaqCategory } from './faq-category';
import { HelpcenterArticle } from './helpcenter-article';
import { HelpcenterContact } from './helpcenter-contact';

interface Path {
  title: string;
  href: string;
}

export enum SectionType {
  ARTICLES = 'articles',
  FAQS = 'faqs',
  CONTACT = 'contact',
  BANNER = 'banner'
}

export enum Layout {
  BIG = '2L-3S',
  SMALL = '3S'
}
export class HelpcenterSection {
  public type: string;
  public title: string;
  public subtitle: string;
  public referenceUids: Array<string>;
  public articles: Array<HelpcenterArticle>;
  public thumbnail: any;
  public path: Path;
  public faqs: Array<FaqCategory>;
  public contacts: Array<HelpcenterContact>;
  public maxCategoriesToShow: number;
  public layout: Layout;

  constructor(item?) {
    if (item) {
      const type = Object.keys(item)[0];
      this.type = type;
      this.title = item[type].title;
      this.subtitle = item[type].subtitle;

      if (type === SectionType.ARTICLES) {
        const uids = item[type].help_article.map(article => article.uid);
        this.referenceUids = uids;
        this.layout = item[type].layout;
      }

      if (type === SectionType.FAQS) {
        const uids = item[type].reference.map(i => i.uid);
        this.referenceUids = uids;
        this.maxCategoriesToShow = item[type].max_categories_to_show;
      }

      if (type === SectionType.CONTACT) {
        const uids = item[type].reference.map(i => i.uid);
        this.referenceUids = uids;
      }

      if (type === SectionType.BANNER) {
        this.title = item[type].text;
        this.subtitle = item[type].subtext;

        this.thumbnail = item[type].image;
        this.path = item[type].path;
      }
    }
  }

  public get isArticles(): boolean {
    return this.type === SectionType.ARTICLES;
  }

  public get isFaqs(): boolean {
    return this.type === SectionType.FAQS;
  }

  public get isContact(): boolean {
    return this.type === SectionType.CONTACT;
  }

  public get isBanner(): boolean {
    return this.type === SectionType.BANNER;
  }

  public get has2LBlocks(): boolean {
    return this.layout === Layout.BIG;
  }

  addArticles(articles: HelpcenterArticle[]) {
    this.articles = [];
    articles.forEach(article => {
      const index = this.referenceUids.indexOf(article?.uid);
      this.articles[index] = article;
    });
  }

  addContacts(contacts: HelpcenterContact[]) {
    this.contacts = [];
    contacts.forEach(contact => {
      const index = this.referenceUids.indexOf(contact?.uid);
      this.contacts[index] = contact;
    });
  }
}
