export class FileMetaData {
  public file_name: string;
  public extension: string;
  public mime_type: string;
  public size: number;
  public created_at: string;
  public scanned_at: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.file_name = item.file_name ?? '';
    this.extension = item.extension ?? '';
    this.mime_type = item.mime_type ?? '';
    this.size = item.size ?? '';
    this.created_at = item.created_at ?? '';
    this.scanned_at = item.scanned_at ?? '';
  }
}