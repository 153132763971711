import { Invitee } from "./invitee";
import { LocationInterface } from '../interfaces/location.interface';
import { TranslationInterface } from "../interfaces/translation.interface";
import { HealthCareProfessional } from "./health-care-professional";

export enum AppointmentStatus {
  NONE = 'NONE',
  VIEWED = 'VIEWED',
  REMOVED = 'REMOVED'
}

export enum AppointmentType {
  SURGERY = 'SURGERY',
  TREATMENT = 'TREATMENT',
  THERAPY = 'THERAPY'
}

export class Appointment {
  public uid: string;
  public title: string;
  public title_key: TranslationInterface;
  public description: string;
  public description_key: TranslationInterface;
  public type: AppointmentType;
  public start_date: string;
  public end_date: string;
  public date: string;
  public location: LocationInterface;
  public cancelled: boolean;
  public organised_by_user: boolean;
  public organiser: HealthCareProfessional;
  public changed_by: HealthCareProfessional;
  public invitees: Invitee[];
  public status_of_user: string;
  public created_for_phase_id: string;

  public translationKey: string;
  public translationDescriptionKey: string;



  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.title = item.title ? item.title : '';
    this.title_key = item.title_key ? item.title_key : null;
    this.description = item.description ? item.description : '';
    this.description_key = item.description_key ? item.description_key : null;
    this.type = item.type ? item.type : null;
    this.start_date = item.start_date;
    this.end_date = item.end_date;
    this.date = item.date;
    this.location = item.location ? item.location : null;
    this.cancelled = item.cancelled ? item.cancelled : null;
    this.organised_by_user = item.organised_by_user ? item.organised_by_user : null;
    this.invitees = item.invitees ? item.invitees : null;
    this.status_of_user = item.status_of_user ? item.status_of_user : null;
    this.created_for_phase_id = item.created_for_phase_id ? item.created_for_phase_id : null;
    this.translationKey = `shared.${this.title_key?.region}.${this.title_key?.key}`;
    this.translationDescriptionKey = `shared.${this.description_key?.region}.${this.description_key?.key}`;

    if(item.organiser) {
      this.organiser = new HealthCareProfessional(item.organiser);
    }

    if(item.changed_by) {
      this.changed_by = new HealthCareProfessional(item.changed_by);
    }

    if(item.invitees) {
      this.invitees = [];
      item.invitees.forEach(invitee => {
        this.invitees.push(new Invitee(invitee));
      });
    }
  }

  merge(appointment: Appointment) {
    this.title = appointment.title;
    this.description = appointment.description;
    this.start_date = appointment.start_date;
    this.end_date = appointment.end_date;
    this.date = appointment.date;
    this.location = appointment.location;
    this.cancelled = appointment.cancelled;
    this.organised_by_user = appointment.organised_by_user;
    this.organiser = appointment.organiser;
    this.changed_by = appointment.changed_by;
    this.invitees = appointment.invitees;
    this.status_of_user = appointment.status_of_user;
  }

  hasViewedState(): boolean {
    return this.status_of_user === AppointmentStatus.VIEWED;
  }

  hasNoneState(): boolean {
    return this.status_of_user === AppointmentStatus.NONE;
  }

  getInvitees(includePatient: boolean = true) {
    const invitees = [];

    this.invitees.forEach(_invitee => {
      if(!includePatient) {
        if(_invitee.type !== "PATIENT") {
          invitees.push(_invitee);
        }
      } else {
        invitees.push(_invitee);
      }
    });

    return invitees;
  }

  hasCancelledState() {
    if(this.status_of_user === 'DECLINED') {
      return true;
    }
    return this.cancelled;
  }
}
