import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackButtonData } from '../../../../models/back-button-data';
import { Faq } from '../../../../models/faq';
import { FaqCategory } from '../../../../models/faq-category';
import { DataService } from '../../../../services/data.service';
import { GeneralService } from '../../../../services/general.service';

@Component({
  selector: 'app-help-center-faqs-detail',
  templateUrl: './help-center-faqs-detail.component.html'
})
export class HelpCenterFaqsDetailComponent implements OnInit {
  public faq: Faq;
  public category: FaqCategory;
  public backButtonData: BackButtonData;

  constructor(
    public dataService: DataService,
    public router: Router,
    public generalService: GeneralService,
    private readonly activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(queryParams => {
      this.faq = this.dataService.get(DataService.HelpcenterFaqSelected);
      this.category = this.dataService.get(DataService.HelpcenterFaqCategorySelected);
    });
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.handleQueryParams(queryParams);
    });
  }

  handleQueryParams(queryParams) {
    const backToUrl = queryParams['back-to-url'];

    if (backToUrl) {
      this.backButtonData = this.generalService.defineBackButton(backToUrl);
    }
  }

  goToFaq(result: {faq: Faq, category: FaqCategory}) {
    this.dataService.set(DataService.HelpcenterFaqSelected, result.faq);
    this.dataService.set(DataService.HelpcenterFaqCategorySelected, result.category);

    this.router.navigateByUrl(`/help-center/faqs/${result.faq.uid}`);
  }
}
