import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination-range',
  templateUrl: './pagination-range.component.html',
  styleUrls: ['./pagination-range.component.scss']
})
export class PaginationRangeComponent implements OnInit {
  @Input()
  get rangeOptions(): number[] { return this._rangeOptions; }
  set rangeOptions(options: number[]) {
    this._rangeOptions = options;
  }
  private _rangeOptions: number[] = [10, 25, 50, 100];

  @Input()
  get currentRange(): number { return this._currentRange; }
  set currentRange(range: number) {
    this._currentRange = range;
  }
  private _currentRange = this.rangeOptions[0];

  @Output() rangeChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  onRangeChange(event: any) {
    this.rangeChange.emit(this.currentRange);
  }
}
