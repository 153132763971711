<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part mb-0">
      <div class="sidebar-logo" [inlineSVG]="'/assets/svg/logo-patient.svg'"></div>
    </div>

    <div class="content-part" [ngClass]="{'validation-visible': showFieldValidation()}" *ngIf="view === 'CODE'">
      <div class="mb-3">
        <h2 *ngIf="currentMfaMethod?.channel === 'EMAIL'">{{ 'pages.flow.mfa_code.enter_security_code_email' | translate }}</h2>
        <h2 *ngIf="currentMfaMethod?.channel === 'SMS'">{{ 'pages.flow.mfa_code.enter_security_code_sms' | translate }}</h2>
        <p>{{ 'pages.flow.mfa_code.subtitle' | translate }}</p>
      </div>

      <div class="form-group styled floating-label mb-1">
        <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('pages.flow.mfa_code.security_code' | translate)" [ngClass]="{'ng-invalid-important': showFieldValidation()}" [(ngModel)]="codeInput" autocomplete="off">
        <label for="inputCode">{{ 'pages.flow.mfa_code.security_code' | translate }}</label>
        <p class="validation-feedback general-feedback" *ngIf="codeIncorrect">{{ 'pages.flow.mfa_code.code_invalid' | translate }}</p>
        <p class="validation-feedback general-feedback" *ngIf="codeEmpty && !codeInput">{{ 'pages.flow.mfa_code.enter_security_code' | translate }}</p>
      </div>

      <p class="mb-0">
        <a href="" class="text-link" id="button_mfa_resend_code" (click)="submitRequestNewCode($event)">{{ 'pages.flow.mfa_code.resend_code' | translate }}</a>
        <span *ngIf="mfaMethods?.length>1">&nbsp;{{ 'pages.flow.mfa_code.or' | translate }}&nbsp;</span>
        <a href="" class="text-link" id="button_mfa_other_method" (click)="viewOtherMethods($event)" *ngIf="mfaMethods?.length>1">{{ 'pages.flow.mfa_code.use_a_backup_method' | translate }}</a>
      </p>

      <button id="button_mfa_submit" type="submit" class="btn btn-sm btn-secondary mt-2 mt-lg-3" [ngClass]="{'loader': isLoading}" (click)="submit()">
        <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
        <span>{{ 'action.sign_in' | translate }}</span>
      </button>
    </div>

    <div class="content-part my-auto" *ngIf="view === 'CODE' && mfaMethods?.length == 1">
      <h3 class="mb-1">
        <em [inlineSVG]="'/assets/svg-color/idea.svg'"></em>
        <span class="align-middle">{{ 'pages.flow.mfa_code.did_you_know' | translate }}</span>
      </h3>
      <p class="m-0">{{ 'pages.flow.mfa_code.did_you_know_text' | translate }}</p>
    </div>

    <div class="content-part mb-auto" *ngIf="view === 'METHODS'">
      <div class="mb-3">
        <h2>{{ 'pages.flow.mfa_code.choose_backup_method' | translate }}</h2>
      </div>

      <p class="mb-2" *ngFor="let mfaMethod of mfaMethods">
        <a href="" class="text-icon-link hoverable text-secondary" (click)="selectMethod($event, mfaMethod)">
          <span class="label" *ngIf="mfaMethod?.channel === 'EMAIL'">{{ 'pages.flow.mfa_code.send_mail_to_input' | translate:{'input': mfaMethod?.value} }}</span>
          <span class="label" *ngIf="mfaMethod?.channel === 'SMS'">{{ 'pages.flow.mfa_code.send_sms_to_input' | translate:{'input': mfaMethod?.value}   }}</span>
          <em class="icon-end rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></em>
        </a>
      </p>
    </div>

    <div class="footer-part">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>
</div>