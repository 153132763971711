<circle-progress
  class="small"
  [outerStrokeWidth]="strokeWidth"
  [innerStrokeWidth]="strokeWidth"
  [space]="-(strokeWidth)"
  [showTitle]="false"
  [showSubtitle]="false"
  [showUnits]="false"
  [percent]="percent"
  [animation]="false"
></circle-progress>
