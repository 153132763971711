import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    public translateService: TranslateService,
    public toastrService: ToastrService,
    public router: Router
  ) { }

  public getBackendErrorByKey(errorKey: string): Observable<string> {
    return this.translateService.get(`shared.business_error_keys.${errorKey}`);
  }

  public showBackendErrorToastByKey(errorKey: string, duration = 6000) {
    this.getBackendErrorByKey(errorKey).subscribe(transResult => {
      this.toastrService.error(transResult, null, {
        disableTimeOut: false,
        timeOut: duration
      });
    });
  }

  public showGeneralBackendErrorToast(duration = 6000) {
    this.translateService.get('error.general').subscribe(transResult => {
      this.toastrService.error(transResult, null, {
        disableTimeOut: false,
        timeOut: duration
      });
    });
  }

  public showSessionTimeOutErrorToast(duration = 6000) {
    this.translateService.get('error.timeout').subscribe(transResult => {
      this.toastrService.error(transResult, null, {
        disableTimeOut: false,
        timeOut: duration
      });
    });
  }

  public showPlatformInactiveError(errorKey: string): void {
    this.router.navigateByUrl('/inactive');
  }
}
