import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import { HttpClient } from '@angular/common/http';
import {AuthenticationService} from '../services/authentication.service';
import {LanguageService} from '../services/language.service';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import { Country } from '../models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends ApiService {

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public languageService: LanguageService
  ) {
    super(http, authenticationService);
  }

  getCountriesFromCms(): Observable<Array<Country>> {
    const url = environment.cmsUrl + '/v3/content_types/countries/entries?asc=title';
    const locale = this.languageService.getCurrentLanguage().locale;

    return new Observable<Country[]>(observer => {
      this.cmsGet(url, locale, null, null, true).subscribe(result => {
        const entries = result['entries'] || [];
        observer.next(entries.map(country => new Country(country)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
