import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../services/authentication.service';
import { LogoutModalComponent } from './../../modals/logout-modal/logout-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../services/general.service';
import { UserService } from '../../services/user.service';
import { ScopeService } from '../../services/scope.service';
import { Patient } from '../../models/patient';
import { PatientService } from '../../services/patient.service';
import { AttentionCenterService } from '../../services/attention-center.service';
import { AttentionCenter } from '../../models/attention-center';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarOpen: boolean;
  public backdropVisible: boolean;
  public backdropShown: boolean;
  public activeState: string;

  public attentionCenter: AttentionCenter;
  public attentionCenterFetchInterval: any;

  public permissionTypeEnum: any;

  public currentPatient: Patient;
  public currentPermissionHospital: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    public modalService: BsModalService,
    public userService: UserService,
    public scopeService: ScopeService,
    public patientService: PatientService,
    public generalService: GeneralService,
    public attentionCenterService: AttentionCenterService
  ) { }

  ngOnInit() {
    this.permissionTypeEnum = UserService.PermissionTypeEnum;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.defineActiveState();
      }
    });
    this.defineActiveState();

    this.scopeService.onScopeChanged.subscribe(() => this.updateVisualScope());
    this.updateVisualScope();

    this.attentionCenterFetchInterval = setInterval(() => {
      if (this.authenticationService.accessTokenValid) {
        this.getAttentionCenter();
      }

    }, 30000);
    this.getAttentionCenter();
  }

  ngOnDestroy() {
    this.attentionCenterResolver();
  }

  attentionCenterResolver() {
    clearInterval(this.attentionCenterFetchInterval);
  }

  openSidebarHandler(event?) {
    event.preventDefault();
    this.sidebarOpen = true;
    this.backdropVisible = true;
    setTimeout(() => {
      this.backdropShown = true;
    });
  }

  closeSidebarHandler(event?) {
    event.preventDefault();
    this.backdropShown = false;
    this.sidebarOpen = false;
    setTimeout(() => {
      this.backdropVisible = false;
    }, 150);
  }

  showLogoutModal(event) {
    event.preventDefault();

    this.modalService.show(LogoutModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  defineActiveState() {
    const url = this.router.url;

    if (url.indexOf('/dashboard') === 0 ||
      url.indexOf('/medical-team') === 0 ||
      url.indexOf('/faq') === 0 ) {
      this.activeState = 'treatments';
    } else if (url.indexOf('/settings') === 0) {
      this.activeState = 'settings';
    } else if (url.indexOf('/conversations') === 0) {
      this.activeState = 'messages';
    } else if (url.indexOf('/calendar') === 0) {
      this.activeState = 'calendar';
    } else if (url.indexOf('/help-center') === 0) {
      this.activeState = 'help-center';
    }
  }

  updateVisualScope() {
    this.currentPatient = this.patientService.getCurrentStoredPatient();
    this.currentPermissionHospital = this.scopeService.getCurrentHospitalBasic();
  }

  triggerCookies(event) {
    event.preventDefault();
    this.generalService.openCookieSettings();
  }

  goToCookiePolicy(event) {
    event.preventDefault();
    this.generalService.goToCookiePolicy();
  }

  getAttentionCenter() {
    this.attentionCenterService.get().subscribe(ac => {
      this.attentionCenter = ac;
    });
  }
}
