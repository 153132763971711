import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { LanguageService } from './language.service';
import { Banner } from '../models/banner';

@Injectable({
  providedIn: 'root'
})

export class BannerService extends ApiService {
  public static StorageKeyHiddenBanner = 'C4T_hidden_banner';
  public static SessionKeyShownBanner = 'C4T_shown_banner';

  constructor(
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    private languageService: LanguageService,
  ) {
    super(http, authenticationService);
  }

  get hiddenBanner(): { uid: string, updated_at: string } | null {
    // A banner is hidden when you acknowledged it by clicking "I understand" in the init-banner-modal
    return localStorage.getItem(BannerService.StorageKeyHiddenBanner) ?
      JSON.parse(localStorage.getItem(BannerService.StorageKeyHiddenBanner)) :
      null;
  }

  get shownBanner() {
    // A banner is marked shown when a user has logged in, and the init-banner-modal was shown
    return sessionStorage.getItem(BannerService.SessionKeyShownBanner) ?
      JSON.parse(sessionStorage.getItem(BannerService.SessionKeyShownBanner)) :
      null;
  }

  getBanners(): Observable<Banner | null> {
    return new Observable(observer => {
      const url = environment.cmsUrl + '/v3/content_types/banner/entries/bltb23f28d8f7f773c8';
      const locale = this.languageService.getCurrentLanguage().locale;

      this.cmsGet(url, locale, null, null).subscribe(result => {
        const banner = result.entry;

        if (banner.active) {
          observer.next(new Banner(banner));
          observer.complete();
        } else {
          observer.next(null);
          observer.complete();
        }
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  updateHiddenBanners(uid: string, updated_at: string): void {
    localStorage.setItem(BannerService.StorageKeyHiddenBanner, JSON.stringify({ uid, updated_at }));
  }

  isBannerHidden(uid: string, updated_at: string): boolean {
    return this.hiddenBanner?.updated_at === updated_at;
  }

  updateShownBanners(uid: string): void {
    sessionStorage.setItem(BannerService.SessionKeyShownBanner, uid);
  }

  isBannerShown(uid: string): boolean {
    return this.shownBanner === uid;
  }
}
