import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'evalTranslation' })
export class EvalTranslation implements PipeTransform {
  constructor() { }

  transform(translation: string): string {
    
    // if (translation && translation.startsWith('shared.')) {
    //   const parts = translation.split('.');

    //   if (parts.length) {
    //     let last: string = parts[parts.length - 1];

    //     last = last.replace(/-/g, ' ');
    //     last = last.replace(/_/g, ' ');

    //     return last;
    //   } else {
    //     return translation;
    //   }
    // } else {
    //   return translation;
    // }

    return translation;
  }
}
