import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AppointmentModalComponent} from './../../../modals/appointment-modal/appointment-modal.component';
import {AppointmentSelectionEvent} from '../../../events/appointment-selection-event';
import moment from 'moment';
import {UserService} from "../../../services/user.service";
import {DateFormat} from "../../../models/date-format";
import {Subject, Subscription} from "rxjs";
import { LocaleService } from '../../../services/locale.service';
import { AppointmentService } from '../../../services/appointment.service';
import { Appointment } from '../../../models/appointment';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';
import { GeneralService } from '../../../services/general.service';
import { TranslateService } from "@ngx-translate/core";
import { AttentionCenterService } from '../../../services/attention-center.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  public currentView: string;
  public showTreatmentsFilter: boolean;
  public disableTreatmentsFilter = true;
  public filtersAreSet: boolean;
  public showPendingInvitations: boolean;
  public pendingAppointments: Appointment[];
  public pendingAppointmentsFetchInterval: any;

  public onNewAppointmentsPendingSubscription: Subscription;

  public currentDay: any;
  public currentMonthFirstDay: any;
  public currentWeekFirstDay:any;
  public currentWeekLastDay:any;
  public currentYear: any;

  public yearChanged: Subject<any> = new Subject();

  public compactAppointment: any;
  public compactAppointmentCoords: any = {
    x: 0,
    y: 0,
    downwards: false
  };

  public dateFormat: DateFormat;
  public time_24_hours: boolean;
  public timeZone: string;

  constructor(
    public modalService: BsModalService,
    public userService: UserService,
    public localeService: LocaleService,
    public appointmentService: AppointmentService,
    public translate: TranslateService,
    public attentionCenterService: AttentionCenterService
  ) {
  }

  ngOnInit() {
    this.setLocale();
    this.loadLocalStorageVariables();
    this.loadPendingAppointments();

    this.appointmentService.appointmentChanged$.subscribe(appointment => {
      this.whenAppointmentHasChanged(appointment);
    });

    this.appointmentService.declineAppointmentClicked$.subscribe(appointment => {
      this.openConfirmModal(appointment);
    });

    this.appointmentService.appointmentStatusChanged$.subscribe(() => {
      this.loadPendingAppointments()
    });
    this.onNewAppointmentsPendingSubscription = this.attentionCenterService.onNewAppointmentsPending.subscribe(() => this.loadPendingAppointments());

  }

  ngOnDestroy() {
    clearInterval(this.pendingAppointmentsFetchInterval);
    this.appointmentService.completeDeclineObserver();
    this.onNewAppointmentsPendingSubscription?.unsubscribe();
  }

  whenAppointmentHasChanged(changedAppointment: Appointment) {
    let pendingAppointmentWasAffected = false;

    if(this.pendingAppointments && this.pendingAppointments.length) {
      this.pendingAppointments.forEach(pendingAppointment => {
        if(pendingAppointment.uid === changedAppointment.uid ) {
          pendingAppointmentWasAffected = true;
        }
      });
    }

    if(pendingAppointmentWasAffected) {
      this.loadPendingAppointments();
    }
  }

  setLocale() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.time_24_hours = this.localeService.getLocalePreferences().locale.time_24_hours;
    this.timeZone = this.localeService.getLocalePreferences().locale.time_zone;

    this.localeService.doLocaleConfiguration();
  }

  loadLocalStorageVariables() {
    this.currentView = localStorage.getItem('current_view');
    if (!this.currentView) {
      this.currentView = 'year';
      localStorage.setItem('current_view', this.currentView);
    }

    this.setCurrentDates();
  }

  setCurrentDates() {
    this.currentYear = moment().year();
    this.currentDay = moment();
    this.currentMonthFirstDay = moment(this.currentDay).startOf('month');
    this.currentWeekFirstDay = moment(this.currentDay).startOf('week');
    this.currentWeekLastDay = this.currentWeekFirstDay.clone().endOf('week');
    localStorage.setItem('current_year', this.currentYear);
  }

  changeViewTo(view: string) {
    this.setCurrentDates();
    this.currentView = view;
    localStorage.setItem('current_view', this.currentView);
  }

  toggleTreatmentsFilter(shown: boolean = undefined) {
    if (shown !== undefined) {
      this.showTreatmentsFilter = shown;
    } else {
      this.showTreatmentsFilter = !this.showTreatmentsFilter;
    }
  }

  resetFilters() {
    this.filtersAreSet = false;
  }

  clickOutsideHeaderFilters() {
    this.toggleTreatmentsFilter(false);
  }

  loadPendingAppointments() {
    this.appointmentService.getPendingAppointments().subscribe(pendingAppointments => {
      this.pendingAppointments = pendingAppointments;
    });
  }

  openPendingAppointment(appointmentSelectionEvent: AppointmentSelectionEvent) {
    this.togglePendingInvitations(null, false);
    this.openAppointmentModal(appointmentSelectionEvent);
  }

  togglePendingInvitations(event, shown: boolean = undefined) {
    if (event) {
      event.preventDefault();
    }

    if (shown !== undefined) {
      this.showPendingInvitations = shown;
    } else {
      this.showPendingInvitations = !this.showPendingInvitations;
    }
  }

  clickOutsidePendingInvitations() {
    this.togglePendingInvitations(null, false);
  }

  openAppointmentCompact(appointmentSelectionEvent: AppointmentSelectionEvent) {
    const event = appointmentSelectionEvent.event;

    let downwards = false;
    let horSided = '';

    const verticalThreshold = (window.innerHeight / 2.5);
    const horizontalThreshold = 180;

    if (event.clientY < verticalThreshold) {
      downwards = true;
    }

    if (event.clientX <= horizontalThreshold) {
      horSided = 'start';
    } else if (event.clientX >= (window.innerWidth - horizontalThreshold)) {
      horSided = 'end';
    }

    this.compactAppointmentCoords = {
      x: event.clientX,
      y: event.clientY,
      downwards: downwards,
      horSided: horSided
    };

    if (this.compactAppointment !== appointmentSelectionEvent.context) {
      this.compactAppointment = appointmentSelectionEvent.context;
    } else {
      this.clearCompactComponent();
    }
  }

  clearCompactComponent() {
    this.compactAppointmentCoords = undefined;
    this.compactAppointment = undefined;
  }

  compactAppointmentViewDetails() {
    this.openAppointmentModal(new AppointmentSelectionEvent(null, this.compactAppointment));
    this.clearCompactComponent();
  }

  openAppointmentModal(appointmentSelectionEvent: AppointmentSelectionEvent) {
    const initialState = {
      appointment: appointmentSelectionEvent.context,
      dateFormat: this.dateFormat,
      time_24_hours: this.time_24_hours
    };

    const modalref = this.modalService.show(AppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  openConfirmModal(appointment) {
    const initialState = {
      title: this.translate.instant('modals.appointment.decline_appointment'),
      description: this.translate.instant('modals.appointment.are_you_sure_no_undo'),
      no: this.translate.instant('action.cancel'),
      yes: this.translate.instant('modals.appointment.yes_decline')
    };

    const modalref = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalref.content.onChoice.subscribe(result => {
      this.appointmentService.changeAppointment(appointment, 'DECLINED').subscribe((choice: boolean) => {
        if(choice) {
          modalref.hide();
          appointment.status_of_user = 'DECLINED';
        }
      });
    });
  }

  nextMonth() {
    this.currentMonthFirstDay = moment(this.currentMonthFirstDay).add(1, 'M').startOf('month');
    localStorage.setItem('current_month_first_day', this.currentMonthFirstDay);
  }

  prevMonth() {
    this.currentMonthFirstDay = moment(this.currentMonthFirstDay).add(-1, 'M').startOf('month');
    localStorage.setItem('current_month_first_day', this.currentMonthFirstDay);
  }

  nextWeek() {
    this.currentWeekFirstDay = moment(this.currentWeekFirstDay).add(1, 'w').startOf('week');
    this.currentWeekLastDay = this.currentWeekFirstDay.clone().endOf('week');

    localStorage.setItem('current_week_first_day', this.currentWeekFirstDay);
  }

  prevWeek() {
    this.currentWeekFirstDay = moment(this.currentWeekFirstDay).add(-1, 'w').startOf('week');
    this.currentWeekLastDay = this.currentWeekFirstDay.clone().endOf('week');

    localStorage.setItem('current_week_first_day', this.currentWeekFirstDay);
  }

  nextYear() {
    this.currentYear = (moment('1/1/' + this.currentYear).add(1, 'y')).year();
    localStorage.setItem('current_year', this.currentYear);
    this.yearChanged.next(this.currentYear);
  }

  prevYear() {
    this.currentYear = (moment('1/1/' + this.currentYear).subtract(1, 'y')).year();
    localStorage.setItem('current_year', this.currentYear);
    this.yearChanged.next(this.currentYear);
  }

  onMore(event) {
    this.changeViewTo('year');
    setTimeout(() => {
      this.appointmentService.moreAppointmentsClickedSource.next(event);
    });
  }

  selectAllTreatments() {}
}
