<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p class="m-0">
        <a class="text-secondary font-weight-bold d-flex align-items-center" routerLink="/help-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to' | translate }} <span>{{ 'pages.default.help_center.help_center' | translate }}</span></span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ helpcenterSection?.title }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="articles.length === 0">
      <span [inlineSVG]="'/assets/svg-color/multipage.svg'"></span>
      <h3>{{ 'pages.default.helpcenter_articles.no_articles_yet' | translate }}</h3>
    </div>

    <div class="row" >
      <div class="col-4 mb-2" *ngFor="let article of articles">
        <div class="card h-100">
          <a href="" (click)="goToArticleDetail($event, article)" class="card-cover" [style.background-image]="'url(' + article?.thumbnail?.image?.url + '?width=920&disable=upscale&format=pjpg&auto=webp' + ')'">
            <div class="cover-meta">
              <div class="meta-category" *ngIf="article?.thumbnail?.icon.includes('Video') || article?.thumbnail?.icon.includes('Audio') ">
                <span [inlineSVG]="'/assets/svg/play.svg'" ></span>
              </div>
              <div class="meta-category" *ngIf="article?.thumbnail?.icon.includes('PDF')">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/page.svg'"></span>
              </div>
            </div>
          </a>
          <div class="card-body">
            <h3 class="m-0">{{ article.title }}</h3>
            <p class="m-0">{{ article.description }}</p>
          </div>
          <div class="card-footer">
            <a href="" (click)="goToArticleDetail($event, article)" class="btn btn-secondary m-start-auto">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
