<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.required || errors?.required">{{ 'form.feedback.field_required' | translate }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.backend_errors">{{ ('shared.business_error_keys.' + field?.errors?.message)  | translate }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="errors?.backend_errors">{{ ('shared.business_error_keys.' + errors?.message)  | translate }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.email || errors?.email">{{ 'form.feedback.email_invalid' | translate }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.maxlength || errors?.maxlength">{{ 'form.feedback.maxlength' | translate : {value:meta?.field_value.max_length} }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.minlength || errors?.minlength">{{ 'form.feedback.minlength' | translate : {value:meta?.field_value.min_length} }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.max || errors?.max">{{ 'form.feedback.max' | translate : {value:meta?.field_value.max} }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.min || errors?.min">{{ 'form.feedback.min' | translate : {value:meta?.field_value.min} }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.pattern || errors?.pattern">{{ 'form.feedback.pattern' | translate : {value:meta?.field_value.pattern} }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.checkboxMin || errors?.checkboxMin">{{ 'form.feedback.checkbox_min' | translate : {value:errors?.checkboxMin?.requiredLength ?? field?.errors?.checkboxMin?.requiredLength} }}</p>
<p class="validation-feedback" [ngClass]="extraClass" *ngIf="field?.errors?.checkboxMax || errors?.checkboxMax">{{ 'form.feedback.checkbox_max' | translate : {value:errors?.checkboxMax?.requiredLength ?? field?.errors?.checkboxMax?.requiredLength} }}</p>
