import { Component, OnInit, Input } from '@angular/core';
import { LanguageCode } from '../../models/language-code';
import { GeneralService } from '../../services/general.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-flow-copyrights',
  templateUrl: './flow-copyrights.component.html',
  styleUrls: ['./flow-copyrights.component.scss']
})
export class FlowCopyrightsComponent implements OnInit {
  public thisYear: string;
  public languageOptions: LanguageCode[];
  public selectedLanguage: string;

  @Input() languageSelect: boolean;
  @Input() cookiesButton: boolean = true;


  constructor(
    public languageService: LanguageService,
    public generalService: GeneralService
  ) {
    this.thisYear = new Date().getFullYear().toString();
  }

  ngOnInit() {
    if(this.languageSelect) {
      this.getLanguages();

      this.languageService.onLanguageUpdated.subscribe(() => {
        this.getLanguages();
      });
    }
  }

  getLanguages() {
    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
      this.selectedLanguage = this.languageService.getCurrentLanguageCode().value;
    });
  }

  onLanguageSelection() {
    this.languageService.setCurrentLanguageByEnum(this.selectedLanguage);
  }

  triggerCookies(event) {
    event.preventDefault();
    this.generalService.openCookieSettings();
  }

  goToCookiePolicy(event) {
    event.preventDefault();
    this.generalService.goToCookiePolicy();
  }
}
