<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2>{{ 'modals.add_mfa_backup.add_mfa_backup' | translate }}</h2>

    <div *ngIf="step === 'choice'">
      <div class="custom-control custom-radio mb-2">
        <input type="radio" class="custom-control-input" id="radioUseCurrentNumber" [value]="true" [(ngModel)]="useCurrentNumber">
        <label class="custom-control-label" for="radioUseCurrentNumber">
          {{ 'modals.add_mfa_backup.use_mobile_number_value' | translate:{value: [currentDialCode?.label, profile?.mobile_number?.number].join('') } }}
        </label>
      </div>

      <div class="custom-control custom-radio mb-2">
        <input type="radio" class="custom-control-input" id="radioUseNewNumber" [value]="false" [(ngModel)]="useCurrentNumber">
        <label class="custom-control-label" for="radioUseNewNumber">
          {{ 'modals.add_mfa_backup.use_other_mobile_number' | translate}}
        </label>
      </div>
    </div>
      
    <form [formGroup]="form">
      <div *ngIf="step === 'input'">

        <div class="row">
          <div class="col-5" [ngClass]="{'order-last': languageService.getCurrentLanguageCode().isHebrew}">
            <div class="form-group">
              <app-select
                [placeholder]="('form.labels.country_code' | translate)"
                [clearable]="false"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="dialCodeOptions"
                [bindValue]="'value'"
  
                [autoSortOnLabel]="true"
                [selectedItemsOnTop]="false"
  
                formControlName="country_code"
  
                [asFilter]="false"
                [showCheckboxes]="false"
              ></app-select>
              <app-feedback-field [field]="form.get('country_code')"></app-feedback-field>
            </div>
          </div>
          <div class="col-7" [ngClass]="{'order-first': languageService.getCurrentLanguageCode().isHebrew}">
            <div class="form-group styled floating-label">
              <input type="tel" id="inputPhone" class="form-control w-100"
                [placeholder]="('form.labels.phone_number' | translate)" formControlName="phone">
              <label for="inputPhone">{{ 'form.labels.phone_number' | translate }}</label>
              <app-feedback-field [field]="form.get('phone')"></app-feedback-field>
            </div>
          </div>
        </div>


      </div>

      <div *ngIf="step === 'code'">
        <p>{{ 'modals.add_mfa_backup.enter_code' | translate }}</p>

        <div class="form-group styled floating-label">
          <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.add_mfa_backup.verification_code' | translate)" formControlName="mobile_verification_code" required>
          <label for="inputCode">{{ 'modals.add_mfa_backup.verification_code' | translate }}</label>

          <app-feedback-field [field]="form.get('mobile_verification_code')"></app-feedback-field>

          <p class="mt-1"><a href="" (click)="requestVerificationCode($event, true)">
            <span *ngIf="!isSendingCode">{{ 'modals.add_mfa_backup.resend_code' | translate }}</span>
            <em class="spinner-border spinner-border-sm" *ngIf="isSendingCode"></em>
          </a></p>
        </div>
      </div>
    </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isSaving}" (click)="handleNextStep()" *ngIf="showNextStepBtn">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.next_step' | translate }}</span>
    </button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isSaving}"  (click)="handleConfirm()" *ngIf="showConfirmStepBtn">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
