import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { PhoneValidator } from './../../validators/phone-validator';
import { Patient } from './../../models/patient';
import { LanguageService } from './../../services/language.service';
import { GeneralService } from './../../services/general.service';
import { Profile } from './../../models/profile';
import { LocaleService } from './../../services/locale.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BaseLocaleInterface } from './../../interfaces/base-locale.interface';
import { Image } from '../../models/image';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MobileVerificationCodeModalComponent } from '../../modals/mobile-verification-code-modal/mobile-verification-code-modal.component';
import { MobileNumber } from '../../interfaces/mobile-number.interface';
import { CountryService } from '../../services/country.service';
import { Country } from '../../models/country';
import { DialCode } from '../../models/dial-code';
import { LanguageCode } from '../../models/language-code';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-onboarding-details-form',
  templateUrl: './onboarding-details-form.component.html',
  styleUrls: ['./onboarding-details-form.component.scss']
})
export class OnboardingDetailsFormComponent implements OnInit {
  @Input() validationVisible: boolean;
  @Input() contactConsented: boolean;
  @Input() mode: string;
  @Input() code: string;
  @Input() language: string;

  @Input('data')
  public set data(data: {
    locale: BaseLocaleInterface,
    patient?: Patient,
    profile?: Profile,
  }) {
    if (data) {
      this.formSetup();

      const bsDatePickerInputFormat: string = this.localeService.getBsDatePickerInputFormat(data.locale);
      this.bsDatepickerConfig.dateInputFormat = bsDatePickerInputFormat;
      this.bsDatepickerConfig.adaptivePosition = true;


      if (data.patient) {
        this.addPatient(data.patient);
      }

      if (data.profile) {
        this.addProfile(data.profile);
      }
    }
  }

  @Output() onFormInitialized: EventEmitter<UntypedFormGroup> = new EventEmitter();

  public countryOptions: Country[];
  public dialCodeOptions: DialCode[];
  public languageOptions: LanguageCode[];

  public currentPatient: Patient;
  public dateFormatMomentJs: string;

  public form: UntypedFormGroup;
  public profilePicturePreview: Image;
  public areNotificationsVisible: boolean;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public languageService: LanguageService,
    public generalService: GeneralService,
    public localeService: LocaleService,
    public bsDatepickerConfig: BsDatepickerConfig,
    public modalService: BsModalService,
    public countryService: CountryService
  ) {

  }

  ngOnInit() {
    this.formSetup();
    this.dialCodeOptions = this.generalService.dialCodes;
    this.countryService.getCountriesFromCms().subscribe((countries: Country[]) => this.onCountriesSuccess(countries));
  }

  onCountriesSuccess(countries: Country[]) {
    this.countryOptions = countries;
  }

  formSetup() {
    if (this.form) {
      return;
    }

    const language = this.languageService.getCurrentLanguage();

    this.form = new UntypedFormGroup({
      gender: new UntypedFormControl('', [Validators.required]),
      first_name: new UntypedFormControl('', [Validators.required]),
      preferred_name: new UntypedFormControl(''),
      last_name: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      language: new UntypedFormControl({value: language, disabled: true}, [Validators.required]),
      date_of_birth: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone_number: new UntypedFormGroup({
        code: new UntypedFormControl(''),
        number: new UntypedFormControl('', [PhoneValidator.validate()]),
      }),
      contact_channel: new UntypedFormControl(''),
      profile_picture: new UntypedFormControl(''),
      notifications_method_email: new UntypedFormControl(''),
      notifications_method_text: new UntypedFormControl(''),
      mobile_verification_code: new UntypedFormControl('')
    });

    this.form.get('phone_number.code').valueChanges.subscribe(() => this.phoneValueChanged());

    this.form.get('phone_number.number').valueChanges.subscribe(() => this.phoneValueChanged());
    this.phoneValueChanged();

    if (this.mode === 'onboarding') {
      this.form.disable();
    }

    if (this.mode === 'self-onboarding') {
      this.form.get('contact_channel').setValue('EMAIL');
    }

    this.onFormInitialized.emit(this.form);
  }

  addPatient(patient: Patient) {
    this.form.enable();
    this.currentPatient = patient;

    let phoneNumber = '';
    let phoneCode = '';

    if (patient.phone_number) {
      if (patient.phone_number.number) {
        phoneNumber = patient.phone_number.number;
      }

      if (patient.phone_number.code) {
        phoneCode = patient.phone_number.code;
      }
    }


    this.form.get('language').disable();

    ['gender', 'first_name', 'last_name', 'email'].forEach(elName => {
      this.form.get(elName).setValue(patient[elName]);
      this.form.get(elName).disable();
    });
    this.form.get('preferred_name').setValue(patient.preferred_name);
    this.form.get('country').setValue(patient.country);
    this.form.get('phone_number.number').setValue(phoneNumber);
    this.form.get('phone_number.code').setValue(phoneCode);
    this.form.get('date_of_birth').setValue(patient.getBirthDate());
    this.form.get('date_of_birth').disable();
    this.form.get('profile_picture').setValue(patient.profile_picture);

    this.phoneValueChanged();
  }

  phoneValueChanged() {
    if(this.isPhoneComplete()) {
      this.form.get('notifications_method_text').enable();
    } else {
      // disable notifications for sms if no phone or code
      this.form.get('notifications_method_text').setValue(false);
      this.form.get('notifications_method_text').disable();

      if(this.form.get('contact_channel').value === 'PHONE') {
        this.form.get('contact_channel').setValue('EMAIL');
      }
    }
  }

  isPhoneComplete(): boolean {
    const phoneControl = this.form.get('phone_number.number');
    const countryCodeControl = this.form.get('phone_number.code');

    if (!phoneControl.value || !countryCodeControl.value) {
      return false;
    } else {
      return phoneControl.valid && countryCodeControl.valid;
    }
  }

  smsNotificationChanged(event) {
    const checked = this.form.get('notifications_method_text').value;
    if (checked) {
      this.openMobileVerificationCodeModal();
    } else {
      this.form.get('mobile_verification_code').reset();
    }
  }

  openMobileVerificationCodeModal() {
    const mobileNumber: MobileNumber = {
      code: this.form.get('phone_number.code').value,
      number: this.form.get('phone_number.number').value
    };

    const initialState = {
      mobileNumber,
      code: this.code,
      language: this.language,
      mode: this.mode
    };

    const modalRef = this.modalService.show(MobileVerificationCodeModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalRef.content?.verificationCodeReceived.subscribe(moVeCo => {
      this.form.get('mobile_verification_code').setValue(moVeCo);
    });

    const onHideSubscription:Subscription = this.modalService.onHide.subscribe(args => {

      const moVeCo = this.form.get('mobile_verification_code').value;

      if(!moVeCo){
        this.form.get('notifications_method_text').reset();
      }

      onHideSubscription.unsubscribe();
    });
  }

  addProfile(profile: Profile) {
    if (profile.contact_consented && this.mode !== 'self-onboarding') {
      this.contactConsented = profile.contact_consented;
      this.form.get('contact_channel').setValidators([Validators.required]);
      this.form.get('contact_channel').setValue('EMAIL');
    }



    if (profile.locale && profile.locale.notification_channels) {
      if (profile.locale.notification_channels.includes('EMAIL')) {
        this.form.get('notifications_method_email').setValue(true);
      }

      if (profile.locale.notification_channels.includes('TEXT')) {
        this.form.get('notifications_method_text').setValue(true);
      }
    }
  }

  isLocaleHebrew(): boolean {
    return this.languageService.getCurrentLanguageCode().isHebrew;
  }
}
