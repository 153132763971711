import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { LanguageService } from '../services/language.service';
import { LocaleService } from '../services/locale.service';


@Pipe({name: 'timeZoneDate', pure: false})
export class TimeZoneDatePipe implements PipeTransform {
  constructor(
    private readonly localeService: LocaleService,
    private readonly languageService: LanguageService
  ) {}

  transform(date: string, format?): string {
    const timeZone = this.localeService.getLocalePreferences().locale.time_zone;
    
    let dateFormat = this.localeService.getLocalePreferences().dateFormat.format;
    if (format) {
      dateFormat = format;
    }

    if (!dateFormat) {
      return;
    }

    let mDate = moment(date);
    if (!mDate.isValid()) {
     mDate = moment(new Date(date)); 
    }

    
    dateFormat = this.localeService.transformFormatToMomentFormat(dateFormat);
    let currentLocale = this.languageService.getCurrentLanguageCode().locale;

    if(currentLocale === 'ar') {
      switch(dateFormat) {
        case "MMM DD, YYYY": {
          dateFormat = "MMM D, YYYY"; // Moment is reversing this date format
          break;
        }

        case "MMMM DD, YYYY": {
          dateFormat = "MMM D, YYYY"; // Moment is reversing this date format
          break;
        }

        case "DD/MM/YYYY": {
          dateFormat = "YYYY/M/D";
          break;
        }

        case "DD MMM YYYY": {
          dateFormat = "D MMM YYYY"; // Moment is reversing this date format
          break;
        }
      }
    }

    if (timeZone) {
      return mDate.tz(timeZone).format(dateFormat);
    } else {
      return mDate.format(dateFormat);
    }
  }
}
