<div class="layout-header-pusher header-patient-meta"></div>

<div class="layout-header header-patient-meta">
  <div class="header-content">
    <div class="container">
      <p>
        <a href="" (click)="goBack($event)"  class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container mb-1">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1 *ngIf="queryList">{{ queryList?.title | translateHelp | translate }}</h1>
        </div>
        <div class="col col-auto" *ngIf="!isReadOnly">
          <button class="btn btn-sm btn-muted m-start-1" id="query-list-save" [ngClass]="{'loader': isSaving}" (click)="saveUserTask()">
            <span class="loader text-muted"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
            <span>{{ 'action.save' | translate }}</span>
          </button>
          <button class="btn btn-sm btn-secondary m-start-1" id="query-list-submit" [ngClass]="{'loader': isSubmitting}" (click)="confirmSubmitUserTask()">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></em></span>
            <span>{{ 'action.submit' | translate }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="user-compact">
        <app-avatar [profilePicture]="queryList?.assignee?.profilePicture"></app-avatar>

        <div class="user-compact-info">
          <p class="title text-truncate">{{ queryList?.assignee?.getFullName() }}</p>
          <p *ngIf="queryList?.assignee?.uid === patientUid">{{ 'pages.default.query_lists.you' | translate }}</p>
          <p>{{ queryList?.assignee?.job_title }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="inline-page-notification" *ngIf="queryList?.accessible_by_patient_mdt">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'/assets/svg/medical-professional.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.query_lists.accessible_by_patient_mdt.title' | translate }}</p>
        <p>{{ 'pages.default.query_lists.accessible_by_patient_mdt.description' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="inline-page-notification muted" *ngIf="queryList?.isCompleted">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'/assets/svg/edit-disabled.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.query_lists.no_update.title' | translate }}</p>
        <p>{{ 'pages.default.query_lists.completed_description' | translate }} {{ queryList?.completed_at | timeZoneDate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="inline-page-notification muted" *ngIf="queryList?.isTimedOut">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'/assets/svg/edit-disabled.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.query_lists.no_update.title' | translate }}</p>
        <p>{{ 'pages.default.query_lists.timed_out_description' | translate : {value:queryList?.due_days_ago?.toString()}  }}</p>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="!task">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="task">
    <p *ngIf="queryList?.description" class="small">{{ queryList?.description | translateHelp | translate }}</p>

    <app-user-task-form [userTask]="task" [isReadOnly]="isReadOnly" [validationVisible]="validationVisible" [(form)]="form"></app-user-task-form>

    <p class="small" *ngIf="queryList?.isSaved">
      {{ 'pages.default.query_lists.edited_by' | translate : {value:editedBy()  } }} {{ queryList?.changed_at | timeZoneDate }} {{ queryList?.changed_at | time }}
    </p>

    <p class="small" *ngIf="queryList?.isCompleted">
      {{ 'pages.default.query_lists.submitted_by' | translate : {value:editedBy()  } }} {{ queryList?.completed_at | timeZoneDate }} {{ queryList?.completed_at | time }}
    </p>

    <p class="small" *ngIf="queryList?.isTimedOut">
      {{ 'pages.default.query_lists.time_out_at' | translate : {value:queryList?.timedout_at | timeZoneDate} }}
    </p>
  </div>
</div>
