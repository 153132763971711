<div class="redirect-layout">
  <div class="layout-header with-background">
    <div class="container">
      <div class="text-center pb-3 pt-4">
        <span class="icon icon-lg rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg-color/check.svg'"></span>
        <h2 class="text-white mb-0 mt-2">{{ 'pages.onboarding.redirect_to_app.account_created' | translate }}</h2>
      </div>
    </div>
  </div>

  <div class="layout-body">
    <div class="content-part">
      <div class="container">
        <p>{{ 'pages.onboarding.redirect_to_app.start_using_app' | translate }}</p>

        <h3>{{ 'pages.onboarding.redirect_to_app.download_app' | translate }}</h3>
        <button class="btn p-0" (click)="redirectToStore()">
          <span class="icon store" [inlineSVG]="storeUrl"></span>
        </button>

        <hr />

        <h3>{{ 'pages.onboarding.redirect_to_app.did_you_know' | translate }}</h3>
        <p>
          {{ 'pages.onboarding.redirect_to_app.visit_web_portal_at' | translate }} <strong>{{
            'pages.onboarding.redirect_to_app.portal_link' | translate }}</strong> {{
          'pages.onboarding.redirect_to_app.and_login' | translate }}
        </p>
        <p class="text-muted">
          {{ 'pages.onboarding.redirect_to_app.ensure_best_experience' | translate }}
        </p>


        <div class="footer-part mt-8">
          <app-flow-copyrights></app-flow-copyrights>
        </div>

      </div>
    </div>
  </div>
</div>
