import { Pipe, PipeTransform, NgModule } from '@angular/core';


@Pipe({
  name: 'bytes',
})
export class BytesPipe implements PipeTransform {
  transform(value: number): any {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (value === 0) return '0 Byte';

    let i = Math.floor((Math.log(value) / Math.log(1024)));
    return Math.round(value / Math.pow(1024, i)) + ' ' + sizes[i];
  }

}