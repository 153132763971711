<div class="banner-info-modal">
  <div class="modal-header align-items-start">
    <h2 class="mb-0">{{ title }}</h2>

    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>
  </div>

  <div class="modal-body">
    <div [innerHtml]="description" class="description"></div>
  </div>

  <div class="modal-footer no-shadow">
    <div class="m-start-auto">
      <button class="btn btn-link m-start-3" id="more-info-banner-modal-i-understand" *ngIf="hasMoreInfo" (click)="showMoreInfoModal()">
        {{ 'action.read_more' | translate }}
      </button>

      <button class="btn btn-secondary m-start-3" id="more-info-banner-modal-i-understand" (click)="confirm()">
        {{ 'action.i_understand' | translate }}
      </button>
    </div>
  </div>
</div>
