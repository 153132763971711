import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { PageTabItem } from '../../../models/page-tab-item';
import { PhaseInstance } from '../../../models/phase-instance';
import { DataService } from '../../../services/data.service';
import { GeneralService } from '../../../services/general.service';
import { MaterialPhase } from '../../../models/material-phase';
import { MaterialService } from '../../../services/material.service';
import { PatientService } from '../../../services/patient.service';

@Component({
  selector: 'app-patient-materials',
  templateUrl: './patient-materials.component.html',
  styleUrls: ['./patient-materials.component.scss']
})
export class PatientMaterialsComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;

  public pageTabItems: PageTabItem[] = [];

  public learningMaterials: any[];
  public isLoadingMaterials: boolean;

  public selectedPhase: MaterialPhase;
  public selectedPhaseId: string;

  public phases: any[];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public materialService: MaterialService,
    public patientService: PatientService,
    public generalService: GeneralService
  ) { }

  ngOnInit() {
    this.pageTabItems = [];

    this.route.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      this.route.queryParams.subscribe(queryparams => {
        if (queryparams?.phase) {
          this.selectedPhaseId = queryparams?.phase;
        }
      });

      this.loadMaterials();
    });

  }

  loadMaterials() {
    this.isLoadingMaterials = true;
    this.pageTabItems = [new PageTabItem('', null)];

    forkJoin([
      this.patientService.getPathwayPhases(this.patientPathwayUid),
      this.materialService.getTranslatedMaterialsMappedToMainPhase(this.patientUid, this.patientPathwayUid)
    ]).subscribe(result => {
      const pathwayPhases: PhaseInstance[] = result[0].phases;
      const materialPhases: MaterialPhase[] = result[1];

      this.createTabs(pathwayPhases, materialPhases);

      this.isLoadingMaterials = false;
    }, () => {
      this.isLoadingMaterials = false;
    });
  }

  getCurrentPhaseId(pathwayPhases: PhaseInstance[]): string {
    let currentPhaseId: string;
    let currentPhase: PhaseInstance;

    if (this.selectedPhaseId) {
      currentPhase = pathwayPhases.find((phase: PhaseInstance) => phase.id === this.selectedPhaseId);

      if (currentPhaseId) {
        return currentPhase.id;
      } else {
        // No match found in main phases, check level 1 subphases(possible coming from timeline)
        for (let i = 0; i < pathwayPhases.length; i++) {
          const pwSubPhases = pathwayPhases[i]?.sub_phase_instances;

          if (pwSubPhases?.length) {
            for (let j = 0; j < pwSubPhases.length; j++) {
              if (pwSubPhases[j].id === this.selectedPhaseId) {
                return pathwayPhases[i].id
              }
            }
          }
        }
      }
    } else {
      return pathwayPhases.find((phase: PhaseInstance) => phase.isCurrent && !phase.isSubPhase)?.id;
    }
  }

  createTabs(pathwayPhases: PhaseInstance[], materialPhases: MaterialPhase[]) {
    if (!pathwayPhases?.length || !materialPhases?.length || !materialPhases) {
      return;
    }

    const currentPhaseId = this.getCurrentPhaseId(pathwayPhases);
    const _pageTabItems = [];
    let currentPhasePageTabItem: PageTabItem;

    materialPhases.forEach((phase: MaterialPhase) => {
      const tabItem = new PageTabItem(phase.translationKey, null, phase);
      _pageTabItems.push(tabItem);

      if (this.dataService.get('selectedPhase') && this.dataService.get('selectedPhase').phase_id === phase.phase_id) {
        currentPhasePageTabItem = tabItem;
      } else if (phase.phase_id === currentPhaseId && !this.dataService.get('selectedPhase')) {
        currentPhasePageTabItem = tabItem;
      } else if (this.selectedPhaseId === phase.phase_id) {
        currentPhasePageTabItem = tabItem;
      }
    });

    this.pageTabItems = _pageTabItems;

    if (currentPhasePageTabItem) {
      setTimeout(() => {
        this.generalService.scrollToPageTabItem(currentPhasePageTabItem, false);
      });

      this.onTabChangeHandler(currentPhasePageTabItem);
    } else {
      this.onTabChangeHandler(_pageTabItems[0]);
    }
  }

  onTabChangeHandler(pageTabItem: PageTabItem) {
    this.selectedPhase = pageTabItem.data;
    this.dataService.set(DataService.KeyLearningPhaseSelected, pageTabItem.data);
  }

  openMaterial(event, material?: any) {
    event.preventDefault();

    this.router.navigateByUrl(`/patient/${this.patientUid}/${this.patientPathwayUid}/learning-materials/article/${material.uid}`);
  }
}
