import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';


@Pipe({ name: 'videosafe' })
export class VideoSafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(videoId) {
    const url = `https://players.brightcove.net/${environment.brightcove_acc_id}/default_default/index.html?videoId=${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
