import { Image } from "./image";

export class Participant {
  public uid: string;
  public first_name: string;
  public last_name: string;
  public profile_picture: Image;
  public removed: boolean;
  public type: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid  = item.uid ? item.uid : '';
    this.first_name = item.first_name ? item.first_name : '';
    this.last_name = item.last_name ? item.last_name : '';
    this.removed = item.removed ? item.removed : '';
    this.type = item.type;

    if(item.profile_picture) {
      this.profile_picture  = new Image(item.profile_picture);
    }
  }

  public getFullName() {
    return [this.first_name, this.last_name].join(' ');
  }
}
