import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AttentionCenter } from '../models/attention-center';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { PatientService } from './patient.service';

@Injectable({
  providedIn: 'root'
})
export class AttentionCenterService extends ApiService {
  public ac: AttentionCenter;

  @Output() onNewConversationMessagesUnread: EventEmitter<any> = new EventEmitter();
  @Output() onNewAppointmentsPending: EventEmitter<any> = new EventEmitter();

  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService,
    public patientservice: PatientService

  ) {
    super(http, authenticationService);
  }

  get(): Observable<AttentionCenter> {
    return new Observable(observer => {
      const paramBits = [];

      const paramsString = paramBits.join('&');
      const url = `${environment.platformUrl}/user/attention-center?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {

        /*
        ** First time?
        */
        if(!this.ac) {
          this.ac = new AttentionCenter(this.findHcp(result));
        /*
        ** In case of updating
        */
        } else {
          const original: AttentionCenter = Object.assign({}, this.ac) as AttentionCenter;
          this.ac.fillFromJson(this.findHcp(result));

          //
          // Evaluate the conversationMessagesUnread
          //
          if(this.ac.conversationMessagesUnread > original.conversationMessagesUnread) {
            this.onNewConversationMessagesUnread.emit();
          }

          //
          // Evaluate the appointmentsPending
          //
          if(this.ac.appointmentsPending > original.appointmentsPending) {
            this.onNewAppointmentsPending.emit();
          }
        }

        observer.next(this.ac);
      });
    });
  }

  refresh() {
    this.get().subscribe(() => {});
  }

  findHcp(result):any {
    const hospitalUid = this.patientservice.getCurrentStoredHospitalUid();
    const patientUid = this.patientservice.getCurrentStoredPatientUid();
    const patients = result['patients'] || [];

    return patients.find(_patient => {
      return (_patient.patient_uid === patientUid && _patient.hospital_uid === hospitalUid);
    });
  }
}
