import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';
import {AuthenticationService} from './authentication.service';
import {UserProfileLocale} from '../models/user-profile-locale';
import {LocaleService} from './locale.service';
import {UserService} from '../../app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public localeService: LocaleService,
    public userService: UserService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  loginWithCredentials(username, password): Observable<any> {
    const params = {
      username,
      password,
      grant_type: 'password'
    };
    return this.login(params);
  }

  loginWithCode(code): Observable<any> {
    const params = {
      code,
      grant_type: 'authorization_code'
    };
    return this.login(params);
  }

  refreshToken(): Observable<any> {
    return new Observable(observer => {
      const refresh_token = this.authenticationService.getRefreshToken();
      const url = this.platformUrl + '/oauth/token';
      const params = {
        refresh_token,
        grant_type: 'refresh_token'
      };

      this.basicAuthPost(url, params).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  private login(params): Observable<any> {
    return new Observable(observer => {
      const url = this.platformUrl + '/oauth/token';

      this.basicAuthPost(url, params).subscribe(result => {
        this.syncOauthLocaleWhenPossible().subscribe(() => {
          observer.next(result);
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  syncOauthLocaleWhenPossible(): Observable<void | UserProfileLocale> {
    if (!this.authenticationService.hasFinalRole()) {
      return this.getOauthLocale();
    } else {
      return new Observable(observer => {
        observer.next();
        observer.complete();
      });
    }
  }

  getOauthLocale(): Observable<UserProfileLocale> {
    return new Observable(observer => {
      const url = this.platformUrl + '/oauth/locale';
      this.authenticatedGet(url).subscribe(result => {
        const locale = new UserProfileLocale(result);
        this.localeService.setLocalePreferences(locale);
        observer.next(locale);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }
}
