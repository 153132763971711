<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/settings/consents" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          <span>{{ 'pages.default.settings.consent_detail.back_to_consents' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ consent?.title }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <!-- The page loader ...
    <app-page-loader></app-page-loader>
    -->

    <div class="text-content-max">
      <h2 class="text-info mb-5">{{ consent?.introduction }}</h2>
      <div [innerHtml]="consent?.body"></div>
    </div>
  </div>
</div>

<div class="layout-footer-pusher"></div>
<div class="layout-footer">
  <div class="footer-content">
    <div class="container">
      <div class="m-start-auto">


        <button *ngIf="consent?.isConsented()" class="btn btn-sm btn-secondary m-start-3" (click)="doDeconsent()" [ngClass]="{'loader': isPerformingAction}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isPerformingAction"></em></span>
          <span>{{ 'action.i_do_not_consent' | translate }}</span>
        </button>
        <button *ngIf="!consent?.isConsented()"  class="btn btn-sm btn-secondary m-start-3" (click)="doConsent()" [ngClass]="{'loader': isPerformingAction}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isPerformingAction"></em></span>
          <span>{{ 'action.i_consent' | translate }}</span>
        </button>

      </div>
    </div>
  </div>
</div>
