<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2 class="mb-3">{{ 'modals.start_new_convo.start_new_convo' | translate }}</h2>

  <div *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div *ngIf="!isLoading">

    <form *ngIf="form" [formGroup]="form" id="form" [ngClass]="{'validation-visible': validationVisible}">
      <p class="validation-feedback general-feedback" *ngIf="!isModalValid()">{{
        'modals.start_new_convo.complete_all_fields' | translate }}</p>

      <div class="form-group required" *ngIf="pathways?.length>1">
        <app-select
        [placeholder]="('modals.start_new_convo.treatment' | translate)"
        [clearable]="false"
        [searchable]="false"
        [hideSelected]="false"
        [multiple]="false"
        [items]="pathways"
        formControlName="pathway"

        (change)="loadPossibleParticipants()"
        [asFilter]="false"
        [showCheckboxes]="false">
        </app-select>

        <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
      </div>

      <div class="form-group styled floating-label required">
        <input type="text" id="inputSubject" class="form-control w-100"
          [placeholder]="('modals.start_new_convo.subject' | translate)" formControlName="subject"
          maxlength="{{subjectMaxLength}}" required>
        <label for="inputSubject" class="required">{{ 'modals.start_new_convo.subject' | translate }}</label>
        <p class="small text-muted text-align-end m-0">{{form?.controls?.subject?.value?.length}}/{{subjectMaxLength}}
        </p>

        <app-feedback-field [field]="form.get('subject')"></app-feedback-field>
      </div>

      <div *ngIf="form?.controls?.pathway?.value">
        <p class="small text-muted text-align-start mb-1">{{ 'modals.start_new_convo.participants' | translate }}</p>

        <div class="text-center text-muted py-3" *ngIf="isLoadingParticipants">
          <em class="spinner-border spinner-border-sm"></em>
        </div>

        <div class="mb-3" *ngIf="!isLoadingParticipants">
          <p class="small text-align-start mb-1">{{ 'modals.start_new_convo.team' | translate }}</p>

          <div class="participant-item clickable" (click)="selectTeam()" [class.selected]="all_hcps"
            [ngClass]="(!isParticipantChosen() && validationVisible)? 'error' : ''">
            <div class="avatar">
              <span class="icon" [inlineSVG]="'/assets/svg/community.svg'"></span>
            </div>
            <div class="meta">
              <p class="title">{{ 'modals.start_new_convo.my_team' | translate }}</p>
              <p class="description">{{ getAllParticipantNames() }}</p>
            </div>
            <div class="checkbox">
              <span class="icon" [inlineSVG]="'/assets/svg/check.svg'" *ngIf="!all_hcps"></span>
              <span class="icon" [inlineSVG]="'/assets/svg/check-selected.svg'" *ngIf="all_hcps"></span>
            </div>
          </div>
        </div>

        <div class="mb-3" *ngIf="!isLoadingParticipants">
          <p class="small text-align-start mb-1">{{ 'modals.start_new_convo.people' | translate }}</p>

          <div class="participant-item clickable" *ngFor="let p of participants" (click)="setSelectedParticipant(p)"
            [class.selected]="p === selectedParticipant"
            [ngClass]="(!isParticipantChosen() && validationVisible)? 'error' : ''">
            <app-avatar [profilePicture]="p?.profile_picture"></app-avatar>

            <div class="meta">
              <p class="title">{{ p?.getFullName() }}</p>
              <p class="description">{{ p?.job_title }}</p>
            </div>
            <div class="checkbox">
              <span class="icon" [inlineSVG]="'/assets/svg/check.svg'" *ngIf="p !== selectedParticipant"></span>
              <span class="icon" [inlineSVG]="'/assets/svg/check-selected.svg'"
                *ngIf="p === selectedParticipant"></span>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>

</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isCreating}" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isCreating"></em></span>
      <span>{{ 'modals.start_new_convo.start_convo' | translate }}</span>
    </button>
  </div>
</div>
