import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';
import {PatientService} from './patient.service';
import {Conversation} from '../models/conversation';
import {Participant} from '../models/participant';
import { Attachment } from '../models/attachment';


@Injectable({
  providedIn: 'root'
})
export class ConversationService extends ApiService {
  private readonly platformUrl: string;
  private readonly environment: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public patientService: PatientService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getConversations(page = 0, size = 30): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations?page=${page}&size=${size}`;

      this.authenticatedGet(url).subscribe(result => {
        const participants = this.mapParticipants(result);
        const conversations = this.mapConversations(result, participants);

        observer.next({conversations, pagination: result.pagination});
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getConversation(uid: string): Observable<Conversation> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${uid}`;

      this.authenticatedGet(url).subscribe(result => {
        const conversation = this.mapConversation(result);
        observer.next(conversation);
        observer.complete();
      });
    });
  }

  getConversationEntries(conversation: Conversation, page = 0, size = 10): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversation.uid}/entries?page=${page}&size=${size}`;

      this.authenticatedGet(url, 'v2').subscribe(result => {
        const filledConversation = this.mapConversationEntries(result, conversation, page);
        observer.next({conversation: filledConversation, pagination: result.pagination});
        observer.complete();
      });
    });
  }

  addConversationEntry(conversationUid: string, message: string, attachment?: Attachment): Observable<any> {
    return new Observable(observer => {
      if(!message || !message.length) {
        message = null;
      }

      if(attachment && attachment.uid) {
        if(attachment.isAudio) {
          this.postConversationAudio(conversationUid, message, attachment, observer);
        } else {
          this.postConversationFile(conversationUid, message, attachment, observer);
        }

      } else {
        this.postConversationEntry(conversationUid, message, observer);
      }
    });
  }

  loadAudioDetails(file): Observable<any> {
    return new Observable(observer => {
      var audio: any = document.createElement('audio');
      var reader = new FileReader();

      reader.onload = function (e) {
        audio.src = e.target.result;
        audio.addEventListener('loadedmetadata', function(){
            observer.next({
              duration: audio.duration
            });
            observer.complete();
        },false);
      };

      reader.readAsDataURL(file);
    });
  }

  postConversationEntry(conversationUid: string, message: string, observer) {
    const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversationUid}/entries`;

    let input: any = {
      message_type: 'TEXT_MESSAGE',
      message: message
    }

    this.authenticatedPost(url, input, 'v2').subscribe(result => {
      observer.next(result);
      observer.complete();
    });
  }

  postConversationFile(conversationUid: string, message: string, attachment: Attachment, observer) {
    const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversationUid}/entries`;

    let input: any = {
      message_type: 'FILE_MESSAGE',
      file_uid: attachment.uid,
      message: message
    }

    this.authenticatedPost(url, input, 'v2').subscribe(result => {
      observer.next(result);
      observer.complete();
    });
  }

  postConversationAudio(conversationUid: string, message: string, attachment: Attachment, observer) {
    const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversationUid}/entries`;

    let input: any = {
      message_type: 'AUDIO_MESSAGE',
      audio_file_uid: attachment.uid
    }

    this.loadAudioDetails(attachment.file).subscribe(audioDetails => {
      input.audio_length = Number(audioDetails.duration);

      this.authenticatedPost(url, input, 'v2').subscribe(audioPostResult => {
        if(message) {
          this.postConversationEntry(conversationUid, message, observer);
        } else {
          observer.next(audioPostResult);
          observer.complete();
        }
      });
    });
  }

  deleteConversationEntry(conversation_uid, entry_id): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversation_uid}/entries/${entry_id}`;
      this.authenticatedDelete(url).subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  mapConversations(result: { items: any[] }, participants): Array<Conversation> {
    const conversations = [];
    for (const item of result.items) {
      const conversation = this.mapConversation(item);
      conversation.setParticipants(participants);
      conversations.push(conversation);
    }
    return conversations;
  }

  mapConversation(item): Conversation {
    return new Conversation(item);
  }

  mapConversationEntries(result, conversation: Conversation, page): Conversation {
    conversation.participants = this.mapParticipants(result);
    conversation.addItems(result.items, page);
    return conversation;
  }

  mapParticipants(result: { participants_details: any[] }): Array<Participant> {
    const participants = [];
    for (const item of result.participants_details) {
      const participant = this.mapParticipant(item);
      participants.push(participant);
    }
    return participants;
  }

  mapParticipant(item): Participant {
    return new Participant(item);
  }

  getDownloadLink(conversationUid: string, entryId: string): Observable<string>{
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversationUid}/entries/${entryId}/download-link`;
      this.authenticatedGet(url).subscribe(result => {
        observer.next(result?.download_link);
        observer.complete();
      });
    });
  }

  addConversation(newConversationData: any): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations`;

      this.authenticatedPost(url, newConversationData).subscribe(result => {
        observer.next(this.mapConversation(result));
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  editConversationSubject(conversationId, subject: string): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversationId}`;
      const data = {subject};

      this.authenticatedPut(url, data).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Attachment
  postAttachment(conversation_id: string, file) {
    const url = `${this.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/conversations/${conversation_id}/files`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return new Observable(observer => {
      this.authenticatedPost(url, formData, null, { 'content-type': 'multipart/form' }).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }
}
