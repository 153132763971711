<div class="heading-block mb-3 help-center-contact" *ngIf="!compact">
  <div class="block-wrapper overflow-hidden py-2">
    <h2 class="text-truncate">{{ helpcenterSection?.title }}</h2>
    <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
  </div>
</div>

<div class="row mb-4">
  <div *ngFor="let contact of helpcenterSection?.contacts; last as last" [ngClass]="compact ? 'col-12' : 'col-4'">
    <div class="card h-100">
      <div class="card-body">
        <img [src]="contact?.icon" class="img-icon-md mb-2">
        <h3 class="mb-1">{{contact?.title}}</h3>
        <p class="m-0">{{ contact?.description }}</p>

        <div *ngIf="contact.isPhone">
          <div class="btn-group text-dropdown mb-1" dropdown>
            <button id="dropdown-btn-countries" dropdownToggle type="button"
              class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
              <span class="m-end-1">{{ currentCountry?.title }}</span>
              <span class="icon" [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
            </button>

            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu"
              aria-labelledby="dropdown-btn-countries">
              <li *ngFor="let country of countries" role="menuitem">
                <a class="dropdown-item" href="" (click)="changeCountry($event, country)">{{country?.title}}</a>
              </li>
            </ul>
          </div>

          <p [ngClass]="{'mb-0': !currentCountry?.description}">{{ currentCountry?.description }}</p>

          <ul>
            <li *ngFor="let phone of currentCountry?.phones">
              <p class="mb-0">
                <span *ngIf="phone.description">{{phone?.description}}: </span>
                <span class="font-weight-bold">{{ phone?.number}}</span>
              </p>
              <p class="text-muted mb-0">{{ phone?.disclaimer }}</p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card-footer" *ngIf="contact.hyperlink && !compact">
        <a href="{{ contact.hyperlink }}" class="btn btn-secondary m-start-auto"
          target="{{(contact.isChat)? '_blank' : '_self'}}" rel="noopener noreferrer">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </a>
      </div>

      <div class="card-footer border-0" *ngIf="contact.hyperlink && compact">
        <a href="{{ contact.hyperlink }}" class="btn btn-link p-start-0"
          target="{{(contact.isChat)? '_blank' : '_self'}}" rel="noopener noreferrer">
          <span>{{ ((contact.isChat)? 'pages.default.help_center.go_to_chat' : 'pages.default.help_center.send_email') | translate }}</span>
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </a>
      </div>

      <hr *ngIf="!last && compact" />
    </div>
  </div>
</div>
