import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Faq } from '../../models/faq';
import { FaqCategory } from '../../models/faq-category';
import { HelpcenterSection } from '../../models/helpcenter-section';

@Component({
  selector: 'app-faq-block',
  templateUrl: './faq-block.component.html'
})
export class FaqBlockComponent {
  @Input() category: FaqCategory;
  @Input() section: HelpcenterSection;

  @Output() goToFaq = new EventEmitter<{ faq: Faq, category: FaqCategory, section: HelpcenterSection }>();

  onGoToFaq(faq, category, section) {
    this.goToFaq.emit({
      faq,
      category,
      section
    });
  }
}
