import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error.service';
import { AuthenticationService } from '../services/authentication.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public authenticationService: AuthenticationService,
    public errorService: ErrorService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (window.location.pathname.indexOf('maintenance') > -1) {
            return;
          }

          if (! (error.error instanceof ErrorEvent)) {
            if (Number(error.status) === 503) {
              const key = error.error.key;
              if (key === 'ERROR_PLATFORM_APPLICATION_INACTIVE' || key ===  'ERROR_PLATFORM_APPLICATION_VERSION_TOO_LOW') {
                this.errorService.showPlatformInactiveError(key);
              }
            } else if (Number(error.status) >= 500 || Number(error.status) === 0) {
              this.errorService.showGeneralBackendErrorToast();
            }

            if (Number(error.status) === 400) {
              this.errorService.showBackendErrorToastByKey(error.error.errors[0].key);
            }

            if (Number(error.status) === 404) {
              this.errorService.showGeneralBackendErrorToast();
            }

            if (Number(error.status) === 423) { // account locked
              this.errorService.showBackendErrorToastByKey(error.error.key);
              this.authenticationService.logout();
            }

            if (Number(error.status) === 440) {
              this.errorService.showSessionTimeOutErrorToast();
              this.authenticationService.logout();
            }
          }

          return throwError(error);
        })
      );
  }
}
