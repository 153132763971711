<div class="default-layout" [ngClass]="{'open': sidebarOpen}">
  <div class="default-layout-backdrop" [ngClass]="{'visible': backdropVisible, 'shown': backdropShown}"
    (click)="closeSidebarHandler($event);"></div>
  <div class="layout-sidebar">
    <div class="sidebar-content is-flexed">
      <div class="content-part mb-0 pb-0">

        <div class="sidebar-toggle">
          <a href="" class="toggle-closed" [inlineSVG]="'/assets/svg/hamburger.svg'"
            (click)="openSidebarHandler($event);"></a>

          <a href="" class="toggle-open scale-up-2" (click)="closeSidebarHandler($event);">
            <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
            <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
          </a>

          <div class="logo" [inlineSVG]="'/assets/svg/logo-patient.svg'"></div>
        </div>
      </div>

      <div class="content-part">
        <app-sidebar-banner></app-sidebar-banner>
      </div>

      <div class="content-part">
        <p class="sidebar-title sidebar-open-element-block">{{ 'layouts.default.nav.title_patient' | translate }}</p>

        <div class="action-bar mb-1">
          <app-avatar [profilePicture]="currentPatient?.profile_picture"></app-avatar>

          <div class="input-wrapper">
            <span class="input m-end-2">
              <span class="main-context input m-end-2" *ngIf="currentPatient">{{ currentPatient?.getFullName() }}</span>
              <span class="sub-context input m-end-2" *ngIf="currentPermissionHospital">{{
                currentPermissionHospital?.name }}</span>
            </span>
          </div>
        </div>

        <div class="nav-item" [ngClass]="{'active': activeState === 'treatments'}">
          <a href="" class="nav-link" id="sidebar-dashboard" routerLink="/dashboard">
            <div class="icon" [inlineSVG]="'/assets/svg/stethoscope.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_treatments' | translate }}</div>
          </a>
        </div>
        <div class="nav-item" id="sidebar-calendar" [ngClass]="{'active': activeState === 'calendar'}" *ngIf="scopeService.doesCurrentPatientHasPermission(permissionTypeEnum.Appointment)">
          <a href="" class="nav-link" routerLink="/calendar">
            <div class="icon" [inlineSVG]="'/assets/svg/calendar.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_calendar' | translate }}</div>
          </a>
          <a href="" class="nav-badge" routerLink="/calendar" *ngIf="attentionCenter?.appointmentsPending">{{
            attentionCenter?.appointmentsPending | translateNumber }}</a>
        </div>
        <div class="nav-item" id="sidebar-conversations" [ngClass]="{'active': activeState === 'messages'}" *ngIf="scopeService.doesCurrentPatientHasPermission(permissionTypeEnum.Messaging)">
          <a href="" class="nav-link" routerLink="/conversations">
            <div class="icon" [inlineSVG]="'/assets/svg/chat.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_messages' | translate }}</div>
          </a>
          <a href="" class="nav-badge" routerLink="/conversations"
            *ngIf="attentionCenter?.conversationMessagesUnread">{{ attentionCenter?.conversationMessagesUnread |
            translateNumber }}</a>
        </div>
      </div>

      <div class="content-part mt-auto mb-0">
        <p class="sidebar-title sidebar-open-element-block">{{ 'layouts.default.nav.title_account' | translate }}</p>

        <div class="nav-item" [ngClass]="{'active': activeState === 'settings'}">
          <a href="" class="nav-link" id="sidebar-account-settings" routerLink="/settings">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/settings.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_settings' | translate }}</div>
          </a>
        </div>

        <div class="nav-item" [ngClass]="{'active': activeState === 'help-center'}">
          <a href="" class="nav-link" id="sidebar-account-help-center" routerLink="/help-center">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/info.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_help_center' | translate }}</div>
          </a>
        </div>

        <div class="nav-item">
          <a href="" class="nav-link" id="sidebar-logout" (click)="showLogoutModal($event)">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/logout.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_logout' | translate }}</div>
          </a>
        </div>

        <div class="nav-item item-legal sidebar-open-element-block">
          <a href="" id="button_flow_copyrights_cookie_settings" class="btn btn-sm btn-as-text m-end-1 d-inline-flex align-items-center"
            (click)="triggerCookies($event)">
            {{ 'components.flow_copyrights.cookie_settings' | translate }}
            <span class="icon sm rtl-mirrored-inline-svg m-start-1"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          </a>

          <a href="" id="button_flow_copyrights_cookie_page" class="btn btn-sm btn-as-text d-inline-flex align-items-center"
            (click)="goToCookiePolicy($event)">
            {{ 'components.flow_copyrights.cookie_page' | translate }}
            <span class="icon sm rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/external.svg'"></span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="layout-body">
    <router-outlet></router-outlet>
  </div>
</div>
