import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener, Input, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageTabItem } from './../../models/page-tab-item';
import { PageScrollService } from 'ngx-page-scroll-core';
import { GeneralService } from '../../services/general.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-tabs',
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss']
})
export class PageTabsComponent implements OnInit, OnDestroy {
  public isScrollable: boolean;
  public leftPosition: number;
  public activeItem: PageTabItem;

  private _onViewRedrawRequestSubscription: Subscription;
  private _onPageTabScrollRequestSubscription: Subscription;

  @Input()
  set items(items: PageTabItem[]) {
    if(this._items!==items) {
      this._items = items;
    }

    if(this._items) {
      if(!this.activeItem || !this.items.includes(this.activeItem)) {
        this.activeItem = this._items[0];
      }
    }
  }
  get items(): PageTabItem[] {
    return this._items;
  }
  private _items: PageTabItem[];

  @Input() asClassicTabs: boolean;

  @Output() onTabChange: EventEmitter<PageTabItem> = new EventEmitter();

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('itemsContainer') itemsContainer: ElementRef;

  constructor(
    @Inject(DOCUMENT) public document: any,
    public changeDetectorRef: ChangeDetectorRef,
    public pageScrollService: PageScrollService,
    protected generalService: GeneralService
  ) {}

  ngOnInit() {
    this.leftPosition = 0;

    this._onViewRedrawRequestSubscription = this.generalService.onViewRedrawRequest.subscribe(() => {
      this.checkIfScrollable();
    });

    this._onPageTabScrollRequestSubscription = this.generalService.onPageTabScrollRequest.subscribe(request => {
      let _pageTabItem = null;
      let _animated = true;

      if(request.hasOwnProperty('pageTabItem')) {
        _pageTabItem = request.pageTabItem;

        if(request.hasOwnProperty('animated')) {
          _animated = request.animated;
        }
      }

      if(_pageTabItem) {
        this.clickHandler(null, _pageTabItem, _animated);
      }
    });
  }

  ngOnDestroy() {
    if(this._onViewRedrawRequestSubscription) {
      this._onViewRedrawRequestSubscription.unsubscribe();
    }

    if(this._onPageTabScrollRequestSubscription) {
      this._onPageTabScrollRequestSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.checkIfScrollable();
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.checkIfScrollable();
  }

  @HostListener('window:scroll')
  onScroll() {
    if(!this.asClassicTabs) {
      this.updateActiveTab();
    }
  }

  checkIfScrollable() {
    this.isScrollable = (this.getScrollDifference() > 0);
  }

  isMarkerVisible(isLeft: boolean):boolean {
    if(isLeft) {
      return this.leftPosition < 0;
    } else {
      return (this.getScrollDifference() + this.leftPosition) > 0;
    }
  }

  move(event, goLeft: boolean) {
    event.preventDefault();
    const difference = this.getScrollDifference();
    let jumpSize = 250;

    if (goLeft) {
      if(this.leftPosition + jumpSize > 0) {
        this.leftPosition = 0;
      } else {
        this.leftPosition += jumpSize;
      }
    } else {
      const rest = (difference+this.leftPosition);

      if(rest < jumpSize) {
        jumpSize = rest;
      }
      this.leftPosition -= jumpSize;
    }
  }

  getScrollDifference():number {
    const itemsEl = this.itemsContainer.nativeElement;
    const scrollEl = this.scrollContainer.nativeElement;
    return (itemsEl.offsetWidth-scrollEl.offsetWidth);
  }

  updateActiveTab() {
    const bodyContent:any = document.getElementsByClassName('body-content')[0];
    const scroll = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    const scrollEnd = document.body.offsetHeight - window.innerHeight;
    const margin: number = 80;
    let tab;

    this._items.forEach(item => {
      const zone:any = document.getElementById(item.htmlID);

      if(zone && bodyContent) {
        if (zone.offsetTop - scroll < bodyContent.offsetTop + margin) {
          tab = item;
        }
      }
    });

    if(scroll === scrollEnd) {
      tab = this._items[this._items.length-1];
    }

    if(tab) {
      this.setActiveTab(tab);
    }
  }

  setActiveTab(tab: PageTabItem) {
    this.activeItem = tab;
    this.onTabChange.emit(this.activeItem);
  }

  clickHandler(event, item: PageTabItem, animated: boolean = true) {
    if (event) {
      event.preventDefault();
    }

    if(this.asClassicTabs) {
      this.setActiveTab(item);
      return;
    }

    const bodyContent:any = document.getElementsByClassName('body-content')[0];
    const bodyContentStyle = getComputedStyle(bodyContent);
    const margin = parseInt(bodyContentStyle.paddingTop) || 40;

    if(document.getElementById(item.htmlID)) {
      this.pageScrollService.scroll({
        document: this.document,
        scrollTarget: `#${item.htmlID}`,
        scrollOffset: (bodyContent.offsetTop + margin),
        duration: (animated ? 500 : 0)
      });
    }
  }
}
