import { TranslationInterface } from '../interfaces/translation.interface';
import { CareModule } from './care-module';
import { Patient } from './patient';

export class UserTaskBasic {
  public uid: string;
  public title: TranslationInterface;
  //public assignee: HealthCareProfessional;
  public due_date: string;
  public created_date: string;
  public created_at: string;
  public care_module: CareModule;
  public patient: Patient;
  public type: string;
  className =  'UserTaskBasic';
  
  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }
  fillFromJson(item) {
    if(item.id) {
      this.uid = item.id;
    }
    
    if(item.uid) {
      this.uid = item.uid;
    }

    this.title = item.title;
    this.due_date = item.due_date;
    this.created_date = item.created_date;
    this.created_at = item.created_at;
    this.type = item.type;

    if(item.assignee) {
      //this.assignee = new HealthCareProfessional(item.assignee);
    }

    if(item.patient) {
      this.patient = new Patient(item.patient);
    }

    if(item.care_module) {
      this.care_module = new CareModule(item.care_module);
    }
  }

  passed() {
    const date = new Date();
    date.setDate(date.getDate() - 2);
    const hours48Ago: Date = date;

    if (new Date(this.created_at).getTime() < hours48Ago.getTime()) {
      return true;
    } 
    return false;
  }

  get translationKey():string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  get contextTiming(): Date {
    return new Date(this.due_date || this.created_date || this.created_at || null);
  }

  get testingID(): string {
    return ['user-task', this.type?.toLowerCase(), this.uid].join('-');
  }
}
