import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'circle-progress-small',
  templateUrl: './circle-progress-small.component.html'
})
export class CircleProgressSmallComponent implements OnInit {

  public strokeWidth = 50;

  @Input() percent: Number;

  constructor() { }

  ngOnInit(): void {
  }

}
