<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  
  <div *ngIf="!isRevokeModal">
    <h3>{{ 'action.do_not_consent' | translate }}</h3>
    <p class="m-0">{{ 'modals.do_not_consent.consent_confirm' | translate }}<br/>{{ 'modals.do_not_consent.consent_info_no_account' | translate }}</p>
  </div>
  <div *ngIf="isRevokeModal">
    <h2>{{ 'action.do_not_consent' | translate }}</h2>
    <p class="m-0">{{ 'modals.do_not_consent.consent_confirm' | translate }}<br/>{{ 'modals.do_not_consent.consent_info_deactivate' | translate }}</p>
  </div>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleNo()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary m-start-3" (click)="handleYes()" [ngClass]="{'loader': isLoading}">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span>{{ 'action.i_am_sure' | translate }}</span>
    </button>
  </div>
</div>
