<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/dashboard" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.goals.goals' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="row" *ngIf="pathway?.goals?.length">

      <div class="col-4 mb-2" *ngFor="let goalInstance of pathway?.goals">
        <div class="card card-goal h-100">
          <div class="card-body card-body">
            <h3>{{ goalInstance?.title | translateHelp | translate }}</h3>

            <div class="goal-box goal-box-graph" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'PROGRESS' && goalInstance.evaluation?.available()">
              <circle-progress
                [percent]="goalService.getProgressPercentage(goalInstance)"
                [title]="goalService.getProgressTitle(goalInstance) | translateNumber"
                [subtitle]="goalService.getProgressSubtitle(goalInstance) | translate"
              ></circle-progress>
            </div>

            <div class="goal-box goal-box-graph" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'PROGRESS' && !goalInstance.evaluation?.available()">
              <label class="position-absolute top-50 start-0 translate-middle-y w-50 text-center"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
              <svg style="width: 100%; height: 100%;" viewBox="0 0 214 214" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M79.3165 210.317C22.2564 195.027 -11.6056 136.377 3.68359 79.3165C18.9728 22.2564 77.6235 -11.6056 134.684 3.68363C191.744 18.9728 225.606 77.6235 210.316 134.684C195.027 191.744 136.377 225.606 79.3165 210.317ZM127.025 32.2654C85.7503 21.2058 43.3249 45.7002 32.2654 86.975C21.2058 128.25 45.7001 170.675 86.9749 181.735C128.25 192.794 170.675 168.3 181.735 127.025C192.794 85.7503 168.3 43.325 127.025 32.2654Z" fill="#F4F4F4"/>
              </svg>
            </div>

            <div class="goal-box goal-box-text" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'TEXT'">
              <p class="title text-truncate">{{ goalInstance?.evaluation?.evaluation_value || '-' }}</p>
              <p *ngIf="goalInstance?.evaluation?.evaluation_unit">{{ goalInstance?.evaluation?.evaluation_unit | translateHelp | translate }}</p>
              <label class="text-center mt-2" *ngIf="!goalInstance.evaluation?.available()"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
            </div>

          </div>
          <div class="card-footer">
            <a href="" class="btn btn-secondary m-start-auto" [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/' + '/goal/' + goalInstance?.id]">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
