import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormField } from '../../models/form-field';
import { FormFieldValueType } from '../../enums/form-field-value-type';
import { FFUiStyleOption } from '../../models/form-field-ui-config';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicInputComponent),
      multi: true,
    },
  ],
})
export class DynamicInputComponent implements ControlValueAccessor {
  @Input() field: FormField;
  @Input() formControl: FormControl<any>;

  public value: any;

  public formFieldValueType = FormFieldValueType;
  public uiStyleOption = FFUiStyleOption;

  public datePickerConfig: any = {
    withTimepicker: true
  };
  public onChange: any = () => {
  }
  public onTouched: any = () => {
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onValueChange(e) {
    this.value = e;
    this.onChange(e);
  }

  numberOnly(event: KeyboardEvent) {
    // This function makes sure you can only enter whitelisted characters.
    // This should be used on input[number].
    // Chrome will not allow you to enter other value then a number or forbiddenKeys.
    // That's why the forbiddenKeys are explicitly blocked.
    // Safari and Firefox allow all characters. They will only throw an error on validation.
    // So we block all keys except 0-9 and the allowedKeys.

    const forbiddenKeys = [',', '.', 'e', '+'];
    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End', '-', 'Control', 'Alt', 'Meta', 'Shift'];

    if (allowedKeys.includes(event.key)) {
      return;
    }

    if (event.key < '0' || event.key > '9' || forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
