import { AbstractControl } from '@angular/forms';

export class PhoneValidator {
  static validate() {
    return (control: AbstractControl): {[key: string]: any} => {
      if(control.value && control.value.length<9){
        return {phone_is_wrong: true};
      } else {
        return {};
      }
    };
  }
}
