import { Injectable } from '@angular/core';
import { DateFormatMap } from '../models/date-format-map';
import { DateFormat } from '../models/date-format';
import { BaseLocaleInterface } from '../interfaces/base-locale.interface';
import { GeneralService } from './general.service';
import moment from 'moment';
import * as locales from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { LanguageService } from './language.service';
import Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  public static converters = {
    // Latin to Arabic
    ar: (num) => {
      return num.toString().replace(/\d/g, (d) => {
        return String.fromCharCode(d.charCodeAt(0) + 1584);
      });
    }
  };

  constructor(
    public generalService: GeneralService,
    public languageService: LanguageService
  ) {
  }

  getBsDatePickerInputFormat(locale?: BaseLocaleInterface): string {
    if (!locale) {
      locale = this.getLocalePreferences().locale;
    }

    const dateFormatMap: DateFormatMap = this.generalService.dateFormatMap;

    if (dateFormatMap) {
      const dateFormat: DateFormat = dateFormatMap.get(locale.date_format);
      return this.getMomentJsFormat(dateFormat);
    }
  }

  getMomentJsFormat(dateFormat: DateFormat) {
    return this.transformFormatToMomentFormat(dateFormat.format);
  }

  transformFormatToMomentFormat(format: string) {
    // Legend

    // 'd' stands for '1'
    // 'dd' stands for '01'
    // 'ddd' stands for 'mon-su'
    // 'dddd' stands for 'monday-sunday'

    // 'm' stands for '1-12'
    // 'mm' stands for '01-12'
    // 'mmm' stands for 'jan-dec'
    // 'mmmm' stands for 'january-december'

    // 'yy' stands for '20'
    // 'yyyy' stands for '2020'

    format = format.replace('yyyy', 'YYYY');
    format = format.replace('yy', 'YY');

    format = format.replace(/m/g, 'M');

    format = format.replace(/d/g, 'D');
    format = format.replace(/DDDD/, 'dddd');
    format = format.replace(/DDD/, 'ddd');

    return format;
  }

  setLocalePreferences(locale: BaseLocaleInterface) {
    const dateFormat = this.generalService.dateFormatMap.get(locale.date_format);

    if (!locale.time_zone) {
      locale.time_zone = 'Europe/Brussels';
    }

    localStorage.setItem('locale', JSON.stringify(locale));
    localStorage.setItem('localeDateFormat', JSON.stringify(dateFormat));

    this.doLocaleConfiguration();
  }

  doLocaleConfiguration() {
    const locale: BaseLocaleInterface = this.getLocalePreferences().locale;

    if (locale.language) {
      this.languageService.setCurrentLanguageByEnum(locale.language);
    }

    this.defineStartOfWeek(locale);
    this.momentJsUpdateLocale(locale);
    this.highchartsUpdateLocaleWithMoment();
  }

  getLocalePreferences() {
    const locale: BaseLocaleInterface = JSON.parse(localStorage.getItem('locale'));
    const localeDateFormat: DateFormat = JSON.parse(localStorage.getItem('localeDateFormat')) as DateFormat;

    return {
      locale,
      dateFormat: localeDateFormat
    };
  }

  toTime(dateString) {
    const time24Hours = this.getLocalePreferences().locale.time_24_hours;
    let timeFormat;

    if (time24Hours) {
      timeFormat = 'HH:mm';
    } else {
      timeFormat = 'hh:mm A';
    }
    return moment(dateString).format(timeFormat);
  }

  defineStartOfWeek(locale: BaseLocaleInterface) {
    if (!locale.first_day_of_week) {
      return;
    }

    for (const _loc in locales) {
      const localeDef = locales[_loc];
      const dow: number = +DAYS_OF_WEEK[locale.first_day_of_week];

      if (localeDef['week']) {
        localeDef['week']['dow'] = dow;
      }

      // the defaults
      defineLocale(locales[_loc].abbr, localeDef);

      // exception for enlish
      if (locales[_loc]?.abbr?.toLowerCase() === 'en-gb') {
        defineLocale('en', localeDef);
      }

      // exception for hebrew
      if (locales[_loc]?.abbr?.toLowerCase() === 'he') {
        defineLocale('he-il', localeDef);
      }
    }
  }

  momentJsUpdateLocale(locale: BaseLocaleInterface) {
    //
    // managing the locale
    //
    let currentLocale = this.languageService.getCurrentLanguageCode().locale;
    switch (currentLocale) {
      case 'he-il':
        currentLocale = 'he';
        break;
      case 'en':
        currentLocale = 'en-Gb';
        break;
    }
    //
    // managing the localeConfig
    //
    let currentLocaleConfig = {};
    if (locale.first_day_of_week) {
      const dow: number = +DAYS_OF_WEEK[locale.first_day_of_week];
      currentLocaleConfig = {
        week: {
          dow
        }
      };
    }
    moment.updateLocale(currentLocale, currentLocaleConfig);
  }

  highchartsUpdateLocaleWithMoment() {
    Highcharts.setOptions({
      lang: {
        months: moment.months(),
        shortMonths: moment.monthsShort(),
        weekdays: moment.weekdays(),
        shortWeekdays: moment.weekdaysShort()
      }
    });

    Highcharts.setOptions({
      lang: {
        decimalPoint: '\u066B'
      }
    });
  }

  dateWithoutTimeZone(value: any): Date {
    let conv_date = new Date();

    const preferences = this.getLocalePreferences();
    const moment_date = moment(new Date(value)).tz(preferences.locale.time_zone);

    if (moment_date) {
      const date_string = moment_date.toString();
      const date_string_converted = date_string.substring(0, date_string.lastIndexOf(':'));
      conv_date = new Date(date_string_converted);
    }

    return conv_date;
  }

  dateWithTimeZone(date: Date): Date {
    const timeZone = this.getLocalePreferences().locale.time_zone;

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return moment.tz(`${year}-${month}-${day} ${hour}:${minutes}`, 'YYYY-MM-DD hh:mm', timeZone).toDate();
  }
}
