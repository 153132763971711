import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from './../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    public authenticationService: AuthenticationService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  handleYes() {
    this.authenticationService.logout();
    return this.bsModalRef.hide();
  }

  handleNo() {
    return this.bsModalRef.hide();
  }
}
