import {TranslationInterface} from '../interfaces/translation.interface';
import {CareModule} from './care-module';
import {FormField} from './form-field';
import {Patient} from './patient';
import {UserTaskBasic} from './user-task-basic';
import {UserTaskSection} from './user-task-section';
import {UserTaskUiConfig} from './user-task-ui-config';

export class UserTask {
  public uid: string;
  public title: TranslationInterface;
  public description: TranslationInterface;
  public patient: Patient;
  public care_module: CareModule;
  public patient_pathway_id: string;
  public due_date: string;
  public created_date: string;
  public type: string;
  public form_fields: Array<FormField> = [];
  public sections: Array<UserTaskSection> = [];
  public ui_config: UserTaskUiConfig;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.id) {
      this.uid = item.id;
    }

    if (item.uid) {
      this.uid = item.uid;
    }

    this.title = item.title;
    this.description = item.description;
    this.patient_pathway_id = item.patient_pathway_id;
    this.due_date = item.due_date;
    this.created_date = item.created_date;

    this.type = item.type;

    if (item.patient) {
      this.patient = new Patient(item.patient);
    }

    if (item.care_module) {
      this.care_module = new CareModule(item.care_module);
    }

    if (item.form_fields) {
      item.form_fields.forEach(formField => {
        this.form_fields.push(new FormField(formField));
      });
    }

    if (item.sections) {
      item.sections.forEach(section => {
        this.sections.push(new UserTaskSection(section));
      });
    }

    if (item.ui_config && Object.keys(item.ui_config).length > 0) {
      this.ui_config = new UserTaskUiConfig(item.ui_config);
    }
  }

  addBasicData(userTaskBasic: UserTaskBasic) {
    this.uid = userTaskBasic.uid;
    this.title = userTaskBasic.title;
    this.due_date = userTaskBasic.due_date;
    this.created_date = userTaskBasic.created_date;
    this.care_module = userTaskBasic.care_module;
    this.patient = userTaskBasic.patient;
    this.type = userTaskBasic.type;
  }

  get translationTitleKey(): string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  get translationDescriptionKey(): string {
    return `shared.${this.description?.region}.${this.description?.key}`;
  }

  get id(): string {
    return this.uid;
  }

  get hasUiConfig(): boolean {
    if (!!!this.ui_config) {
      for (const form_field of this.form_fields) {
        const hasUiConfig = form_field?.ui_config && Object.keys(form_field?.ui_config).length > 0;
        if (hasUiConfig) {
          console.log('form_field', form_field.id, hasUiConfig);
          return true;
        }
      }

      for (const section of this.sections) {
        for (const form_field of section?.form_fields) {
          const hasUiConfig = form_field?.ui_config && Object.keys(form_field?.ui_config)?.length > 0;
          if (hasUiConfig) {
            return true;
          }
        }
      }

    }
    return !!this.ui_config;
  }
}
