import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pathway } from '../../../models/pathway';
import { GoalService } from '../../../services/goal.service';
import { PatientService } from '../../../services/patient.service';

@Component({
  selector: 'app-patient-goals',
  templateUrl: './patient-goals.component.html',
  styleUrls: ['./patient-goals.component.scss']
})
export class PatientGoalsComponent implements OnInit {
  public isLoading: boolean;
  public patientUid: string;
  public patientPathwayUid: string;
  public pathway: Pathway;

  constructor(
    public patientService: PatientService,
    public goalService: GoalService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      this.loadPathwayDashboard();
    });
  }

  loadPathwayDashboard() {
    this.isLoading = true;
    this.patientService.getPathwayDashboard(this.patientPathwayUid).subscribe(
      pathway => this.pathwayDashboardSuccessHandler(pathway),
      () => this.pathwayDashboardErrorHandler()
    );
  }

  pathwayDashboardSuccessHandler(pathway) {
    this.pathway = pathway;
    this.isLoading = false;
  }

  pathwayDashboardErrorHandler() {
    this.isLoading = false;
  }

}
