import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss']
})
export class DeleteAccountModalComponent implements OnInit {
  public isRequesting: boolean;
  public isWrongPassword: boolean;
  public validationVisible: boolean;
  public form: UntypedFormGroup;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public authenticationService: AuthenticationService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public router: Router,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.formSetup();
  }

  formSetup() {
    if(this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
    });
  }

  handleDelete() {
    if(this.form.valid) {
      this.validationVisible = false;
      this.doDeleteRequest();
    } else {
      this.validationVisible = true;
    }
  }

  doDeleteRequest() {
    if(this.isRequesting) {
      return;
    }

    this.isRequesting = true;

    this.userService.rightOfErasure(this.form.value.password).subscribe(result => {
      this.onDeleteRequestSuccess();
    }, error => {
      this.onDeleteRequestError(error);
    })
  }

  onDeleteRequestSuccess() {
    this.isRequesting = false;

    this.toastrService.info(this.translate.instant('modals.delete_account.delete_request_sent'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.authenticationService.logout();

    return this.bsModalRef.hide();
  }

  onDeleteRequestError(response) {
    this.isRequesting = false;

    if(response.status === 400) {
      if(response.error && response.error.key === 'ERROR_IDENTITY_GDPR_PASSWORD_INVALID') {
        this.validationVisible = true;
        this.isWrongPassword = true;
      }

      if(response.error && response.error.key === 'ERROR_IDENTITY_TOO_MANY_WRONG_PASSWORD_ATTEMPTS') {
        this.bsModalRef.hide();
        this.toastrService.info(this.translate.instant('form.feedback.acc_locked'), null, {
          disableTimeOut: false,
          timeOut: 4000
        })
        this.authenticationService.logout();
      }
    }
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }
}
