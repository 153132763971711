<circle-progress
  class="medium"
  [outerStrokeWidth]="strokeWidth"
  [innerStrokeWidth]="strokeWidth"
  [space]="-(strokeWidth)"
  [showTitle]="true"
  [titleFontSize]="'4rem'"
  [unitsFontSize]="'4rem'"
  [showSubtitle]="false"
  [showUnits]="true"
  [percent]="percent"
  [animation]="false"
></circle-progress>
