import moment from 'moment';
import 'moment-timezone';
import { FileMetaData } from './file-meta-data';

export class ConversationItem {
  public id: string;
  public created_at: string;
  public created_by: string;
  public type: string;
  public hcp_uid: string;
  public page: number;
  public participant_uid: string;
  public old_message: string;
  public new_message: string;
  public message: string;
  public read_at: string;

  // File
  public file_status: string;
  public file: FileMetaData;

  // Audio
  public audio_length: number;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id ? item.id : '';
    this.message = item.message ? item.message : '';
    this.created_at = item.created_at ? item.created_at : '';
    this.created_by = item.created_by ? item.created_by : '';
    this.read_at = item.read_at ? item.read_at : '';
    this.type = item.type ? item.type : '';
    this.hcp_uid = item.hcp_uid ? item.hcp_uid : '';
    this.participant_uid = item.participant_uid ? item.participant_uid : '';
    this.old_message = item.old_message ? item.old_message : '';
    this.new_message = item.new_message ? item.new_message : '';

    //fix for message entries, for when the type is abcent.
    if(this.message && !this.type) {
      this.type = 'TEXT_MESSAGE';
    }

    // File
    if(this.type === 'FILE_MESSAGE') {
      this.file_status = item.file_status ?? '';
      if(item.file) {
        this.file = new FileMetaData(item.file);
      }
    }

    // Audio
    if(this.type === 'AUDIO_MESSAGE') {
      this.audio_length = item?.audio_length;
    }
  }

  time(time_24_hours: boolean, timeZone: string) {
    if (time_24_hours) {
      return moment(this.created_at).tz(timeZone).format('HH:mm');
    } else {
      return moment(this.created_at).tz(timeZone).format('LT');
    }
  }
}
