<div class="bg-pattern-light">
  <div class="container">
    <div class="layout-sidebar">
      <div class="sidebar-content is-flexed responsive-sidebar">
        <div class="content-part">
          <div class="sidebar-logo d-none d-sm-block" [inlineSVG]="'/assets/svg/logo-patient.svg'"></div>
          <div class="icon icon-lg d-block d-sm-none pb-4" [inlineSVG]="'/assets/svg/patient-logo-icon.svg'"></div>
        </div>

        <div class="content-part d-block d-md-none">
          <h1 class="text-primary mb-2">
            <span class="text-nowrap">{{ 'components.flow_welcome.welcome_to' | translate }}</span>&nbsp;
            <span class="text-nowrap">{{ 'components.flow_welcome.c4t' | translate }}</span>
          </h1>
        </div>

        <div class="content-part" [ngClass]="{'validation-visible': showFieldValidation()}">

          <h2>{{ 'pages.flow.self_onboarding_code.enter_code_hcp' | translate }}</h2>

          <div class="form-group styled floating-label mb-1">
            <input type="text" id="inputCode" class="form-control w-100"
              [placeholder]="('pages.flow.self_onboarding_code.enter_code' | translate)"
              [ngClass]="{'ng-invalid-important': showFieldValidation()}" [(ngModel)]="codeInput" autocomplete="off">
            <label for="inputCode">{{ 'pages.flow.self_onboarding_code.enter_code' | translate }}</label>
            <p class="validation-feedback general-feedback" *ngIf="codeIncorrect">{{
              'pages.flow.self_onboarding_code.code_incorrect' | translate }}</p>
            <p class="validation-feedback general-feedback" *ngIf="codeEmpty && !codeInput">{{
              'pages.flow.self_onboarding_code.enter_hc' | translate }}</p>
          </div>

          <p class="mb-0 text-muted">{{ 'pages.flow.self_onboarding_code.dont_have_codes' | translate }}<br />{{
            'pages.flow.self_onboarding_code.dont_have_codes_text' | translate }}</p>

          <button id="button_onboarding_code_submit" type="submit" class="btn btn-sm btn-secondary mt-3 mt-lg-4"
            [ngClass]="{'loader': isLoading}" (click)="submit()">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
            <span>{{ 'action.continue' | translate }}</span>
          </button>
        </div>

        <div class="footer-part mt-auto d-flex">
          <app-flow-copyrights></app-flow-copyrights>

          <button class="btn btn-light shadow btn-rounded m-start-auto d-sm-none" (click)="openHelpCenter()">
            <span class="icon" [inlineSVG]="'/assets/svg/chat-question.svg'"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="layout-body fixed with-background d-none d-sm-flex flex-column">
      <div class="body-content">
        <app-flow-welcome></app-flow-welcome>
      </div>

      <div class="body-content mt-auto m-start-auto">
        <button class="btn btn-light btn-rounded" (click)="openHelpCenter()">
          <span class="icon" [inlineSVG]="'/assets/svg/chat-question.svg'"></span>
          {{ 'pages.flow.home.need_help' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
