import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {AuthenticationService} from "../services/authentication.service";
import {ApiService} from "../services/api.service";
import {environment} from './../../environments/environment';
import {Observable, Subject} from "rxjs";
import {Appointment, AppointmentStatus} from "../models/appointment";
import { Moment } from 'moment';
import { PatientService } from './patient.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends ApiService {
  public static StatusAppointmentPending = "PENDING";
  public static StatusAppointmentAccepted = "ACCEPTED";
  public static StatusAppointmentDeclined = "DECLINED";

  public static StatusAppointmentRemoved = AppointmentStatus.REMOVED;
  public static StatusAppointmentNone = AppointmentStatus.NONE;
  public static StatusAppointmentViewed = AppointmentStatus.VIEWED;

  private readonly appointmentChangedSource = new Subject<Appointment>();
  appointmentChanged$ = this.appointmentChangedSource.asObservable();

  public readonly moreAppointmentsClickedSource = new Subject<Date>();
  moreAppointmentsClicked$ = this.moreAppointmentsClickedSource.asObservable();

  public readonly declineAppointmentClickedSource = new Subject<Appointment>();
  declineAppointmentClicked$ = this.declineAppointmentClickedSource.asObservable();

  public readonly appointmentStatusChanged = new Subject();
  public appointmentStatusChanged$ = this.appointmentStatusChanged.asObservable();


  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public patientService: PatientService,
    public translate: TranslateService
  ) {
    super(http, authenticationService);
  }

  completeDeclineObserver() {
    this.declineAppointmentClicked$ = new Observable(observer => observer.complete());
  }

  getAllAppointments(startDate: Moment, endDate: Moment, userStatus: string = '', count: number = 1000, page: number = 0, allItems?: Appointment[], passedObserver?: any): Observable<any> {
    if(!allItems) {
      allItems = [];
    }

    return new Observable(observer => {
      const urlParts = [];
      const urlParams = [];

      urlParts.push(`${environment.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/appointments`);

      if(startDate) {
        const isoStartDate = startDate.toISOString();
        urlParams.push(`start_date=${isoStartDate}`);
      }

      if(endDate) {
        const isoEndDate = endDate.toISOString();
        urlParams.push(`end_date=${isoEndDate}`);
      }

      urlParams.push(`page=${page}`);
      urlParams.push(`size=${count}`);

      if(userStatus && userStatus.length) {
        urlParams.push(`status_of_user=${userStatus}`);
      }

      urlParts.push(`?${urlParams.join('&')}`);
      const url = urlParts.join('');

      this.authenticatedGet(url).subscribe(result => {
        const appointments = this.mapAppointments(result['items']);

        allItems = allItems.concat(appointments);

        if(result.pagination.total_pages - 1 === page || result.pagination.total_pages === 0) {
          const finalObserver = passedObserver || observer;
          finalObserver.next(allItems);
          finalObserver.complete();
        } else {
          this.getAllAppointments(startDate, endDate, userStatus, count, page + 1, allItems, observer).subscribe();
        }
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPendingAppointments(): Observable<any> {
    return new Observable(observer => {
      const urlParts = [];
      const urlParams = [];

      urlParts.push(`${environment.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/appointments`);

      urlParams.push(`page=0`);
      urlParams.push(`size=1000`);
      urlParams.push(`status_of_user=${AppointmentService.StatusAppointmentNone}`);
      urlParams.push(`cancelled=false`);

      urlParts.push(`?${urlParams.join('&')}`);
      const url = urlParts.join('');

      this.authenticatedGet(url).subscribe(result => {
        const appointments = this.mapAppointments(result['items']);
        const returnList = [];

        appointments.forEach(appointment => {
          if (appointment.status_of_user === AppointmentService.StatusAppointmentNone && !appointment.cancelled ) {
            returnList.push(appointment);
          }
        });

        observer.next(returnList);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getAppointmentsByType(type: string): Observable<any> {
    return new Observable(observer => {
      const urlParts = [];
      const urlParams = [];

      urlParts.push(`${environment.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/appointments`);

      urlParams.push(`page=0`);
      urlParams.push(`size=1000`);
      urlParams.push(`type=${type}`);
      urlParams.push(`cancelled=false`);

      urlParts.push(`?${urlParams.join('&')}`);
      const url = urlParts.join('');

      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapAppointments(result['items']));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getAppointment(id: string): Observable<Appointment> {
    return new Observable<Appointment>(observer => {
      const url = `${environment.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/appointments/${id}`;
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapAppointment(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  changeAppointment(appointment: Appointment, status): Observable<any>{
    return new Observable(observer => {
      const url = `${environment.platformUrl}/patients/${this.patientService.getCurrentStoredPatientUid()}/appointments/${appointment.uid}`;
      this.authenticatedPatch(url, {status: status}).subscribe(result => {
        observer.next(result);
        observer.complete();
        appointment.status_of_user = status;
        this.appointmentChangedSource.next(appointment);
      }, error => {
        observer.error(error);
        observer.complete();
      })
    });
  }

  mapAppointments(list: any[]): Array<Appointment> {
    const appointments = new Array();
    list.forEach(item => {
      const appointment = this.mapAppointment(item);
      if(appointment.status_of_user !== AppointmentService.StatusAppointmentRemoved) {
        appointments.push(appointment);
      }
    });
    return appointments;
  }

  mapAppointment(input: any): Appointment {
    const appointment = new Appointment(input);
    if (appointment.title_key && appointment.title === "") {
      appointment.title = this.translate.instant(appointment.translationKey);
    }

    if (appointment.description_key && appointment.description === "") {
      appointment.description = this.translate.instant(appointment.translationDescriptionKey);
    }
    return appointment;
  }
}
