<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="backButtonData?.url" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to' | translate }} <span>{{ 'pages.default.help_center.help_center'
              | translate }}</span></span>
        </a>
      </p>
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container">
    <app-faq-content [faq]="faq" [category]="category" (goToFaq)="goToFaq($event)"></app-faq-content>
  </div>
</div>
