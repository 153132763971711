import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Hospital } from '../models/hospital';
import {environment} from './../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LanguageService } from './language.service';
import { PatientService } from './patient.service';

@Injectable({
  providedIn: 'root'
})
export class HospitalService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    public patientService: PatientService,
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    public languageService: LanguageService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getHospital(hospital_uid: string): Observable<any> {
    return new Observable(observer => {
      const hospitalPF = `${this.platformUrl}/hospitals/${hospital_uid}`;

      this.authenticatedGet(hospitalPF).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getHospitalContent(hospital: Hospital): Observable<any> {
    return new Observable(observer => {
      const locale = this.languageService.getCurrentLanguage().locale;
      const observables: Observable<any>[] = [];
      const hasMetadata = hospital.hasOwnProperty('metadata') && hospital.metadata?.region && hospital.metadata?.key;
      const hasLogo = hospital.hasOwnProperty('logo_reference') && hospital.logo_reference?.region && hospital.logo_reference?.key;
      let metadata;
      let logo;

      if (hasMetadata) {
        observables.push(this.cmsRegionGetDetail(hospital.metadata, locale));
      }
      if (hasLogo) {
        observables.push(this.cmsRegionGetDetail(hospital.logo_reference, locale));
      }

      forkJoin(observables).subscribe(results => {
        if (hasMetadata) {
          metadata = results.find( result => result.uid === hospital.metadata.key);
        }

        if (hasLogo) {
          logo = results.find( result => result.uid === hospital.logo_reference.key);
        }

        observer.next({...metadata, logo});
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getCurrentHospital(): Observable<Hospital> {
    return new Observable<Hospital>(observer => {
      const hospital_uid = this.patientService.getCurrentStoredHospitalUid();

      this.getHospital(hospital_uid).subscribe(hospitalData => {
        const hospital = new Hospital(hospitalData);

        this.getHospitalContent(hospital).subscribe(hospitalContent => {
          const singleHospitalContent = hospitalContent;
          hospital.addDetails(singleHospitalContent);

          observer.next(hospital);
          observer.complete();
        });

      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
