<div class="layout-header-pusher header-back-tabs-progress"></div>

<div class="layout-header header-back-tabs-progress">
  <div class="header-content">
    <div class="container">
      <p class="m-0">
        <a routerLink="/dashboard" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_overview' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.patient_materials.learning_materials' | translate }}</h1>
      <app-page-tabs [asClassicTabs]="true" [items]="pageTabItems" (onTabChange)="onTabChangeHandler($event)"></app-page-tabs>
    </div>

    <div class="wide-progress-bar-wrapper">
      <div class="container">
        <div class="progress">
          <div class="progress-bar" role="progressbar" [style.width]="selectedPhase?.percent()"></div>
        </div>
        <label *ngIf="selectedPhase">{{ 'pages.default.patient_materials.read_stats' | translate:{value: selectedPhase?.completed, total: selectedPhase?.total} | translateNumber }}</label>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoadingMaterials">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!isLoadingMaterials && (!selectedPhase || !selectedPhase?.educational_materials?.length)">
      <span [inlineSVG]="'/assets/svg-color/multipage.svg'"></span>
      <h3>{{ 'pages.default.patient_materials.no_materials_yet' | translate }}</h3>
    </div>

    <div class="row"  *ngIf="!isLoadingMaterials">
      <div class="col-4 mb-2" *ngFor="let material of selectedPhase?.educational_materials">
        <div class="card h-100" [ngClass]="{ 'card-muted': material.isCompleted, 'card-unavailable': material.isUnavailable }">
          <a href="" class="card-cover" [style.background-image]="'url(' + material?.thumbnail?.image?.url + '?width=814&disable=upscale&format=pjpg&auto=webp' + ')'" (click)="openMaterial($event, material)">
            <div class="cover-meta">
              <div class="meta-category" *ngIf="material.thumbnail">
                <span [inlineSVG]="'/assets/svg/page.svg'" *ngIf="material.isArticle" class="rtl-mirrored-inline-svg"></span>
                <span [inlineSVG]="'/assets/svg/pdf.svg'" *ngIf="material.isDocument"></span>
                <span [inlineSVG]="'/assets/svg/play.svg'" *ngIf="material.isMedia" class="rtl-mirrored-inline-svg"></span>
              </div>
            </div>
          </a>
          <div class="card-body">
            <h3 class="m-0">{{ material.title || 'pages.default.patient_materials.unavailable' | translate }}</h3>
          </div>
          <div class="card-footer">
            <ng-container *ngIf="!material.isUnavailable">
              <p class="text-muted" *ngIf="material.isUnstarted">
                <span class="font-weight-semibold align-middle m-start-1" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.not_played' | translate }}</span>
                <span class="font-weight-semibold align-middle m-start-1" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.not_read' | translate }}</span>
              </p>
              <p class="text-muted" *ngIf="material.isStarted">
                <circle-progress-small [percent]="material.progress"></circle-progress-small>
                <span class="font-weight-semibold align-middle m-start-1" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.progress_played' | translate : {value:material.progress} }}</span>
                <span class="font-weight-semibold align-middle m-start-1" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.progress_read' | translate : {value:material.progress} }}</span>
              </p>
              <p class="text-muted" *ngIf="material.isCompleted">
                <span class="align-middle" [inlineSVG]="'/assets/svg/check-selected.svg'"></span>
                <span class="font-weight-semibold align-middle m-start-1" *ngIf="material.isMedia">{{ 'pages.default.patient_materials.played' | translate }}</span>
                <span class="font-weight-semibold align-middle m-start-1" *ngIf="!material.isMedia">{{ 'pages.default.patient_materials.read' | translate }}</span>
              </p>
            </ng-container>
            <a href="" class="btn btn-secondary m-start-auto" (click)="openMaterial($event, material)">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
