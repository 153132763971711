import { Appointment } from './appointment';
import { CareModule } from './care-module';
import { GoalInstanceOverview } from './goal-instance-overview';
import { HealthCareProfessional } from './health-care-professional';
import { PatientMdt } from './patient-mdt';
import { PhaseInstance } from './phase-instance';
import { Reminder } from './reminder';

export class Pathway {
  id: string;

  care_module: CareModule;
  case_manager: HealthCareProfessional;
  clinical_lead: HealthCareProfessional;

  patient_mdt: PatientMdt;

  started_at: string;
  ended_at: string;

  patient_uid: string;
  patient_pathway_id: string;

  reminders: Reminder[];
  phases: PhaseInstance[];
  goals: GoalInstanceOverview[];
  appointments: Appointment[];

  label: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id;

    if (item.care_module) {
      this.care_module = new CareModule(item.care_module);
    }

    if(item.case_manager) {
      this.case_manager = new HealthCareProfessional(item.case_manager);
    }

    if(item.clinical_lead) {
      this.clinical_lead = new HealthCareProfessional(item.clinical_lead);
    }

    if(item.patient_mdt) {
      this.patient_mdt = new PatientMdt(item.patient_mdt);
    }

    this.started_at = item.started_at || '';
    this.ended_at = item.ended_at || '';

    this.patient_uid = item.patient_uid || '';
    this.patient_pathway_id = item.patient_pathway_id || '';

    if (item.reminders) {
      this.reminders = [];
      item.reminders.forEach(_reminder => {
        let reminder = new Reminder(_reminder);
        if(reminder.isValid) {
          this.reminders.push(reminder);
        }
      });
    }

    if (item.phases) {
      this.phases = item.phases.map(_phase => new PhaseInstance(_phase));
    }

    if (item.goals) {
      this.goals = item.goals.map(_goal => new GoalInstanceOverview(_goal));
    }

    if (item.appointments) {
      this.appointments = item.appointments.map(_appointment => new Appointment(_appointment));
    }
  }

  getHcps(
    onlyActiveHcps: boolean = true,
    includeLeadpositions: boolean = true,
    excludeLeadpositions: boolean = false,
  ): Array<HealthCareProfessional> {
    if(!this.patient_mdt?.hcps) {
      return;
    }

    const patientMdtHcps = this.patient_mdt.getAllHcps(onlyActiveHcps);
    const hcpsUids = [];

    let hcps: Array<HealthCareProfessional> = [];

    patientMdtHcps.forEach(_hcp => {
      hcps.push(_hcp);
      hcpsUids.push(_hcp.uid);
    });

    if(includeLeadpositions && this.case_manager && !hcpsUids.includes(this.case_manager.uid)) {
      if(!onlyActiveHcps || (onlyActiveHcps && this.case_manager.status == 'ACTIVE')) {
        hcps.unshift(this.case_manager);
      }
    }

    if(includeLeadpositions && this.clinical_lead && !hcpsUids.includes(this.clinical_lead.uid)) {
      if(!onlyActiveHcps || (onlyActiveHcps && this.clinical_lead.status == 'ACTIVE')) {
        hcps.unshift(this.clinical_lead);
      }
    }

    if(excludeLeadpositions) {
      hcps = hcps.filter(_hcp => {
        const ignoreUids = [this.case_manager?.uid, this.clinical_lead?.uid];
        return !ignoreUids.includes(_hcp.uid);
      });
    }

    return hcps;
  }

  get translationKey():string {
    return this.care_module?.translationKey || '';
  }
}
