import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {QueryListDetailComponent} from '../pages/default/query-list-detail/query-list-detail.component';

@Injectable()
export class QueryListCanDeactivateGuard
  implements CanDeactivate<QueryListDetailComponent> {
  canDeactivate(component) {
    return component.canDeactivate();
  }
}
