import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { HomeFlowService } from '../../../services/home-flow.service';
import { LanguageCode } from '../../../models/language-code';
import { DataService } from '../../../services/data.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HelpCenterModalComponent } from '../../../modals/help-center-modal/help-center-modal.component';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-locale-registration',
  templateUrl: './locale-registration.component.html',
  styleUrls: ['./locale-registration.component.scss']
})
export class LocaleRegistrationComponent implements OnInit {
  public languageOptions: LanguageCode[];
  public selectedLanguage: LanguageCode;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public languageService: LanguageService,
    public homeFlowService: HomeFlowService,
    public dataService: DataService,
    public modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getLanguageCodes();

    this.languageService.onLanguageUpdated.subscribe(() => {
      this.getLanguageCodes();
    });
  }

  getLanguageCodes() {
    this.languageService.getSupportedAppLanguages().subscribe(languageCodes => {
      this.languageOptions = languageCodes;
      this.selectedLanguage = this.languageService.getCurrentLanguage();
    });
  }

  onLanguageSelection() {
    this.languageService.setCurrentLanguageByEnum(this.selectedLanguage.value);
    this.getLanguageCodes();
  }

  submit() {
    if(this.router.url.includes("/register/language")) {
      this.router.navigateByUrl('/register/code');
    } else if(this.router.url.includes("/onboarding/language")) {
      this.homeFlowService.goNextStepOrDone().subscribe(() => {});
    } else {
      this.router.navigateByUrl('/');
    }
  }

  openHelpCenter() {
    this.modalService.show(HelpCenterModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg modal-compact'
      })
    );
  }
}
