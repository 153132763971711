import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-surgery-date-modal',
  templateUrl: './surgery-date-modal.component.html',
  styleUrls: ['./surgery-date-modal.component.scss']
})
export class SurgeryDateModalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {

  }
}
