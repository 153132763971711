<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/settings" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.settings.security.security_settings' | translate }}</h1>
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div id="zone_change_password" class="mb-5">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.security.change_pw' | translate }}</h2>
        </div>
      </div>

      <form [formGroup]="passwordForm" id="form" (ngSubmit)="passwordFormSubmit()" [ngClass]="{'validation-visible': validationVisible}">
        <div class="form-group styled floating-label width-limit">
          <input type="password" id="inputCurrentPassword" class="form-control w-100" [placeholder]="('pages.default.settings.security.current_pw'|translate)" formControlName="old_password">
          <label for="inputCurrentPassword" class="required">{{ 'pages.default.settings.security.current_pw' | translate }}</label>
          <p class="validation-feedback" *ngIf="passwordForm?.controls?.old_password?.errors">{{ 'form.feedback.current_pw_incorrect' | translate }}</p>
        </div>

        <div class="form-group styled floating-label width-limit">
          <input type="password" id="inputNewPassword" class="form-control w-100" [placeholder]="('pages.default.settings.security.new_pw'|translate)" formControlName="new_password" [ngClass]="{'ng-invalid-important': (passwordForm?.errors?.mismatch)}">
          <label for="inputNewPassword" class="required">{{ 'pages.default.settings.security.new_pw' | translate }}</label>
          <p class="validation-feedback" *ngIf="passwordForm?.controls?.new_password?.errors?.password_policy">{{ 'form.feedback.pw_complexity_error' | translate }}</p>
          <p class="validation-feedback" *ngIf="passwordForm?.controls?.new_password?.errors?.password_already_used">{{ 'form.feedback.pw_already_used' | translate:{value: policy?.history_size} }}</p>
        </div>

        <div class="form-group styled floating-label width-limit">
          <input type="password" id="inputConfirmNewPassword" class="form-control w-100" [placeholder]="('pages.default.settings.security.confirm_pw'|translate)" formControlName="new_password_verification" [ngClass]="{'ng-invalid-important': passwordForm?.errors?.mismatch}">
          <label for="inputConfirmNewPassword" class="required">{{ 'pages.default.settings.security.confirm_pw' | translate }}</label>
          <p class="validation-feedback general-feedback" *ngIf="passwordForm?.errors?.mismatch">{{ 'pages.default.settings.security.pw_not_the_same' | translate }}</p>

        </div>

        <button type="submit" class="btn btn-secondary mt-2 mb-5" [ngClass]="{'loader': isSettingPassword}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSettingPassword"></em></span>
          <span>{{ 'pages.default.settings.security.change_pw' | translate }}</span>
        </button>
      </form>

      <div class="bg-white p-3">
        <app-password-policy-rules [policy]="policy" [validationVisible]="validationVisible" [passwordControl]="passwordForm?.controls?.new_password" ></app-password-policy-rules>
      </div>
    </div>

    <div id="zone_tsa" class="mb-5" *ngIf="profile?.mfa_enabled">
      <div class="heading-block mb-4">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.security.two_step_auth' | translate }}</h2>
          <p>{{ 'pages.default.settings.security.two_step_auth_info' | translate }}</p>
        </div>
        <button class="block-action m-start-auto" (click)="showAddMfaBackupModal($event)" [disabled]="profile?.mfaBackupMethods?.length">
          <span>{{ 'pages.default.settings.security.add_backup_method' | translate }}</span>
          <span class="icon m-start-1" [inlineSVG]="'/assets/svg/add.svg'"></span>
        </button>
      </div>

      <p class="text-muted lead">
        <strong>{{ 'pages.default.settings.security.mfa_primary_method' | translate }}</strong>
      </p>

      <div class="single-item-block mb-2 action-item-block" *ngFor="let mfaMethode of profile?.mfaPrimaryMethods">
        <div class="block-wrapper p-start-3 p-end-0">
          <span *ngIf="mfaMethode?.channel === 'EMAIL'" [inlineSVG]="'/assets/svg/mail.svg'" class="icon rtl-mirrored-inline-svg"></span>
          <span *ngIf="mfaMethode?.channel === 'SMS'" [inlineSVG]="'/assets/svg/phone.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </div>
        <div class="block-wrapper m-end-auto">
          <p class="label" *ngIf="mfaMethode?.channel === 'EMAIL'">{{profile?.email}}</p>
          <p class="label" *ngIf="mfaMethode?.channel === 'SMS'">{{ currentDialCode?.label }} {{ profile?.mobile_number?.number }}</p>
        </div>
        
        <div class="block-wrapper">
          <p>{{ 'pages.default.settings.security.mfa_primary_method_cant_be_removed' | translate }}</p>
        </div>
      </div>

      <div class="mt-4"></div>

      <p class="text-muted lead">
        <strong>{{ 'pages.default.settings.security.mfa_backup_method' | translate }}</strong>
      </p>

      <ng-container *ngIf="profile?.mfaBackupMethods?.length">
        <div class="single-item-block mb-2 action-item-block" *ngFor="let mfaMethode of profile?.mfaBackupMethods">
          <div class="block-wrapper p-start-3 p-end-0">
            <span *ngIf="mfaMethode?.channel === 'EMAIL'" [inlineSVG]="'/assets/svg/mail.svg'" class="icon rtl-mirrored-inline-svg"></span>
            <span *ngIf="mfaMethode?.channel === 'SMS'" [inlineSVG]="'/assets/svg/phone.svg'" class="icon rtl-mirrored-inline-svg"></span>
          </div>
          <div class="block-wrapper m-end-auto">
            <p class="label" *ngIf="mfaMethode?.channel === 'EMAIL'">{{profile?.email}}</p>
            <p class="label" *ngIf="mfaMethode?.channel === 'SMS'">{{ currentDialCode?.label }} {{ profile?.mobile_number?.number }}</p>
          </div>
          <div class="block-wrapper" *ngIf="!mfaMethode.canBeRemoved">
            <p>{{ 'pages.default.settings.security.mfa_this_method_cant_be_removed' | translate }}</p>
          </div>
          <a href="" class="block-action action-light" *ngIf="mfaMethode.canBeRemoved" (click)="removeMfaBackupMethod($event, mfaMethode)">
            <span class="icon small m-end-1" [inlineSVG]="'/assets/svg/close.svg'"></span>
            <span>{{ 'pages.default.settings.security.mfa_remove' | translate }}</span>
          </a>
          <a href="" class="block-action" (click)="setPrimaryMfaMethod($event, mfaMethode)">
            <span>{{ 'pages.default.settings.security.mfa_make_primary' | translate }}</span>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="!profile?.mfaBackupMethods?.length">
        <p class="text-muted">{{ 'pages.default.settings.security.mfa_no_backup_set_up' | translate }}</p>
      </ng-container>
    </div>

    <!-- TEMP hide -->
    <div id="zone_trusted_devices" class="mb-5 d-none">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.security.trusted_devices' | translate }}</h2>
          <p>{{ 'pages.default.settings.security.trusted_devices_text' | translate }}</p>
        </div>
      </div>
    </div>

  </div>
</div>
