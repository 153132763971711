export class DateFormat {
  public key: string;
  public value: string;
  public label: string;
  public format: string;
  public translationKey: string;

  constructor(key, value) {
    this.key = this.value = key;
    this.label = this.format = value;
    this.translationKey = "general.locale_values.date_formats." + key;
  }
}