import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormField } from '../../models/form-field';
import { FFUiIconSource, FFUiStyleOption } from '../../models/form-field-ui-config';

@Component({
  selector: 'app-styled-enum',
  templateUrl: './styled-enum.component.html',
})
export class StyledEnumComponent {

  get inputType(): string {
    // TODO: Hide AFSQ-1565
    // if (
    //   this.field?.ui_config.ui_style === FFUiStyleOption.CHECKBOX ||
    //   (this.field?.ui_config.ui_style === FFUiStyleOption.TILE && this.field?.field_value.multi_select_allowed)
    // ) {
    //   return 'checkbox';
    // } else {
      return 'radio';
    // }
  }

  get multiOptions(): Array<string> {
    return this.field?.field_value?.enum_values
      .map(item => item.value) ?? [];
  }

  get multiOptionsWithoutNone(): Array<string> {
    return this.multiOptions.filter(item => item !== this.specialNone);
  }

  get multiOptionsWithoutSpecials(): Array<string> {
    return this.multiOptions.filter(item => item !== this.specialNone && item !== this.specialAll);
  }

  get specialAll(): string {
    return this.field.id + '_all';
  }

  get specialNone(): string {
    return this.field.id + '_none';
  }

  @Input() field: FormField;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  public IconSource = FFUiIconSource;

  getColorFor(value): string {
    if (this.field.ui_config.hasColors) {
      return this.field.ui_config.getColorFor(value);
    }
  }

  getIconFor(value) {
    if (this.field.ui_config.hasIcons) {
      return this.field.ui_config.getIconFor(value);
    }
  }

  onValueChange(e) {
    this.value = e;
    this.valueChange.emit(e);
  }

  onMultiValueChange(value: string) {
    if (!this.value) {
      this.value = [];
    }

    const isSpecialAllSelected = value === this.specialAll;
    const isSpecialNoneSelected = value === this.specialNone;

    if (isSpecialAllSelected) {
      if (!this.value.includes(value)) {
        // Select all
        this.value = this.multiOptionsWithoutNone;
      } else {
        // Deselect all
        this.value = [];
      }
    } else if (isSpecialNoneSelected) {
      if (!this.value.includes(value)) {
        // Select none
        this.value = [value];
      }
    } else {
      if (this.value.includes(value)) {
        // Remove from array
        this.value = this.value.filter(val => val !== value);

        // Remove all if selected
        if (this.value.includes(this.specialAll)) {
          this.value = this.value.filter(val => val !== this.specialAll);
        }
      } else {
        // Add to array
        this.value.push(value);

        // Remove none if selected
        if (this.value.includes(this.specialNone)) {
          this.value = this.value.filter(val => val !== this.specialNone);
        }

        // When all normal options are selected, all should also be selected
        if (this.arraysHaveSameContents(this.value, this.multiOptionsWithoutSpecials)) {
          this.value.push(this.specialAll);
        }
      }
    }

    this.valueChange.emit(this.value);
  }

  enumChecked(enumValue: string): boolean {
    // TODO: Hide AFSQ-1565
    // if (this.field?.field_value.multi_select_allowed) {
    //   return this.value?.includes(enumValue);
    // } else {
      return enumValue === this.value;
    // }
  }

  arraysHaveSameContents(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }
}
