export enum ParagraphType {
  TEXT = 'text',
  IMAGE = 'image',
  PDF = 'pdf',
  VIDEO = 'video',
  BANNER = 'banner',
  AUDIO = 'audio',
}

export enum ImagePosition {
  LEFT = 'Left',
  RIGHT = 'Right'
}

export interface Link {
  text: string;
  href: string;
}
export class HelpcenterParagraph {
  public type: string;
  public image_position: ImagePosition;
  public image: any;
  public file: any;
  public link: Link;
  public text: string;
  public videos: string[];

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    const keys = Object.keys(item);
    this.type = keys[0];
    const values = item[this.type];

    if (this.type === ParagraphType.TEXT) {
      this.text = values?.text;
    }


    if (this.type === ParagraphType.IMAGE || this.type === ParagraphType.PDF || this.type === ParagraphType.AUDIO) {
      this.file = values?.file;
    }

    if(this.type === ParagraphType.VIDEO) {
      if (values.file?.items?.length) {
        this.videos = values.file.items;
      } else {
        this.videos = [values.file];
      }
    }

    if (this.type === ParagraphType.BANNER) {
      this.image = item[this.type].image;
      this.image_position = item[this.type].image_position;
      this.link = item[this.type].link;
      this.text = item[this.type].text;
    }
  }

  public get isText(): boolean {
    return this.type === ParagraphType.TEXT;
  }

  public get isImage(): boolean {
    return this.type === ParagraphType.IMAGE;
  }

  public get isPdf(): boolean {
    return this.type === ParagraphType.PDF;
  }

  public get isVideo(): boolean {
    return this.type === ParagraphType.VIDEO;
  }

  public get isBanner(): boolean {
    return this.type === ParagraphType.BANNER;
  }

  public get isAudio(): boolean {
    return this.type === ParagraphType.AUDIO;
  }

  public get imagePositionLeft(): boolean {
    return this.image_position === ImagePosition.LEFT;
  }
}
