<div class="container layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-info font-weight-bolder" *ngIf="homeFlowService.getSidebarTitleKey()">{{
        homeFlowService.getSidebarTitleKey() | translate }}</p>

      <app-onboaring-icon-steps *ngIf="showOnboardingIconSteps" [onBoardingType]="onBoardingType"
        [currentState]="currentState"></app-onboaring-icon-steps>
    </div>

    <div class="footer-part d-none d-sm-block mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">

    <div class="container" *ngIf="!isFormDataLoaded">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="isFormDataLoaded">
      <h1 class="display-1 mb-3">{{ 'pages.flow.onboarding_details.your_details' | translate }}</h1>
      <app-onboarding-details-form (onFormInitialized)="onFormInitialized($event)" [mode]="'onboarding'"
        [data]="formComponentData" [validationVisible]="validationVisible"></app-onboarding-details-form>
    </div>

    <div class="container footer-part d-sm-none d-block my-8">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <div class="w-100 d-flex flex-column-reverse flex-xs-row justify-content-xs-end">
          <button class="btn btn-sm btn-secondary m-start-xs-3 my-1 my-xs-0" (click)="formSubmit()"
            [ngClass]="{'loader': isUpdatingPatient}">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isUpdatingPatient"></em></span>
            <span>{{ 'action.continue' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
