import { DateFormatterParams, DateAdapter, CalendarMomentDateFormatter } from 'angular-calendar';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '../services/general.service';
import { LocaleService } from '../services/locale.service';
import { BaseLocaleInterface } from '../interfaces/base-locale.interface';


@Injectable()
export class CustomCalendarDateFormatter extends CalendarMomentDateFormatter {
  public locale: BaseLocaleInterface;
  public weekdays: Array<any>;
  
  constructor(
    public dateAdapter: DateAdapter,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public generalService: GeneralService
  ) {
    super(moment, dateAdapter);
    this.locale = this.localeService.getLocalePreferences().locale;

    this.generalService.getWeekDays().subscribe(weekdays => {
      this.weekdays = weekdays;
    });
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    const dateTime = new Date(date);
    
    const day = this.weekdays.find(item => {
      return item.index == dateTime.getDay();
    });

    return this.translateService.instant(day.translationKey);
  }

  public weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
    const mDate = moment(date);
    const month = mDate.format('MMM');
    return `${mDate.format('D')} ${month}`;
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    let timeFormat = 'HH:mm';

    if(this.locale.time_24_hours) {
      timeFormat = 'HH:mm';
    } else {
      timeFormat = 'hh:mm A';
    }

    return moment(date).format(timeFormat);
  }
}
