export enum ConsentStatus {
  OPEN = 'OPEN',
  CONSENTED = 'CONSENTED',
  DECLINED = 'DECLINED'
}
export class Consent {
  id: string;
  title: string;
  introduction: string;
  body: string;
  order: number;
  type: string;
  handled: boolean;
  status: string;
  version: number;
  hospital_id: string;
  patientUid: string
  language: string;
  category: string;
  date: string;
  status_at: string | Date;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.type  = item.type ? item.type.toUpperCase() : '';
    this.hospital_id = item.hospital_uid || '';
    this.id = item.key || '';
    this.language = item.language || '';
    this.version = item.version ?? '';
    this.order = item.order ?? '';
    this.date = item.date || '';
    this.category = item.category || '';
    this.status_at = item.status_at || '';
    this.status = item.status ? item.status.toUpperCase() :  '';
    this.patientUid = item.patient_uid || '';
  }

  addDetails(details) {
    this.title = details.title;
    this.introduction = details.introduction;
    this.body = details.body;
  }

  getType() {
    if (this.type) {
      return this.type.toUpperCase();
    }
  }

  isPlatformContactConsent(): boolean {
    if(this.category && this.type) {
      const upperCategory = this.category?.toUpperCase();
      const upperType = this.type?.toUpperCase();
      return (upperCategory === 'CONTACT' && upperType === 'PLATFORM');
    } else {
      return false;
    }
  }

  icon(): string {
    let iconString;

    if (this.category) {
      const upperCategory = this.category?.toUpperCase();

      switch (upperCategory) {
        case 'CONTACT':
          iconString = '/assets/svg/phone.svg';
          break;
        case 'LEGAL':
          iconString = '/assets/svg/password.svg';
          break;
        case 'PRIVACY':
          iconString = '/assets/svg/multpage.svg';
          break;
        case 'ANALYTICS':
          iconString = '/assets/svg/increase.svg';
          break;
        default:
          iconString = '/assets/svg/multpage.svg';
          break;
      }
      return iconString;
    }
  }

  isConsented(): boolean {
    return this.status?.toUpperCase() === 'CONSENTED';
  }

  isHospitalConsent(): boolean {
    return this.type === 'HOSPITAL';
  }

  isPlatformConsent(): boolean {
    return this.type === 'PLATFORM';
  }
}
