import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";
import { LocaleService } from '../../services/locale.service';
import { AppointmentService } from '../../services/appointment.service';
import { GeneralService } from '../../services/general.service';
import { DateFormat } from '../../models/date-format';
import { Appointment, AppointmentType } from '../../models/appointment';
import { SurgeryDateModalComponent } from '../../modals/surgery-date-modal/surgery-date-modal.component';
import { AppointmentModalComponent } from '../../modals/appointment-modal/appointment-modal.component';
import { Pathway } from 'src/app/models/pathway';

@Component({
  selector: 'app-surgery-dates',
  templateUrl: './surgery-dates.component.html',
  styleUrls: ['./surgery-dates.component.scss']
})
export class SurgeryDatesComponent implements OnInit {
  public appointments: Appointment[];
  public time_24_hours: boolean;
  public dateFormat: DateFormat;
  private _pathway: Pathway;

  @Input('pathway')
  public set pathway(pathway: Pathway) {
    if (this._pathway !== pathway) {
      this._pathway = pathway;

      if (this._pathway) {
        this.filterAppointments();
      }
    }
  }

  public get pathway(): Pathway {
    return this._pathway;
  }

  constructor(
    public localeService: LocaleService,
    public modalService: BsModalService,
    public appointmentService: AppointmentService,
  ) {}

  ngOnInit(): void {
    const preferences = this.localeService.getLocalePreferences();
    this.time_24_hours = preferences.locale.time_24_hours;
    this.dateFormat = preferences.dateFormat;
  }

  public filterAppointments(): void {
    this.appointments = this.sortAppointments(this._pathway.appointments.filter(item => item.type == AppointmentType.SURGERY));
  }

  public sortAppointments(appointments: Appointment[]): Appointment[] {
    return appointments.sort((a, b) => {
      let date1: any, date2: any;
      date1 = new Date(a.start_date? a.start_date : a.date);
      date2 = new Date(b.start_date? b.start_date : b.date );
      return (date1 - date2);
    });
  }

  public showDateDetails(event: Event): void {
    event.preventDefault();

    this.modalService.show(SurgeryDateModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
      })
    );
  }

  public showSurgeryDetails(event: Event, appointment: Appointment): void {
    event.preventDefault();

    this.appointmentService.getAppointment(appointment.uid).subscribe((result) => {
      const initialState = {
        appointment: result,
        dateFormat: this.dateFormat,
        time_24_hours: this.time_24_hours
      };

      this.modalService.show(AppointmentModalComponent,
        GeneralService.BsModalOptions({
          class: 'modal-dialog-centered modal-xl',
          initialState
        })
      );
    });
  }
}
