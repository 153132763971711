import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html'
})
export class InactiveComponent implements OnInit {
  constructor() { }

  ngOnInit() {}
}
