import { Component,EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html'
})
export class VideoCarouselComponent {
  @Input() videos: string[];
  @Input() controlsPosition: 'bottom' | 'sides';

  @Output() load = new EventEmitter<boolean>();

  constructor() { }

  setProgress() {
    this.load.emit();
  }
}
