<div class="bg-pattern-light">
  <div class="container">
    <div class="layout-sidebar">
      <div class="sidebar-content is-flexed responsive-sidebar">
        <div class="content-part">
          <div class="sidebar-logo d-none d-sm-block" [inlineSVG]="'/assets/svg/logo-patient.svg'"></div>
          <div class="icon icon-lg d-block d-sm-none pb-4" [inlineSVG]="'/assets/svg/patient-logo-icon.svg'"></div>
        </div>

        <div class="content-part d-block d-md-none">
          <h1 class="text-primary mb-2">
            <span class="text-nowrap">{{ 'components.flow_welcome.welcome_to' | translate }}</span>&nbsp;
            <span class="text-nowrap">{{ 'components.flow_welcome.c4t' | translate }}</span>
          </h1>
        </div>

        <div class="content-part">
          <h2 class="mb-3">{{ 'pages.flow.self_onboarding_confirm.registering_for' | translate }} <span
              class="text-info" id="text_treatment">{{ careModule }}</span> {{ 'pages.flow.self_onboarding_confirm.at' |
            translate }}
            <span class="text-info" id="text_hospital">{{hospital?.name }}</span> {{
            'pages.flow.self_onboarding_confirm.correct' | translate }}
          </h2>

          <p>
            <a id="link_code_confirm" href="" class="text-icon-link hoverable text-secondary"
              (click)="handleConfirm($event);">
              <span class="label">{{ 'pages.flow.self_onboarding_confirm.yes_correct' | translate }}</span>
              <span class="icon-end">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
              </span>
            </a>
          </p>
          <p>
            <a id="link_code_decline" href="" class="text-icon-link hoverable text-secondary"
              (click)="handleDecline($event);">
              <span class="label">{{ 'pages.flow.self_onboarding_confirm.no_not_correct' | translate }}</span>
              <span class="icon-end">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
              </span>
            </a>
          </p>
        </div>

        <div class="footer-part mt-auto">
          <app-flow-copyrights></app-flow-copyrights>
        </div>
      </div>
    </div>

    <div class="layout-body fixed with-background d-none d-sm-block">
      <div class="body-content">
        <app-flow-welcome></app-flow-welcome>
      </div>
    </div>
  </div>
</div>
