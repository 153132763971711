import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Hospital } from '../models/hospital';
import { Consent } from '../models/consent';
import { ConsentService } from './consent.service';
import { CareModule } from '../models/care-module';
import { PatientService } from './patient.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import { LanguageCode } from '../models/language-code';


@Injectable({
  providedIn: 'root'
})
export class SelfRegistrationService {
  private readonly selfRegistrationUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly consentService: ConsentService,
    private readonly patientService: PatientService,
    public translateService: TranslateService,
    public apiService: ApiService
  ) {
    this.selfRegistrationUrl = environment.selfRegistrationUrl;
  }

  selfRegistrationHttpOptions(code: string, language: string): {
    headers: HttpHeaders
  } {
    return {
      headers: new HttpHeaders({
        Authorization: `Basic ${btoa(`${environment.auth_username}:${environment.auth_password}`)}`,
        'X-Hospital-TimedCode': code.replace('-', ''),
        'X-Hospital-UserLanguage': language,
        'X-ApplicationUid': environment.auth_username
      })
    };
  }

  startSelfRegistration(code: string, language: LanguageCode): Observable<{ hospital: Hospital, consents: Consent[], careModule: CareModule }> {
    return new Observable((observer) => {
      const httpOptions = this.selfRegistrationHttpOptions(code, language.value);
      const url = this.selfRegistrationUrl + '/self-registration';

      this.http.get(url, httpOptions).subscribe((result: { hospital: any, consents: any, care_module: any }) => {
        const hospital: Hospital = new Hospital(result.hospital);
        const consents: Array<Consent> = this.mapConsents(result.consents);
        this.getCareModuleTranslation(new CareModule(result.care_module), language).subscribe(careModule => {
          observer.next({ hospital, consents, careModule });
          observer.complete();
        });
      },
        error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  validateConsents(consents: Array<Consent>, code: string, language: string): Observable<any> {
    return new Observable((observer) => {
      const httpOptions = this.selfRegistrationHttpOptions(code, language);
      const url = this.selfRegistrationUrl + '/self-registration/validate-consents';
      const params = { consents: this.postableConsents(consents) };

      this.http.post(url, params, httpOptions).subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      });
    });
  }

  postSelfRegistration(consents: Array<Consent>, data, code: string, language: string): Observable<any> {
    return new Observable((observer) => {
      const httpOptions = this.selfRegistrationHttpOptions(code, language);
      const url = this.selfRegistrationUrl + '/self-registration';

      if (data.profile_picture && data.profile_picture.data) {
        data.profile_picture = data.profile_picture.data.split(',')[1];
      }
      const params = {
        patient: data,
        consents: this.postableConsents(consents)
      };

      this.http.post(url, params, httpOptions).subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      });
    });
  }

  verifyMobileNumber(mobile_number, code: string, language: string): Observable<any> {
    const httpOptions = this.selfRegistrationHttpOptions(code, language);
    const url = this.selfRegistrationUrl + '/self-registration/verify-mobile-number';
    return this.http.post(url, mobile_number, httpOptions);
  }

  getCareModuleTranslation(careModule: CareModule, language: LanguageCode): Observable<any> {
    return new Observable((observer) => {
      this.apiService.cmsRegionGet(careModule.name, language.locale).subscribe(result => {
        const careModuleName = result[0]?.caremodules[careModule.name.key];
        observer.next(careModuleName);
        observer.complete();
      });
    });
  }

  mapConsents(result: Array<any>): Array<Consent> {
    let consents: Consent[] = [];
    for (const item of result) {
      const consent = this.mapConsent(item);
      consents.push(consent);
    }

    consents = this.sortConsents(consents);

    return consents;
  }

  mapConsent(item): Consent {
    const consent = new Consent();
    consent.language = item.language;
    consent.id = item.key;
    consent.type = item.type;
    consent.version = item.version;
    consent.category = item.category;
    consent.order = item.order;
    consent.hospital_id = item.hospital_uid;

    return consent;
  }

  sortConsents(consents: Array<Consent>): Array<Consent> {
    consents.sort((a, b) => {
      const consentA = a.order;
      const consentB = b.order;
      if (consentA < consentB) {
        return -1;
      }
      if (consentA > consentB) {
        return 1;
      }

      return 0;
    });

    return consents;
  }

  postableConsents(consents: Array<Consent>): object {
    return consents.map(consent => this.postableConsent(consent));
  }

  postableConsent(consent: Consent): Object {
    return {
      type: consent.type,
      key: consent.id,
      language: consent.language,
      status: consent.status,
      version: consent.version,
      category: consent.category,
      hospital_uid: consent.hospital_id,
      platform_application_uid: environment.auth_username
    };
  }
}

