<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  
  <h2>{{ 'modals.restrict_processing_data.restrict_processing' | translate }}</h2>

  <form [formGroup]="form" id="form" [ngClass]="{'validation-visible': validationVisible}">

    <div *ngIf="!isVerificationStep">
      <p>{{ 'modals.restrict_processing_data.gdpr_question' | translate }}</p>

      <div class="custom-control custom-radio mb-1">
        <input type="radio" class="custom-control-input" id="radioSexMale" formControlName="reason" value="WRONG_DATA" (change)="reasonProvided()">
        <label class="custom-control-label" for="radioSexMale">{{ 'modals.restrict_processing_data.data_accuracy' | translate }}</label>
      </div>

      <div class="custom-control custom-radio mb-1">
        <input type="radio" class="custom-control-input" id="radioSexFemale" formControlName="reason" value="MARKETING_ABUSE" (change)="reasonProvided()">
        <label class="custom-control-label" for="radioSexFemale">{{ 'modals.restrict_processing_data.unlawful_processing' | translate }}</label>
      </div>

      <div class="custom-control custom-radio mb-1">
        <input type="radio" class="custom-control-input" id="radioSexFemale" formControlName="reason" value="LEGAL" (change)="reasonProvided()">
        <label class="custom-control-label" for="radioSexFemale">{{ 'modals.restrict_processing_data.legal_claim' | translate }}</label>
      </div>

      <div class="form-group styled floating-label mt-4">
        <input type="text" id="inputExplanation" class="form-control w-100" placeholder="Your explanation" formControlName="explanation">
        <label for="inputExplanation" class="required">{{ 'modals.restrict_processing_data.your_explanation' | translate }}</label>
      </div>
    </div>

    <div *ngIf="isVerificationStep">
      <p>{{ 'modals.restrict_processing_data.restrict_processing_info' | translate }}</p>
      <p>{{ 'modals.restrict_processing_data.provide_pw' | translate }}</p>

      <div class="form-group styled floating-label">
        <input type="password" id="inputPassword" class="form-control w-100" [placeholder]="('form.labels.your_pw' | translate)" formControlName="password">
        <label for="inputPassword">{{ 'form.labels.your_pw' | translate }}</label>
        <!-- in case of local error feedback concerning the password field -->
        <p class="validation-feedback" *ngIf="form?.controls['password']?.errors">{{ 'form.feedback.pw_invalid' | translate }}</p>
        <!-- in case of server error feedback concerning the password -->
        <p class="validation-feedback general-feedback" *ngIf="isWrongPassword">{{ 'form.feedback.pw_invalid' | translate }}</p>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary m-start-3" (click)="handleContinue()" *ngIf="!isVerificationStep" [disabled]="!isInputProvided()">
      <span>{{ 'action.continue' | translate }}</span>
    </button>
    <button class="btn btn-secondary m-start-3" (click)="handleDelete()" [ngClass]="{'loader': isRequesting}" *ngIf="isVerificationStep">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isRequesting"></em></span>
      <span>{{ 'modals.restrict_processing_data.confirm_delete_account_data' | translate }}</span>
    </button>
  </div>
</div>
