import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Conversation } from "../../models/conversation";
import { ConversationItem } from "../../models/conversation_item";
import { ConversationService } from "../../services/conversation.service";

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html'
})
export class AudioPlayerComponent implements AfterViewInit{
  public progressTime = 0;
  public progressPercent = 0;
  public playing = false;

  @ViewChild('audioElement', { static: false }) public _audioRef:  ElementRef;

  private audio: HTMLMediaElement;

  constructor(
    public conversationService: ConversationService,
  ) {
  }

  @Input() inputClass: string;
  @Input() convo: Conversation;

  //
  // convoItem - START
  //
  @Input('convoItem')
  public set convoItem(convoItem: ConversationItem) {
    this._convoItem = convoItem;
    this.progressTime = Math.round(this.convoItem?.audio_length);
  }
  public get convoItem(): ConversationItem {
    return this._convoItem;
  }
  private _convoItem: ConversationItem;
  //
  // convoItem - END
  //

  public source: string;


  pause(): void {
    if (this.audio) {
      this.audio.pause();
      this.playing = false;
    }
  }

  play() {
    if (this.audio) {
      if (this.audio.readyState >= 2) {
        this.audio.play();
        this.playing = true;
      }
    }
  }

  seek(percent) {
    const currentTime = Math.round(this.audio.duration * (percent / 100));
    this.audio.currentTime = currentTime;
    this.progressPercent = percent;
  }

  startSeek(event) {
    // When seeking the audio needs to be paused.
    this.audio.pause();
  }

  stopSeek(event) {
    // Restart playing is the audio was playing.
    if (this.playing) {
      this.audio.play();
    }
  }

  fetchSource() {
    if(this.convo && this.convoItem) {
      this.conversationService.getDownloadLink(this.convo.uid, this.convoItem.id).subscribe(link => {
        this.source = link;
      });
    }
  }

  public ngAfterViewInit() {
    if(this._audioRef) {
      this.audio = this._audioRef.nativeElement;
      this.audio.addEventListener("timeupdate", (currentTime)=>{
        this.progressTime = this.audio.currentTime;
        this.progressPercent = Math.round((this.audio.currentTime / this.audio.duration) * 100);
      });

      this.audio.addEventListener("ended", (event)=>{
        this.playing = false;
      });
    }

    this.fetchSource();
  }
}
