<div class="modal-body">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  <h2>{{ title }}</h2>
  <p class="m-0">{{ description }}</p>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleNo()">{{ no || 'Cancel' }}</button>
    <button class="btn btn-secondary m-start-3" (click)="handleYes()" [ngClass]="{'loader': showYesLoading}">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="showYesLoading"></em></span>
      <span>{{ yes || 'Yes' }}</span>
    </button>
  </div>
</div>