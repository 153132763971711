<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/settings" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.settings.privacy.privacy_settings' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <h2>{{ 'pages.default.settings.privacy.export_personal_data' | translate }}</h2>
    <div style="max-width: 650px;">
      <p class="text-muted">{{ 'pages.default.settings.privacy.export_personal_data_info' | translate }}</p>
    </div>
    <button class="btn btn-secondary" (click)="showExportPersonalDataModal($event)">{{ 'pages.default.settings.privacy.export_data' | translate }}</button>

    <hr class="my-5"/>

    <h2>{{ 'pages.default.settings.privacy.restrict_processing_personal_data' | translate }}</h2>
    <div style="max-width: 650px;">
      <p class="text-muted">{{ 'pages.default.settings.privacy.export_data_info' | translate }}</p>
    </div>
    <button class="btn btn-secondary" (click)="showRestrictProcessingDataModal()">{{ 'pages.default.settings.privacy.restrict_processing_personal_data' | translate }}</button>

    <hr class="my-5"/>

    <h2>{{ 'pages.default.settings.privacy.delete_acc_personal_data' | translate }}</h2>
    <div style="max-width: 650px;">
      <p class="text-muted">{{ 'pages.default.settings.privacy.delete_acc_personal_data_info' | translate }}</p>
    </div>
    <button class="btn btn-secondary" (click)="showDeleteAccountModal()">{{ 'pages.default.settings.privacy.delete_acc' | translate }}</button>
  </div>
</div>
