export class DialCode {
  public code: string;
  public country: string;

  // params for select
  public label: string;
  public value: string;

  constructor(countryEnum = '', code = '') {
    this.country  = countryEnum;
    this.code  = code;

    // params for select
    this.label = `${this.country} (+${this.code})`;
    this.value = this.country;
  }
}
