export enum BannerType {
  INFORMATIONAL = 'Informational',
  WARNING = 'Warning',
  CRITICAL = 'Critical',
  MAINTENANCE = 'Maintenance'
}

export class Banner {
  uid: string;
  active: boolean;
  updated_at: string;
  description: string;
  modal_content?: string;
  more_information?: string;
  title: string;
  type: BannerType;


  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item: any) {
    this.uid = item.uid;
    this.active = item.active;
    this.updated_at = item.updated_at;
    this.description = item.description;
    this.modal_content = item.modal_content;
    this.more_information = item.more_information;
    this.title = item.title;
    this.type = BannerType[item.type?.toUpperCase()];
  }
}
