<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <p class="m-0">
        <a class="text-secondary font-weight-bold d-flex align-items-center" [routerLink]="backButtonData?.url">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to' | translate }} <span>{{ 'pages.default.help_center.help_center'
              | translate }}</span></span>
        </a>
      </p>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="!article">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="article">
    <div class="article-content">
      <!-- content-head - start -->
      <!-- (should always be here, is not in the repeater loop) -->
      <div class="content-head">
        <!-- <p class="small text-muted mb-2 mb-lg-3" *ngIf="article?.disclaimer"><i>{{ article?.disclaimer}}</i></p> -->
        <h1 class="mb-1">{{ article?.title }}</h1>
        <p class="introduction">{{ article?.description }}</p>
      </div>
      <!-- content-head - end -->

      <ng-container *ngFor="let paragraph of article?.paragraphs">
        <div *ngIf="paragraph.isText" [innerHtml]="paragraph.text" class="content-partial"></div>

        <!-- PDF -->
        <div class="content-pdf" *ngIf="paragraph.isPdf">
          <div class="single-item-block m-0">
            <div class="block-wrapper p-start-3 p-end-1">
              <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/page.svg'"></span>
            </div>
            <div class="block-wrapper">
              <p class="label">{{ paragraph.file?.title}}</p>
              <p class="value">{{ 'pages.default.patient_materials.pdf_file' | translate}}</p>
            </div>
            <a href="{{ paragraph.file?.url }}" target="_blank" class="block-action m-start-auto" rel="noopener" >
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/download.svg'"></span>
            </a>
          </div>
        </div>
        <!-- /PDF -->

        <!-- Video -->
        <div class="content-video" *ngIf="paragraph.isVideo && paragraph?.videos">
          <app-video-carousel class="position-absolute w-100 h-100" [videos]="paragraph.videos"></app-video-carousel>
        </div>
        <!-- /Video -->

        <!-- Audio -->
        <div class="content-audio" *ngIf="paragraph.isAudio" >
          <div class="audio-player">
            <p class="title">{{ paragraph.file?.title}}</p>
            <audio preload="auto" autobuffer controls controlsList="nodownload">
              <source [src]="paragraph.file?.url" />
            </audio>
          </div>
        </div>
        <!-- /Audio -->

        <!-- Image -->
        <div class="content-image" *ngIf="paragraph.isImage && paragraph?.file">
          <img [src]="paragraph.file?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp'" class="w-100" />
        </div>
        <!-- /Image -->

        <!-- Banner  -->
        <!-- Banner Right -->
        <div *ngIf="paragraph.type === 'banner' && paragraph.image_position === 'Right'" class="content-feature">
          <div class="feature-body d-flex flex-column justify-content-between">
            <div class="title" [innerHtml]="paragraph?.text"></div>

            <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="text-white" *ngIf="paragraph?.link?.href">
              <span class="align-middle">{{ paragraph?.link.title }}</span>
              <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>

          <div class="feature-image" [style.background-image]="'url(' + paragraph.image?.url + '?width=920&disable=upscale&format=pjpg&auto=webp' + ')'"></div>
        </div>
        <!-- /Banner Right -->

        <!-- Banner Left -->
        <div *ngIf="paragraph.type === 'banner' && paragraph.image_position === 'Left'" class="content-feature">
          <div class="feature-image" [style.background-image]="'url(' + paragraph.image?.url + '?width=920&disable=upscale&format=pjpg&auto=webp' + ')'"></div>

          <div class="feature-body d-flex flex-column justify-content-between">
            <div class="title" [innerHtml]="paragraph.text"></div>

            <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="text-white" *ngIf="paragraph?.link?.href">
              <span class="align-middle">{{ paragraph?.link.title }}</span>
              <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
        </div>
        <!-- /Banner Left -->

        <!-- Banner Top -->
        <div *ngIf="paragraph.type === 'banner'  && paragraph.image_position === 'Top'" class="content-feature-top">
          <div class="feature-image" [style.background-image]="'url(' + paragraph.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ')'"></div>
          <div class="feature-body bg-primary">
            <div class="title" [innerHtml]="paragraph.text"></div>

            <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="text-white" *ngIf="paragraph?.link?.href">
              <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href  }}</span>
              <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
        </div>
        <!-- /Banner Top -->

        <!-- Banner Bottom -->
        <div *ngIf="paragraph.type === 'banner'  && paragraph.image_position === 'Bottom'" class="content-feature-top">
          <div class="feature-body bg-primary">
            <div class="title" [innerHtml]="paragraph.text"></div>

            <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="text-white" *ngIf="paragraph?.link?.href">
              <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href  }}</span>
              <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
          <div class="feature-image" [style.background-image]="'url(' + paragraph.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ')'"></div>
        </div>
        <!-- /Banner Bottom -->
        <!-- /Banner -->

      </ng-container>

      <!-- content-footer - start -->
      <div class="content-footer" *ngIf="article?.disclaimer">
        <p class="small text-muted mb-2 mb-lg-3"><i>{{ article?.disclaimer}}</i></p>
      </div>
      <!-- content-footer - end -->
    </div>

    <!-- extra space - start -->
    <div class="py-4"></div>
    <!-- extra space - end -->

    <div class="related-articles" *ngIf="article?.referenceUids && relatedArticles?.length > 0">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden py-2">
          <h2 class="text-truncate">{{ 'pages.default.help_center.more_like_this' | translate }}</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-4 mb-2" *ngFor="let article of relatedArticles">
          <div class="card h-100">
            <a href="" (click)="goToArticle($event, article)" class="card-cover"
              [style.background-image]="'url(' + article?.thumbnail?.image?.url + '?width=920&disable=upscale&format=pjpg&auto=webp' + ')'" rel="noopener">
              <div class="cover-meta">
                <div class="meta-category"
                  *ngIf="article?.thumbnail?.icon.includes('Video') || article?.thumbnail?.icon.includes('Audio') ">
                  <span [inlineSVG]="'/assets/svg/play.svg'"></span>
                </div>
                <div class="meta-category" *ngIf="article?.thumbnail?.icon.includes('PDF')">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/page.svg'"></span>
                </div>
              </div>
            </a>
            <div class="card-body">
              <h3 class="m-0">{{ article.title }}</h3>
              <p class="m-0">{{ article.description }}</p>
            </div>
            <div class="card-footer">
              <a href="" (click)="goToArticle($event, article)" class="btn btn-secondary m-start-auto">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
