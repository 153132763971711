import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpcenterArticle } from '../../../../models/helpcenter-article';
import { HelpcenterSection } from '../../../../models/helpcenter-section';
import { DataService } from '../../../../services/data.service';
import { HelpcenterService } from '../../../../services/helpcenter.service';

@Component({
  selector: 'app-help-center-articles-overview',
  templateUrl: './help-center-articles-overview.component.html'
})
export class HelpCenterArticlesOverviewComponent implements OnInit {
  public helpcenterSection: HelpcenterSection;
  public isLoadingArticles = false;
  public articles = [];

  constructor(
    public helpcenterService: HelpcenterService,
    public dataService: DataService,
    public router: Router,
  ) {

  }

  ngOnInit(): void {
    this.helpcenterSection = this.dataService.get(DataService.HelpcenterSectionSelected );
    this.articles = this.helpcenterSection.articles;
  }


  goToArticleDetail(event, article: HelpcenterArticle) {
    event.preventDefault();
    this.dataService.set(DataService.HelpcenterArticleSelected, article);
    this.router.navigate(['/help-center', 'articles', article.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }}
    );
  }
}
