interface PermissionPatient {
  uid: string;
  first_name: string;
  last_name: string;
  permissions: string[];
}

export interface PermissionHospital {
  patient: PermissionPatient;
  hospital_uid: string;
  name: string;
  site: any;
}

export class Permissions {
  public hospitals: PermissionHospital[];

  constructor(item?) {
    if (!item) {
      return;
    }

    if (item.hospitals) {
      this.hospitals = item.hospitals;
    } else {
      this.hospitals = [];
    }
  }

  getHospital(uid: string): PermissionHospital {
    const found = this.hospitals.filter(item => {
      return String(item.hospital_uid) === String(uid);
    });

    if (found && found.length) {
      return found[0];
    } else {
      return undefined;
    }
  }

  getPatient(uid: string): PermissionPatient {
    let found;

    this.hospitals.forEach(hospitalData => {
      if (String(hospitalData.patient?.uid) === String(uid)) {
        found = hospitalData.patient;
      }
    });

    return found;
  }

  doesPatientHasPermission(uid: string, rule) {
    const patient = this.getPatient(uid);

    if (!patient || !patient.permissions) {
      return false;
    }

    return patient.permissions.includes(rule);
  }
}