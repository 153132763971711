<div class="video-carousel">
  <div *ngIf="videos?.length === 1">
    <iframe [src]="videos[0] | videosafe" allow="encrypted-media" (load)="setProgress()"></iframe>
  </div>

  <carousel *ngIf="videos?.length > 1" class="carousel-container"
    [ngClass]="{'controls-bottom': controlsPosition === 'bottom'}" [noWrap]="false" [interval]="0">
    <slide *ngFor="let video of videos">
      <iframe [src]="video | videosafe" allow="encrypted-media" (load)="setProgress()"></iframe>
    </slide>
  </carousel>
</div>
