export class LanguageCode {
  public value: string;
  public locale: string;
  public reading_direction: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.value  = item.value;
    this.locale  = item.locale;
    this.reading_direction = item.reading_direction || 'LEFT_TO_RIGHT';
  }

  get translationKey():string {
    return `shared.languages.${this.value?.toLowerCase()}`;
  }

  get isRTL() {
    return this.reading_direction === 'RIGHT_TO_LEFT';
  }

  get isHebrew(): boolean {
    return this.locale === 'he-il';
  }
}
