import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {ApiService} from '../services/api.service';
import {AuthenticationService} from '../services/authentication.service';
import {LoginService} from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class CodeService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    private readonly loginService: LoginService
) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  validateCode(code) {
    return this.loginService.loginWithCode(code);
  }
}
