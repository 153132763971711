import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {
  public code: number;

  constructor(
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => this.paramsChanged(params));
  }

  paramsChanged(params) {
    if(params.code) {
      this.code = Number(params.code);
    }
  }
}
