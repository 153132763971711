export class MaterialParagraph {
  public type: string;
  public image_position: string;
  public image: any;
  public file: any;
  public link: any;
  public text: string;
  public videos: string[];

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    const keys = Object.keys(item);
    this.type = keys[0];
    const values = item[this.type]

    if(this.type === "text") {
      this.text = values["text"];
    }

    if(this.type === "banner") {
      this.text = values["text"];
      this.image = values["image"];
      this.image_position = values["image_position"];
      this.link = values["link"];
    }

    if(this.type === "image" || this.type === "audio" || this.type === "pdf") {
      this.file = values["file"];
    }

    if (this.type === 'video') {
      if (values.file?.items?.length) {
        this.videos = values.file.items;
      } else {
        this.videos = [values.file];
      }
    }
  }
}
