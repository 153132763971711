<div class="layout-header-pusher header-back"></div>
<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/help-center" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span>{{ 'action.back_to' | translate }} <span>{{ 'pages.default.help_center.help_center' | translate }}</span></span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ helpcenterSection?.title }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="faq-block" *ngFor="let category of faqCategories">
      <app-faq-block [category]="category" [section]="helpcenterSection" (goToFaq)="goToFaq($event)"></app-faq-block>
    </div>
  </div>
</div>
