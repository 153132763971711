import { TranslationInterface } from '../interfaces/translation.interface';

export enum FFUiStyleOption {
  RADIO = 'RADIO',
  DROPDOWN = 'DROPDOWN',
  TILE = 'TILE',
  YESNO = 'YESNO',
  // TODO: Hide AFSQ-1565
  // CHECKBOX = 'CHECKBOX',
  SLIDER_HORIZONTAL = 'SLIDER_HORIZONTAL',
  SLIDER_VERTICAL = 'SLIDER_VERTICAL',
  PICKER_WHEEL = 'PICKER_WHEEL',
}

export enum FFUiValueStyleOption {
  VALUE = 'VALUE',
  ICON = 'ICON',
  VALUE_ICON = 'VALUE_ICON',
  VALUE_LABEL = 'VALUE_LABEL' /** only possible for long/double */
}

export enum FFUiIconSource {
  MDI = 'MDI:',
  MS = 'MS:',
  U = 'U'
}

export enum MeasuringUnit {
  CM = 'CM',
  KG = 'KG'
}

export type FFUiColor = {
  key: string,
  color: string
};

export type FFUiLabel = {
  key: string,
  label: TranslationInterface,
  translationKey: string
};

export type FFUiIcon = {
  key: string,
  icon: string,
  type: FFUiIconSource
};

export type FFUiSliderStepInterval = {
  main: number,
  sub?: number
};

export class FormFieldUiConfig {
  protected readonly colorTriggers = ['enum_color_', 'number_color_'];
  protected readonly iconTriggers = ['enum_icon_', 'number_icon_'];
  protected readonly labelTriggers = ['number_label_'];

  public ui_style: FFUiStyleOption;
  public ui_value_style: FFUiValueStyleOption;
  public colors: Array<FFUiColor>;
  public icons: Array<FFUiIcon>;
  public labels: Array<FFUiLabel>;
  public measuring_unit: MeasuringUnit | string; // This unit is for giving all values the same label f.e. 'cm'. (picker wheel)

  public number_max: number;
  public number_min: number;
  public number_slider_reverse: boolean;
  public number_steps_interval: FFUiSliderStepInterval;

  constructor(item?: any) {
    if (item) {
      this.ui_style = item.enum_style ?? item.number_style;
      this.ui_value_style = item.enum_value_style ?? item.number_value_style;

      this.number_max = item.number_max;
      this.number_min = item.number_min;
      this.number_slider_reverse = item.number_slider_reverse;


      if (item.number_steps_interval) {
        this.number_steps_interval = this.mapStepsInterval(item.number_steps_interval);
      }

      if (this.inputHasColors(item)) {
        this.colors = this.mapColors(item);
      }

      if (this.inputHasIcons(item)) {
        this.icons = this.mapIcons(item);
      }

      if (this.inputHasLabels(item)) {
        this.labels = this.mapLabels(item);
      }

      if (this.ui_style === FFUiStyleOption.PICKER_WHEEL && this.labels) {
        this.measuring_unit = item.number_label_0;
      }
    }
  }

  get hasColors(): boolean {
    return !!this.colors?.length;
  }

  get hasIcons(): boolean {
    return !!this.icons?.length;
  }

  get showValue(): boolean {
    return this.ui_value_style !== FFUiValueStyleOption.ICON;
  }

  get showIcon(): boolean {
    return this.ui_value_style === FFUiValueStyleOption.ICON || this.ui_value_style === FFUiValueStyleOption.VALUE_ICON;
  }

  get showLabel(): boolean {
    return this.ui_value_style === FFUiValueStyleOption.VALUE_LABEL;
  }



  mapStepsInterval(input: string): FFUiSliderStepInterval {
    const parts = input.split(',');

    if (parts.length > 2) { return; }

    const main = parseInt(parts[0].trim(), 10);
    if (isNaN(main)) { return; }

    const sub = parts.length === 2 ? parseInt(parts[1].trim(), 10) : undefined;
    if (sub !== undefined && isNaN(sub)) { return; }

    return { main, sub };
  }

  inputHasColors(input: any): boolean {
    return Object.keys(input).some(key => this.colorTriggers.some(trigger => key.startsWith(trigger)));
  }

  mapColors(input: any): Array<FFUiColor> {
    return Object.keys(input)
      .filter(key => this.colorTriggers.some(trigger => key.startsWith(trigger)))
      .map(key => {
        const trigger = this.colorTriggers.find(trigger => key.startsWith(trigger));
        if (trigger) {
          return {
            key: key.replace(trigger, ''),
            color: `${input[key]}`
          };
        }
        return null;
      })
      .filter(color => color !== null);
  }

  getColorFor(key: string): string | null {
    if (!this.colors?.length) { return null; }

    const color = this.colors.find(color => color.key.toLowerCase() === key.toLowerCase())?.color;
    return color ? color : null;
  }

  inputHasIcons(input: any): boolean {
    return Object.keys(input).some(key => this.iconTriggers.some(trigger => key.startsWith(trigger)));
  }

  mapIcons(input: any): Array<FFUiIcon> {
    return Object.keys(input)
      .filter(key => this.iconTriggers.some(trigger => key.startsWith(trigger)))
      .map(key => {
        const trigger = this.iconTriggers.find(trigger => key.startsWith(trigger));
        const iconWithoutSourcePrefix = (value: string, prefix: FFUiIconSource): string =>
          value.substring(prefix.length);

        if (trigger) {
          const k = key.replace(trigger, '');
          let icon: string;
          let type: FFUiIconSource;

          const prefix =
            input[key].startsWith(FFUiIconSource.MDI.toLowerCase()) ? FFUiIconSource.MDI :
              input[key].startsWith(FFUiIconSource.MS.toLowerCase()) ? FFUiIconSource.MS :
                input[key].startsWith(FFUiIconSource.U) ? FFUiIconSource.U : null;

          switch (prefix) {
            case FFUiIconSource.MDI:
              type = FFUiIconSource.MDI;
              icon = iconWithoutSourcePrefix(input[key], FFUiIconSource.MDI);
              break;
            case FFUiIconSource.MS:
              type = FFUiIconSource.MS;
              icon = iconWithoutSourcePrefix(input[key], FFUiIconSource.MS).toLowerCase();
              break;
            case FFUiIconSource.U:
              type = FFUiIconSource.U;
              icon = `&#X${iconWithoutSourcePrefix(input[key], FFUiIconSource.U)};`;
              break;
          }

          return {
            key: k,
            icon,
            type
          };
        }

        return null;
      })
      .filter(icon => icon !== null);
  }

  getIconFor(key: string): any {
    if (!this.icons?.length) { return null; }

    const icon = this.icons.find(icon => icon.key.toLowerCase() === key.toLowerCase());
    return icon ? icon : null;
  }

  inputHasLabels(input: any): boolean {
    return Object.keys(input).some(key => this.labelTriggers.some(trigger => key.startsWith(trigger)));
  }

  mapLabels(input: any): Array<FFUiLabel> {
    return Object.keys(input)
      .filter(key => this.labelTriggers.some(trigger => key.startsWith(trigger)))
      .map(key => {
        const trigger = this.labelTriggers.find(trigger => key.startsWith(trigger));
        if (trigger) {
          const label = input[key] as TranslationInterface;

          return {
            key: key.replace(trigger, ''),
            label,
            translationKey: `shared.${label.region}.${label.key}`
          };
        }
        return null;
      })
      .filter(label => label !== null);
  }
}
