<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back"></div>

  <div class="layout-header header-back">
    <div class="header-content">
      <div class="container">
        <p>
          <a href="" routerLink="/dashboard" class="text-secondary font-weight-bold d-flex align-items-center">
            <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
            <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            <span>{{ 'action.back_to_overview' | translate }}</span>
          </a>
        </p>
      </div>

      <div class="container">
        <h1 *ngIf="filter === 'QUESTIONNAIRE'">{{ 'pages.default.dashboard.essential-forms' | translate }}</h1>
        <h1 *ngIf="filter === 'CHECKLIST'">{{ 'pages.default.dashboard.checklists' | translate }}</h1>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!queryLists?.length">
        <span [inlineSVG]="'/assets/svg-color/forms.svg'" *ngIf="filter === 'QUESTIONNAIRE'"></span>
        <h3 *ngIf="filter === 'QUESTIONNAIRE'">{{ 'pages.default.essential_forms.no_essential_forms_yet' | translate }}</h3>
        <span [inlineSVG]="'/assets/svg-color/approve.svg'" *ngIf="filter === 'CHECKLIST'"></span>
        <h3 *ngIf="filter === 'CHECKLIST'">{{ 'pages.default.checklists.no_checklists_yet' | translate }}</h3>
      </div>
      <div class="row">
        <div class="col-4 mb-2" *ngFor="let form of queryLists">
          <div class="card h-100" [ngClass]="{'card-muted': grayedOut(form)}">
            <div class="card-body card-body">

              <p class="text-muted small mb-1">
                <span *ngIf="form.status === QueryListStatus.COMPLETED">{{ 'pages.default.query_lists.submitted_on' | translate }} {{ form.completed_at | timeZoneDate }}</span>
                <span *ngIf="form.status === QueryListStatus.TIMED_OUT">{{ 'pages.default.query_lists.due_at' | translate : {value:form.due_days_ago?.toString() } }}</span>
              </p>

              <h3>{{ form.title | translateHelp | translate }}</h3>

              <div class="user-compact mb-2">
                <app-avatar [profilePicture]="form.assignee?.profilePicture"></app-avatar>

                <div class="user-compact-info">
                  <p class="title text-truncate">{{ form.assignee?.getFullName() }}</p>
                  <p *ngIf="form.assignee?.uid === patientUid">{{ 'pages.default.query_lists.you' | translate }}</p>
                  <p>{{ form.assignee?.job_title }}</p>
                </div>
              </div>
            </div>
            <div class="card-footer">

              <div class="font-weight-semibold d-flex align-items-center text-info" *ngIf="form.status === QueryListStatus.NEW">
                <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/dot.svg'"></span>
                {{ 'pages.default.query_lists.status.new' | translate }}
              </div>

              <div class="font-weight-semibold d-flex align-items-center" *ngIf="form.status === QueryListStatus.SAVED">
                <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/play.svg'"></span>
                {{ 'pages.default.query_lists.status.saved' | translate }}
              </div>

              <div class="font-weight-semibold d-flex align-items-center text-muted" *ngIf="form.status === QueryListStatus.COMPLETED">
                <span class="m-end-1 " [inlineSVG]="'/assets/svg/check-free.svg'"></span>
                {{ 'pages.default.query_lists.status.completed' | translate }}
              </div>

              <div class="font-weight-semibold d-flex align-items-center text-muted" *ngIf="form.status === QueryListStatus.TIMED_OUT">
                <span class="rtl-mirrored-inline-svg m-end-1 " [inlineSVG]="'/assets/svg/close.svg'"></span>
                {{ 'pages.default.query_lists.status.timed_out' | translate }}
              </div>


              <button class="btn btn-secondary m-start-auto" (click)="evalUserTask(form)">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'" *ngIf="!isEvaluating"></span>
                <em class="spinner-border spinner-border-sm" *ngIf="isEvaluating"></em>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto" id="total-patients">{{ getLabelForTotalPatients() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
