import { Component, OnInit } from '@angular/core';
import { DataService } from './../../../services/data.service';
import { Hospital } from './../../../models/hospital';
import { Router } from '@angular/router';
import { CareModule } from '../../../models/care-module';

@Component({
  selector: 'app-self-onboarding-confirm',
  templateUrl: './self-onboarding-confirm.component.html',
  styleUrls: ['./self-onboarding-confirm.component.scss']
})
export class SelfOnboardingConfirmComponent implements OnInit {
  public hospital: Hospital;
  public careModule: CareModule;

  constructor(
    public dataService: DataService,
    public router: Router
  ) { }

  ngOnInit() {
    this.hospital = this.dataService.get(DataService.KeySelfOnboardingHospital);
    this.careModule = this.dataService.get(DataService.KeySelfOnboardingCareModule);

    if (!this.hospital || !this.careModule) {
      this.handleDecline();
    }

  }

  handleConfirm(event) {
    event.preventDefault();
    this.router.navigateByUrl('register/consent');
  }

  handleDecline(event?) {
    if(event) {
      event.preventDefault();
    }

    this.dataService.clearSelfOnboardingKeys();
    this.router.navigateByUrl('register');
  }
}
