<div class="notice always-on-top">
  <div class="logo mb-6 mb-xs-9 mb-lg-11 mb-xl-13" [inlineSVG]="'/assets/svg/logo-patient.svg'"></div>

  <div class="position-relative">
    <h1 class="display-1 text-secondary">
      <span>{{ 'pages.general.generic_error.inactive' | translate }}</span>
      <em [inlineSVG]="'/assets/svg-color/caution.svg'" class="icon m-start-2"></em>
    </h1>
    <h2>{{ 'pages.general.generic_error.inactive_text1' | translate }}</h2>
  </div>

  <div class="mt-auto">
    <app-flow-copyrights></app-flow-copyrights>
  </div>
</div>
