import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BackButtonData } from '../../../../models/back-button-data';
import { HelpcenterArticle } from '../../../../models/helpcenter-article';
import { DataService } from '../../../../services/data.service';
import { GeneralService } from '../../../../services/general.service';
import { HelpcenterService } from '../../../../services/helpcenter.service';

@Component({
  selector: 'app-help-center-articles-detail',
  templateUrl: './help-center-articles-detail.component.html',
  styleUrls: ['./help-center-articles-detail.component.scss']
})
export class HelpCenterArticlesDetailComponent implements OnInit {
  public article: HelpcenterArticle;
  public relatedArticles: HelpcenterArticle[];
  public backButtonData: BackButtonData;

  constructor(
    public dataService: DataService,
    public helpcenterService: HelpcenterService,
    public router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public generalService: GeneralService
    ) { }

  ngOnInit(): void {
    this.article = this.dataService.get(DataService.HelpcenterArticleSelected);

    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.handleQueryParams(queryParams);
    });

    if (this.article.referenceUids) {
      this.helpcenterService.getArticles(this.article.referenceUids).subscribe((articles: HelpcenterArticle[]) => {
        this.relatedArticles = articles;
      });
    }
  }

  goToArticle(event, article: HelpcenterArticle) {
    event.preventDefault();
    this.article = article;
    this.router.navigateByUrl(`/help-center/articles/${article.uid}`);
  }

  handleQueryParams(queryParams) {
    const backToUrl = queryParams['back-to-url'];

    if (backToUrl) {
      this.backButtonData = this.generalService.defineBackButton(backToUrl);
    }
  }
}
