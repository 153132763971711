<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a routerLink="/settings" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-d-none m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="d-none rtl-d-inline m-end-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          <span>{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.settings.consents_overview.consents' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="!consentsLoaded">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="consentsLoaded">
    <div class="single-item-block mb-2 item-clickable" *ngFor="let consent of consents" (click)="gotoConsent($event,consent)">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="consent?.icon()"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ consent.title }}</p>
        <p class="value" *ngIf="consent.isConsented()">{{ 'pages.default.settings.consents_overview.consented_on' | translate }} {{ consent.status_at | timeZoneDate:dateFormat.format }}</p>
      </div>
      <a href="" class="block-action m-start-auto" >
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </a>
    </div>
  </div>
</div>
