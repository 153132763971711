import { setMinutes } from 'ngx-bootstrap/chronos/utils/date-setters';
import { AddressInterface } from '../interfaces/address.interface';
import { LocationInterface } from '../interfaces/location.interface';
import { MobileNumber } from '../interfaces/mobile-number.interface';
import { TranslationInterface } from '../interfaces/translation.interface';
import { HospitalDefaultLocale } from './hospital-default-locale';

interface Image {
  uid: string;
  filename: string;
  url: string;
  title: string;
}

interface OpeningHoursDay {
  day: string;
  hours: {
    closed: {
      hours: number;
      minutes: number;
      label: string;
    },
    open: {
      hours: number;
      minutes: number;
      label: string;
    },
    select: OpeningHoursSelectOptions;
  };
}

export enum OpeningHoursSelectOptions {
  CLOSED = 'Closed',
  CUSTOM = 'Custom',
  OPEN = 'Open 24 hours'
}
export class Hospital {
  public uid: string;
  public name: string;
  public email: string;
  public site: LocationInterface;
  public address: AddressInterface;
  public phone_number: MobileNumber;
  public logo_reference: TranslationInterface;
  public metadata: TranslationInterface;

  public default_locale: HospitalDefaultLocale;
  public description: string;

  // These fields are populated with CMS data:
  public website: string;
  public open_24_7: boolean;
  public opening_hours: OpeningHoursDay[];
  public images: Image[] = [];
  public google_maps_uri: string;
  public logo: Image;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.name = item.name;
    this.email = item.email;
    this.site = item.site;
    this.address = item.address;
    this.phone_number = item.phone_number;
    this.logo_reference = item.logo;
    this.metadata = item.metadata;


    if (item.default_locale) {
      this.default_locale = new HospitalDefaultLocale(item.default_locale);
    }
  }

  addDetails(details) {
    this.website = details.website;
    this.open_24_7 = details.open_24_7;
    this.google_maps_uri = details.google_maps_uri;
    this.description = details.description;

    if (details.images) {
      details.images.forEach(image => {
        
        this.images.push({
          uid: image?.uid,
          filename: image?.filename,
          url: image?.url,
          title: image?.title
        });
      });
    }

    if (details.logo) {
      this.logo = {
        uid: details.logo?.uid,
        filename: details.logo?.file?.filename,
        url: details.logo?.file?.url,
        title: details.logo?.title
      };
    }
    
    if(!this.open_24_7) {
      if (details?.opening_hours?.length && (typeof details?.opening_hours !== 'string')) {
        this.opening_hours = [];
        details.opening_hours.forEach(opening_hour => {
          const closing_hours = opening_hour.hours.closed.hours?.toLocaleString(undefined, {minimumIntegerDigits: 2});
          const closing_minutes = opening_hour.hours.closed.minutes?.toLocaleString(undefined, {minimumIntegerDigits: 2});

          const opening_hours = opening_hour.hours.open.hours?.toLocaleString(undefined, {minimumIntegerDigits: 2});
          const opening_minutes = opening_hour.hours.open.minutes?.toLocaleString(undefined, {minimumIntegerDigits: 2});

          this.opening_hours.push({
            day: opening_hour.day,
            hours: {
              closed: {
                hours: closing_hours,
                minutes: closing_minutes,
                label: closing_hours + ':' + closing_minutes
              },
              open: {
                hours: opening_hours,
                minutes: opening_minutes,
                label: opening_hours + ':' + opening_minutes
              },
              select: opening_hour.hours.select,
            }
          });
        });
      }
    }
  }
}
