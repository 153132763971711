import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-do-not-consent-modal',
  templateUrl: './do-not-consent-modal.component.html',
  styleUrls: ['./do-not-consent-modal.component.scss']
})
export class DoNotConsentModalComponent implements OnInit {
  @Output() public onChoice: EventEmitter<boolean> = new EventEmitter();
  
  @Input() isLoading: boolean;
  @Input() isRevokeModal: boolean;
  
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(){
  }

  handleYes() {
    this.onChoice.emit(true);
  }

  handleNo() {
    return this.bsModalRef.hide();
  }
}
