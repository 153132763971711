import moment from 'moment';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgHttpCachingModule, NgHttpCachingSessionStorage, NgHttpCachingStrategy } from 'ng-http-caching';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { forkJoin, Observable } from 'rxjs';
import { ClickOutsideModule } from 'ng-click-outside';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter,
  MOMENT
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartModule } from 'angular-highcharts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

//
// Layouts
//
import { FlowLayoutComponent } from './layouts/flow-layout/flow-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';

//
// Flow Layout Pages
//
import { HomePageComponent } from './pages/flow/home-page/home-page.component';
import {
  DeviceNotSupportedPageComponent
} from './pages/flow/device-not-supported-page/device-not-supported-page.component';
import { LinkInvalidPageComponent } from './pages/flow/link-invalid-page/link-invalid-page.component';
import { OnboardingConsentComponent } from './pages/flow/onboarding-consent/onboarding-consent.component';
import { OnboardingDetailsComponent } from './pages/flow/onboarding-details/onboarding-details.component';
import { OnboardingPasswordComponent } from './pages/flow/onboarding-password/onboarding-password.component';
import { LocaleRegistrationComponent } from './pages/flow/locale-registration/locale-registration.component';
import { SelfOnboardingCodeComponent } from './pages/flow/self-onboarding-code/self-onboarding-code.component';
import { SelfOnboardingConfirmComponent } from './pages/flow/self-onboarding-confirm/self-onboarding-confirm.component';
import { SelfOnboardingConsentComponent } from './pages/flow/self-onboarding-consent/self-onboarding-consent.component';
import { SelfOnboardingDetailsComponent } from './pages/flow/self-onboarding-details/self-onboarding-details.component';
import {
  SelfOnboardingApprovalComponent
} from './pages/flow/self-onboarding-approval/self-onboarding-approval.component';
import { MfaCodeComponent } from './pages/flow/mfa-code/mfa-code.component';
import { ForgotPasswordComponent } from './pages/flow/forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from './pages/flow/forgot-password-reset/forgot-password-reset.component';
import { ExpiredPasswordResetComponent } from './pages/flow/expired-password-reset/expired-password-reset.component';

//
// Default Layout Pages
//
import { DashboardPageComponent } from './pages/default/dashboard-page/dashboard-page.component';
import {
  SettingsPreferencesComponent
} from './pages/default/settings/settings-preferences/settings-preferences.component';
import { SettingsPersonalComponent } from './pages/default/settings/settings-personal/settings-personal.component';
import { SettingsOverviewComponent } from './pages/default/settings/settings-overview/settings-overview.component';
// tslint:disable-next-line:max-line-length
import {
  SettingsConsentsOverviewComponent
} from './pages/default/settings/settings-consents-overview/settings-consents-overview.component';
import {
  SettingsConsentDetailComponent
} from './pages/default/settings/settings-consent-detail/settings-consent-detail.component';
import { SettingsSecurityComponent } from './pages/default/settings/settings-security/settings-security.component';
import { SettingsPrivacyComponent } from './pages/default/settings/settings-privacy/settings-privacy.component';
import { ConversationsComponent } from './pages/default/conversations/conversations.component';
import { CalendarComponent } from './pages/default/calendar/calendar.component';
import { MedicalTeamComponent } from './pages/default/medical-team/medical-team.component';
import { FaqOverviewComponent } from './pages/default/faq-overview/faq-overview.component';
import { FaqDetailComponent } from './pages/default/faq-detail/faq-detail.component';
import {
  HelpCenterOverviewComponent
} from './pages/default/help-center/help-center-overview/help-center-overview.component';
import {
  HelpCenterArticlesOverviewComponent
} from './pages/default/help-center/help-center-articles-overview/help-center-articles-overview.component';
import {
  HelpCenterFaqsOverviewComponent
} from './pages/default/help-center/help-center-faqs-overview/help-center-faqs-overview.component';
import {
  HelpCenterArticlesDetailComponent
} from './pages/default/help-center/help-center-articles-detail/help-center-articles-detail.component';
import {
  HelpCenterFaqsDetailComponent
} from './pages/default/help-center/help-center-faqs-detail/help-center-faqs-detail.component';

//
// Generic Pages
//
import { GenericErrorComponent } from './pages/general/generic-error/generic-error.component';
import { CookiePageComponent } from './pages/general/cookie-page/cookie-page.component';

//
// Components
//
import { PageTabsComponent } from './components/page-tabs/page-tabs.component';
import { FlowWelcomeComponent } from './components/flow-welcome/flow-welcome.component';
import { FlowCopyrightsComponent } from './components/flow-copyrights/flow-copyrights.component';
import { OnboaringIconStepsComponent } from './components/onboaring-icon-steps/onboaring-icon-steps.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { OnboardingDetailsFormComponent } from './components/onboarding-details-form/onboarding-details-form.component';
import { PasswordPolicyRulesComponent } from './components/password-policy-rules/password-policy-rules.component';
import { CalendarYearViewComponent } from './components/calendar-year-view/calendar-year-view.component';
import { CalendarMonthViewComponent } from './components/calendar-month-view/calendar-month-view.component';
import { CalendarWeekViewComponent } from './components/calendar-week-view/calendar-week-view.component';
import {
  CalendarAppointmentCompactComponent
} from './components/calendar-appointment-compact/calendar-appointment-compact.component';
import {
  CalendarPendingInvitationsComponent
} from './components/calendar-pending-invitations/calendar-pending-invitations.component';
import { DynamicInputComponent } from './components/dynamic-input/dynamic-input.component';
import { AppSelectComponent } from './components/app-select/app-select.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CircleProgressSmallComponent } from './components/circle-progress-small/circle-progress-small.component';
import { CircleProgressMediumComponent } from './components/circle-progress-medium/circle-progress-medium.component';
import { MaintenanceComponent } from './pages/general/maintenance/maintenance.component';
import { InactiveComponent } from './pages/general/inactive/inactive.component';
import { SurgeryDatesComponent } from './components/surgery-dates/surgery-dates.component';
import { HelpCenterFaqListComponent } from './components/help-center-faq-list/help-center-faq-list.component';
import { HelpCenterContactComponent } from './components/help-center-contact/help-center-contact.component';
import { FaqBlockComponent } from './components/faq-block/faq-block.component';
import { FaqContentComponent } from './components/faq-content/faq-content.component';
import { MobileRedirectComponent } from './components/mobile-redirect/mobile-redirect.component';
import { PatientMaterialsComponent } from './pages/default/patient-materials/patient-materials.component';
import { MaterialDetailComponent } from './pages/default/material-detail/material-detail.component';
import { HospitalComponent } from './pages/default/hospital/hospital.component';
import { TimelineComponent } from './pages/default/timeline/timeline.component';
import { SessionInterceptor } from './http-interceptors/session-interceptor';
import { PatientGoalsComponent } from './pages/default/patient-goals/patient-goals.component';
import { GoalDetailComponent } from './pages/default/goal-detail/goal-detail.component';
import { FeedbackFieldComponent } from './components/feedback-field/feedback-field.component';
import { AvatarInputComponent } from './components/avatar-input/avatar-input.component';
import { QueryListOverviewComponent } from './pages/default/query-list-overview/query-list-overview.component';
import { ChecklistsComponent } from './pages/default/checklists/checklists.component';
import { QueryListDetailComponent } from './pages/default/query-list-detail/query-list-detail.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginationRangeComponent } from './components/pagination-range/pagination-range.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import {
  OnboardingRedirectToAppComponent
} from './pages/flow/onboarding-redirect-to-app/onboarding-redirect-to-app.component';
import { PatientRemindersComponent } from './components/patient-reminders/patient-reminders.component';
import { PatientReminderItemComponent } from './components/patient-reminder-item/patient-reminder-item.component';
import { VideoCarouselComponent } from './components/video-carousel/video-carousel.component';

//
// Modals
//
import { CreatingAccountModalComponent } from './modals/creating-account-modal/creating-account-modal.component';
import { DoNotConsentModalComponent } from './modals/do-not-consent-modal/do-not-consent-modal.component';
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { OnboardingLoadingModalComponent } from './modals/onboarding-loading-modal/onboarding-loading-modal.component';
import {
  EditPersonalDetailsModalComponent
} from './modals/edit-personal-details-modal/edit-personal-details-modal.component';
import {
  ExportPersonalDataModalComponent
} from './modals/export-personal-data-modal/export-personal-data-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { DeleteAccountModalComponent } from './modals/delete-account-modal/delete-account-modal.component';
import {
  RestrictProcessingDataModalComponent
} from './modals/restrict-processing-data-modal/restrict-processing-data-modal.component';
import { EditPhoneModalComponent } from './modals/edit-phone-modal/edit-phone-modal.component';
import { EditEmailModalComponent } from './modals/edit-email-modal/edit-email-modal.component';
import {
  StartNewConversationModalComponent
} from './modals/start-new-conversation-modal/start-new-conversation-modal.component';
import {
  EditConversationSubjectModalComponent
} from './modals/edit-conversation-subject-modal/edit-conversation-subject-modal.component';
import {
  ConversationParticipantsModalComponent
} from './modals/conversation-participants-modal/conversation-participants-modal.component';
import { AppointmentModalComponent } from './modals/appointment-modal/appointment-modal.component';
import { UserTaskStandardModalComponent } from './modals/user-task-standard-modal/user-task-standard-modal.component';
import { SurgeryDateModalComponent } from './modals/surgery-date-modal/surgery-date-modal.component';
import { HelpCenterModalComponent } from './modals/help-center-modal/help-center-modal.component';
import { ProfilePictureModalComponent } from './modals/profile-picture-modal/profile-picture-modal.component';
import {
  MobileVerificationCodeModalComponent
} from './modals/mobile-verification-code-modal/mobile-verification-code-modal.component';
import { AddMfaBackupModalComponent } from './modals/add-mfa-backup-modal/add-mfa-backup-modal.component';
import { PathwayMessageModalComponent } from './modals/pathway-message-modal/pathway-message-modal.component';

//
// Services
//
import { GeneralService } from './services/general.service';
import { LanguageService } from './services/language.service';
import { MaintenanceService } from './services/maintenance.service';
import { TranslationLoaderService } from './services/translation-loader.service';

//
// Pipes
//
import { SimpleDatePipe } from './pipes/simple_date.pipe';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { TranslateHelp } from './pipes/translate-help.pipe';
import { EvalTranslation } from './pipes/eval-translation.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { TimeZoneDatePipe } from './pipes/tz_date.pipe';
import { TimePipe } from './pipes/time.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { TranslateNumberPipe } from './pipes/translate-number.pipe';
import { VideoSafePipe } from './pipes/video-safe.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { BytesPipe } from './pipes/bytes.pipe';

//
// Interceptors & guards
//
import { ErrorInterceptor } from './http-interceptors/error-interceptor';
import { HeaderInterceptor } from './http-interceptors/header-interceptor';
import { QueryListCanDeactivateGuard } from './helpers/query-list-form.guard';
import { SidebarBannerComponent } from './components/sidebar-banner/sidebar-banner.component';
import { BannerInfoModalComponent } from './modals/banner-info-modal/banner-info-modal.component';
import { BannerInitModalComponent } from './modals/banner-init-modal/banner-init-modal.component';
import { UserTaskFormComponent } from './components/user-task-form/user-task-form.component';
import { UserTaskVisualModalComponent } from './modals/user-task-visual-modal/user-task-visual-modal.component';
import { StyledEnumComponent } from './components/styled-enum/styled-enum.component';
import { AppRangeSliderComponent } from './components/app-range-slider/app-range-slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { StyledQuestionComponent } from './components/styled-question/styled-question.component';

//
// Setup language loader
//
export function CustomTranslateLoader(http: HttpClient) {
  return {
    getTranslation(lang: string): Observable<any> {
      return TranslationLoaderService.GetAllTranslations(http, lang);
    }
  };
}

//
// AppInitializer
//
export function appInitializerFactory(
  maintenanceService: MaintenanceService,
  generalService: GeneralService,
  languageService: LanguageService,
) {
  return () => {
    return new Promise((resolve, reject) => {
      forkJoin([
        maintenanceService.appInitializer(),
        generalService.appInitializer(),
        languageService.appInitializer(),
      ]).subscribe(() => {
        resolve(true);
      }, () => {
        reject(true);
      });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FlowLayoutComponent,
    HomePageComponent,
    DefaultLayoutComponent,
    DashboardPageComponent,
    PageTabsComponent,
    DeviceNotSupportedPageComponent,
    FlowWelcomeComponent,
    FlowCopyrightsComponent,
    LinkInvalidPageComponent,
    OnboardingConsentComponent,
    OnboaringIconStepsComponent,
    OnboardingPasswordComponent,
    OnboardingDetailsComponent,
    CreatingAccountModalComponent,
    DoNotConsentModalComponent,
    LocaleRegistrationComponent,
    GenericErrorComponent,
    MaintenanceComponent,
    InactiveComponent,
    SelfOnboardingCodeComponent,
    SelfOnboardingConfirmComponent,
    SelfOnboardingConsentComponent,
    SelfOnboardingDetailsComponent,
    SelfOnboardingApprovalComponent,
    MfaCodeComponent,
    LogoutModalComponent,
    OnboardingLoadingModalComponent,
    PageLoaderComponent,
    OnboardingDetailsFormComponent,
    ForgotPasswordComponent,
    ForgotPasswordResetComponent,
    PasswordPolicyRulesComponent,
    ExpiredPasswordResetComponent,
    SettingsPreferencesComponent,
    SettingsPersonalComponent,
    SettingsOverviewComponent,
    EditPhoneModalComponent,
    EditEmailModalComponent,
    EditPersonalDetailsModalComponent,
    SettingsConsentsOverviewComponent,
    SettingsConsentDetailComponent,
    SettingsSecurityComponent,
    SettingsPrivacyComponent,
    ConfirmModalComponent,
    DeleteAccountModalComponent,
    ExportPersonalDataModalComponent,
    ConfirmModalComponent,
    EditPersonalDetailsModalComponent,
    RestrictProcessingDataModalComponent,
    ConversationsComponent,
    StartNewConversationModalComponent,
    EditConversationSubjectModalComponent,
    ConversationParticipantsModalComponent,
    CalendarComponent,
    SimpleDatePipe,
    WeekdayPipe,
    TimePipe,
    BytesPipe,
    TimeZoneDatePipe,
    SecurePipe,
    SafePipe,
    TranslateNumberPipe,
    CalendarYearViewComponent,
    CalendarMonthViewComponent,
    CalendarWeekViewComponent,
    AppointmentModalComponent,
    UserTaskStandardModalComponent,
    CalendarAppointmentCompactComponent,
    CalendarPendingInvitationsComponent,
    MobileRedirectComponent,
    MedicalTeamComponent,
    FaqOverviewComponent,
    FaqDetailComponent,
    PatientMaterialsComponent,
    MaterialDetailComponent,
    HomePageComponent,
    CookiePageComponent,
    HospitalComponent,
    TimelineComponent,
    DynamicInputComponent,
    SortByPipe,
    VideoSafePipe,
    PatientGoalsComponent,
    GoalDetailComponent,
    AppSelectComponent,
    HelpCenterOverviewComponent,
    HelpCenterArticlesOverviewComponent,
    HelpCenterFaqsOverviewComponent,
    HelpCenterArticlesDetailComponent,
    HelpCenterFaqsDetailComponent,
    ProfilePictureModalComponent,
    MobileVerificationCodeModalComponent,
    AvatarComponent,
    FeedbackFieldComponent,
    AvatarInputComponent,
    TranslateHelp,
    EvalTranslation,
    ChecklistsComponent,
    QueryListOverviewComponent,
    QueryListDetailComponent,
    PaginationComponent,
    PaginationRangeComponent,
    AudioPlayerComponent,
    AddMfaBackupModalComponent,
    DateTimeInputComponent,
    DateInputComponent,
    OnboardingRedirectToAppComponent,
    PatientRemindersComponent,
    PatientReminderItemComponent,
    PathwayMessageModalComponent,
    CircleProgressSmallComponent,
    CircleProgressMediumComponent,
    VideoCarouselComponent,
    HelpCenterModalComponent,
    HelpCenterFaqListComponent,
    HelpCenterContactComponent,
    FaqBlockComponent,
    FaqContentComponent,
    SurgeryDatesComponent,
    SurgeryDateModalComponent,
    SidebarBannerComponent,
    BannerInfoModalComponent,
    BannerInitModalComponent,
    UserTaskFormComponent,
    UserTaskVisualModalComponent,
    StyledEnumComponent,
    AppRangeSliderComponent,
    StyledQuestionComponent,
  ],
  bootstrap: [AppComponent], imports: [ImageCropperModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSliderModule,
    NgHttpCachingModule.forRoot({
      cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL,
      store: new NgHttpCachingSessionStorage(),
      lifetime: 3600000
    }),
    InfiniteScrollModule,
    TimepickerModule.forRoot(),
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CustomTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxPageScrollCoreModule.forRoot({
      easingLogic: (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) {
          return c / 2 * t * t + b;
        }
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      }
    }),
    ToastrModule.forRoot({
      closeButton: false,
      disableTimeOut: true,
      preventDuplicates: true
    }),
    BackButtonDisableModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ClickOutsideModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CalendarMomentDateFormatter,
      },
    }),
    ChartModule,
    NgCircleProgressModule.forRoot({
      outerStrokeWidth: 28,
      outerStrokeLinecap: 'square',
      outerStrokeColor: '#000099',
      showInnerStroke: true,
      innerStrokeWidth: 28,
      innerStrokeColor: '#CCCCEB',
      space: -28,
      titleFontSize: '1.75rem',
      titleFontWeight: '900',
      titleColor: '#000099',
      showSubtitle: true,
      subtitleFontSize: '1.75rem',
      subtitleFontWeight: '900',
      subtitleColor: '#000099',
      showUnits: false,
      units: '%',
      unitsFontSize: '1.75rem',
      unitsFontWeight: '900',
      unitsColor: '#000099',
      maxPercent: 100,
      showZeroOuterStroke: false,
      startFromZero: true,
      animationDuration: 1000,
      renderOnClick: false,
    }),
    CarouselModule.forRoot()], providers: [
    {provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
    DatePipe,
    SortByPipe,
    TimePipe,
    SecurePipe,
    VideoSafePipe,
    SafePipe,
    TranslateNumberPipe,
    TimeZoneDatePipe,
    TranslateHelp,
    EvalTranslation,
    BytesPipe,
    GeneralService,
    QueryListCanDeactivateGuard,
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [
        MaintenanceService,
        GeneralService,
        LanguageService
      ],
      multi: true
    },
    {
      provide: MOMENT,
      useValue: moment,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {


}
