<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2>{{ 'modals.edit_contact_details.edit_contact_details' | translate }}</h2>

    <div *ngIf="(step === 'email')">
      <div class="form-group styled floating-label">
        <input type="email" id="inputEmail" class="form-control w-100" [placeholder]="('form.labels.email_address' | translate)" formControlName="email" email>
        <label for="inputEmail" class="required">{{ 'form.labels.email_address' | translate }}</label>
        <p class="validation-feedback general-feedback" *ngIf="form?.controls['email']?.errors?.email || form?.controls['email']?.errors?.pattern">{{ 'form.feedback.email_invalid' | translate }}</p>
        <p class="validation-feedback general-feedback" *ngIf="form?.controls['email']?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>
      </div>
    </div>

    <div *ngIf="(step === 'code')">
      <p>{{ 'modals.edit_contact_details.enter_code_email' | translate }}</p>

      <div class="form-group styled floating-label">
        <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.edit_mail.verification_code' | translate)" formControlName="code" required>
        <label for="inputCode">{{ 'modals.edit_contact_details.verification_code' | translate }}</label>
        <p class="validation-feedback general-feedback" *ngIf="form?.controls['code']?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>

        <p class="mt-1"><a href="" (click)="resendCode($event)">Resend code</a></p>
      </div>
    </div>

    <p class="text-info font-weight-bold">{{ 'modals.edit_contact_details.warning' | translate }}</p>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isSaving}" *ngIf="(step === 'email') && isEmailChanged()" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span *ngIf="(step === 'email')">{{ 'action.next_step' | translate }}</span>
    </button>

    <button class="btn btn-secondary m-start-3" [ngClass]="{'loader': isSaving}" *ngIf="((step === 'code') || !isEmailChanged())" (click)="handleSubmit()" [disabled]="!isEmailChanged()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
