<div class="body-content body-content-calendar-month-view">

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="calendar-month-view" *ngIf="!isLoading">
    <div class="container">

      <div class="calendar-month-view-wrapper">
        <div class="month-legend">
          <div class="item" *ngFor="let day of weekdays">
            <p>{{ day }}</p>
          </div>
        </div>

        <div class="month-days" #monthDaysContainer>

          <div class="item disabled" [style.height.%]="blockHeight" *ngFor="let i of [].constructor(fillUpDaysAtStart)"></div>

          <div class="item" [style.height.%]="blockHeight" *ngFor="let day of days" [ngClass]="{'current': isToday(day.key)}">
            <p class="num">{{ day.label | translateNumber }} </p>
            <div *ngFor="let appointment of getVisibleItems(day.appointments).visible" id="item-{{appointment?.uid}}">
              <a href="" (click)="selectAppointment($event, appointment)" 
              class="event"
              [ngClass]="{
                'cancelled' : appointment.cancelled || appointment.status_of_user == 'DECLINED', 
                'pending': appointment.hasNoneState(),
                'full-day': appointment?.date
              }" >
                <span class="m-end-1 font-weight-medium">{{ appointment.start_date | time }}</span>
                <span class="text-truncate">{{ appointment.title }}</span>
                <span class="highlight" *ngIf="appointment.status_of_user == 'PENDING'"></span>
              </a>
            </div>
            <a href="" (click)="onMoreClicked($event, day.appointments[0].start_date)" class="text-muted" *ngIf="getVisibleItems(day.appointments)?.invisible?.length">
              <span>+{{getVisibleItems(day.appointments)?.invisible?.length}} {{ 'pages.default.calendar.more' | translate }}</span>
            </a>
          </div>

          <div class="item disabled" [style.height.%]="blockHeight" *ngFor="let i of [].constructor(fillUpDaysAtEnd)"></div>
        </div>
      </div>
    </div>
  </div>
</div>
