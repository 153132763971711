<div class="mb-2 mb-lg-3" *ngIf="hasReminders">

  <h2 class="text-secondary">{{ 'pages.default.dashboard.reminders' | translate }}</h2>

  <ng-container *ngIf="hasActionReminders">
    <div class="row justify-content-between">
      <div class="col-auto">
        <h3 class="text-muted">{{ 'components.patient_reminders.needs_action' | translate }}</h3>
      </div>

      <div class="col-auto" *ngIf="actionReminders?.length > actionRemindersPreview">
        <a class="text-icon-link hoverable text-secondary" (click)="showMoreToggle($event, 'action')">
          <span class="label" *ngIf="!actionRemindersShowMore">{{ 'components.patient_reminders.view_all' | translate }} ({{ actionReminders?.length }})</span>
          <span class="label" *ngIf="actionRemindersShowMore">{{ 'components.patient_reminders.view_less' | translate }}</span>
          <div class="icon-end" [inlineSVG]="'/assets/svg/chevron-down.svg'" [ngClass]="{'d-none': actionRemindersShowMore}"></div>
          <div class="icon-end" [inlineSVG]="'/assets/svg/chevron-up.svg'" [ngClass]="{'d-none': !actionRemindersShowMore}"></div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-6" *ngFor="let reminder of visibleActionReminders">
        <app-patient-reminder-item
          id="reminder-{{reminder?.testingID}}"
          [reminder]="reminder"
          [patientUid]="patientUid"
          [patientPathwayID]="pathway?.patient_pathway_id"
          (onUpdate)="anyReminderUpdated()"
        ></app-patient-reminder-item>
      </div>
    </div>
  </ng-container>

  <div class="pb-2" *ngIf="hasActionReminders && hasInfoReminders"></div>

  <ng-container *ngIf="hasInfoReminders">
    <div class="row justify-content-between">
      <div class="col-auto">
        <h3 class="text-muted">{{ 'components.patient_reminders.for_your_information' | translate }}</h3>
      </div>

      <div class="col-auto" *ngIf="otherInfoRemindersCount > 2 || (otherInfoRemindersCount > 0 && oldInformationReminders?.length > 0)">
        <a class=" text-icon-link hoverable text-secondary" (click)="showMoreToggle($event, 'information')">

          <span class="label" *ngIf="!informationRemindersShowMore">
            <ng-container *ngIf="informationReminders?.length > 0">{{ 'components.patient_reminders.view_all' | translate }} ({{ otherInfoRemindersCount }})</ng-container>
            <ng-container *ngIf="informationReminders?.length == 0">{{ 'components.patient_reminders.view_past_reminders' | translate }} ({{ otherInfoRemindersCount }})</ng-container>
          </span>

          <span class="label" *ngIf="informationRemindersShowMore">{{ 'components.patient_reminders.view_less' | translate }}</span>
          <div class="icon-end" [inlineSVG]="'/assets/svg/chevron-down.svg'" [ngClass]="{'d-none': informationRemindersShowMore}"></div>
          <div class="icon-end" [inlineSVG]="'/assets/svg/chevron-up.svg'" [ngClass]="{'d-none': !informationRemindersShowMore}"></div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-6" *ngFor="let reminder of visibleInformationReminders">
        <app-patient-reminder-item
          id="reminder-{{reminder?.testingID}}"
          [reminder]="reminder"
          [patientUid]="patientUid"
          [patientPathwayID]="pathway?.patient_pathway_id"
          (onUpdate)="anyReminderUpdated()"
        ></app-patient-reminder-item>
      </div>
    </div>

    <div *ngIf="informationRemindersShowMore && oldInformationReminders?.length > 0">
      <p class="text-muted small">{{ 'components.patient_reminders.read_messages_auto_removed_info' | translate }}</p>

      <div class="row">
        <div class="col-6" *ngFor="let reminder of oldInformationReminders">
          <app-patient-reminder-item
            id="reminder-{{reminder?.testingID}}"
            [reminder]="reminder"
            [patientUid]="patientUid"
            [patientPathwayID]="pathway?.patient_pathway_id"
            (onUpdate)="anyReminderUpdated()"
          ></app-patient-reminder-item>
        </div>
      </div>
    </div>
  </ng-container>
</div>
