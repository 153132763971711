<div class="sidebar-banner bg-primary py-2 p-start-2" *ngIf="banner">

  <div class="p-end-2">
    <div class="d-flex">
      <span *ngIf="banner?.type === bannerType.MAINTENANCE" [inlineSVG]="'/assets/svg/wrench.svg'"></span>
      <span *ngIf="banner?.type === bannerType.CRITICAL" [inlineSVG]="'/assets/svg/caution.svg'"></span>
      <span *ngIf="banner?.type === bannerType.WARNING" [inlineSVG]="'/assets/svg/notification-idle.svg'"></span>
      <span *ngIf="banner?.type === bannerType.INFORMATIONAL" [inlineSVG]="'/assets/svg/info-circle.svg'"></span>

      <h3 class="sidebar-open-element-block mb-0 m-start-2">{{ banner?.title }}</h3>
    </div>

    <div [innerHtml]="banner?.description" class="description sidebar-open-element-block mt-2"></div>
  </div>

  <div class="text-align-end pt-2 sidebar-open-element-block" *ngIf="hasMoreInfo">
    <button class="btn btn-link" type="button" (click)="showMoreInfoModal(banner.title, banner.more_information)"
      aria-label="Read more about this notification">{{ 'action.read_more' | translate }}</button>
  </div>
</div>
