<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h1>{{ 'pages.default.settings.overview.settings' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div routerLink="/settings/personal"
         class="single-item-block mb-2 item-clickable"
         id="settingsPersonalBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/profile.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.personal_settings' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.personal_info_contact_details' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <div class="pb-3 pb-lg-4"></div>

    <div routerLink="/settings/preferences"
         class="single-item-block mb-2 item-clickable"
         id="settingsPreferencesBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/approve.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.preferences' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.preferences_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <div routerLink="/settings/security"
         class="single-item-block mb-2 item-clickable"
         id="settingsSecurityBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/password.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.security_settings' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.security_settings_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <!-- temp hide this block -->
    <div routerLink="/settings/privacy"
         class="single-item-block mb-2 item-clickable d-none"
         id="settingsPrivacyBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/eye.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.privacy_settings' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.privacy_settings_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <!-- temp hide this block -->
    <div routerLink="/settings"
         class="single-item-block mb-2 item-clickable d-none"
         id="settingsExternalAppsBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/link.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.external_apps' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.external_apps_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <div routerLink="/settings/consents"
         class="single-item-block mb-2 item-clickable"
         id="settingsConsentsBtn">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/multpage.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.consents' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.view_agreements' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>
  </div>
</div>
