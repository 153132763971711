export enum Type {
  MAIL = 'Email',
  CHAT = 'Chat',
  PHONE = 'Phone',
}

export class HelpcenterContact {
  public uid: string;
  public title: string;
  public description: string;
  public text: string;
  public hyperlink: string;
  public icon: string;
  public type: Type;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.title = item.title;
    this.description = item.description;
    this.text = item.text;
    this.hyperlink = item.hyperlink;
    this.type = item.type;

    if (item.icon) {
      this.icon = item.icon.url;
    }
  }

  public get isMail(): boolean {
    return this.type === Type.MAIL;
  }

  public get isChat(): boolean {
    return this.type === Type.CHAT;
  }

  public get isPhone(): boolean {
    return this.type === Type.PHONE;
  }
}
