<div class="mb-2 mb-xl-3">
  <app-select
  class="d-inline-block"
  [selectClass]="'text-select sm m-end-2 ff-overflow-scroll'"
  id="select_flow_copyrights_language"
  [searchable]="false"
  [multiple]="false"
  [items]="languageOptions"
  [(ngModel)]="selectedLanguage"
  [bindValue]="'value'"
  (change)="onLanguageSelection()"

  [autoSortOnLabel]="true"
  [selectedItemsOnTop]="false"

  *ngIf="languageSelect"
  ></app-select>

  <div class="d-inline-block" *ngIf="cookiesButton">
    <a href="" id="button_flow_copyrights_cookie_settings" class="btn btn-sm btn-as-text" (click)="triggerCookies($event)">{{ 'components.flow_copyrights.cookie_settings' | translate }}</a>
    <span class="icon xs text-muted rtl-mirrored-inline-svg mx-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
    <a href="" id="button_flow_copyrights_cookie_page" class="btn btn-sm btn-as-text" (click)="goToCookiePolicy($event)">{{ 'components.flow_copyrights.cookie_page' | translate }}</a>
  </div>
</div>

<p class="m-0 small text-muted">&copy; {{ thisYear }} {{ 'general.copyright' | translate }}</p>
